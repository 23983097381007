import infoCoupon from 'assets/vaunce/image/info_coupon.png';
import infoEvent from 'assets/vaunce/image/info_event.png';
import infoMember from 'assets/vaunce/image/info_member.png';
import infoMembership from 'assets/vaunce/image/info_membership.png';
import infoParty from 'assets/vaunce/image/info_party.png';
import infoPremium from 'assets/vaunce/image/info_premium.png';
import infoRegi from 'assets/vaunce/image/info_regi.png';

import infoInquiry from 'assets/vaunce/image/info_inquiry.png';
import infoPrivacy from 'assets/vaunce/image/info_privacy.png';
import infoQuestion from 'assets/vaunce/image/info_question.png';
import infoTerms from 'assets/vaunce/image/info_terms.png';
import infoSetting from 'assets/vaunce/image/info_setting.png';

import infoCoupon2X from 'assets/vaunce/image/info_coupon@2x.png';
import infoEvent2X from 'assets/vaunce/image/info_event@2x.png';
import infoMember2X from 'assets/vaunce/image/info_member@2x.png';
import infoMembership2X from 'assets/vaunce/image/info_membership@2x.png';
import infoParty2X from 'assets/vaunce/image/info_party@2x.png';
import infoPremium2X from 'assets/vaunce/image/info_premium@2x.png';
import infoRegi2X from 'assets/vaunce/image/info_regi@2x.png';

import infoInquiry2X from 'assets/vaunce/image/info_inquiry@2x.png';
import infoPrivacy2X from 'assets/vaunce/image/info_privacy@2x.png';
import infoQuestion2X from 'assets/vaunce/image/info_question@2x.png';
import infoTerms2X from 'assets/vaunce/image/info_terms@2x.png';
import infoSetting2X from 'assets/vaunce/image/info_setting@2x.png';

export interface MenuType {
	label: string;
	url: string;
	iconUrl: string;
	srcsetUrl: string;
	needLoginAssertion: boolean;
	subTitle?: string;
}

export const upperMenuItems: MenuType[] = [
	{
		label: '멤버십',
		url: '/vclub',
		iconUrl: infoMember,
		srcsetUrl: infoMember2X,
		needLoginAssertion: true,
	},
	{
		label: '쿠폰',
		url: '/couponList',
		iconUrl: infoCoupon,
		srcsetUrl: infoCoupon2X,
		needLoginAssertion: true,
	},
	{
		label: '간편 입장 서비스',
		url: '/facilityRegister',
		iconUrl: infoRegi,
		srcsetUrl: infoRegi2X,
		needLoginAssertion: true,
	},
	{
		label: '멤버십 안내',
		url: '/membershipinfo',
		iconUrl: infoMembership,
		srcsetUrl: infoMembership2X,
		needLoginAssertion: false,
	},
	{
		label: '바운스 프리미엄 클럽',
		url: '/vipclub',
		iconUrl: infoPremium,
		srcsetUrl: infoPremium2X,
		needLoginAssertion: true,
	},
	{
		label: '파티/단체',
		url: '/reservation',
		iconUrl: infoParty,
		srcsetUrl: infoParty2X,
		needLoginAssertion: true,
	},
	{
		label: '이벤트',
		url: '/event',
		iconUrl: infoEvent,
		srcsetUrl: infoEvent2X,
		needLoginAssertion: false,
	},
];

export const bottomMenuItems: MenuType[] = [
	{
		label: '1:1 문의',
		url: '/questions',
		iconUrl: infoInquiry,
		srcsetUrl: infoInquiry2X,
		needLoginAssertion: true,
	},
	{
		label: '자주 묻는 질문',
		url: '/faq',
		iconUrl: infoQuestion,
		srcsetUrl: infoQuestion2X,
		needLoginAssertion: true,
	},
	{
		label: '이용 약관',
		url: '/termsofuse',
		iconUrl: infoTerms,
		srcsetUrl: infoTerms2X,
		needLoginAssertion: true,
	},
	{
		label: '개인정보처리방침',
		url: '/privacy',
		iconUrl: infoPrivacy,
		srcsetUrl: infoPrivacy2X,
		needLoginAssertion: true,
	},
	{
		label: '설정',
		url: '/setting',
		iconUrl: infoSetting,
		srcsetUrl: infoSetting2X,
		needLoginAssertion: true,
	},
];
