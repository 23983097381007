import { Box, BoxProps, styled } from '@mui/material';

export interface IRoundBoxProps extends BoxProps {}
export const RoundBox: React.FC<IRoundBoxProps> = ({ ...others }) => {
	return <StyleBox {...others} />;
};

const StyleBox = styled(Box)(({ theme }) => ({
	backgroundColor: '#EDEDED',
	color: `${theme.palette.primary.main}`,
	width: '100%',
	borderRadius: '18px',
	padding: '7.5px 10px',
	marginBottom: '35px',
}));
