import { CloseIconBox } from '@kinderlabs-pos/ui-atoms';
import {
	Box,
	Dialog,
	DialogContent,
	DialogProps,
	Divider,
	IconButton,
	Stack,
	styled,
	Typography,
} from '@mui/material';

const noticeContentFirst: string[] = [
	'구매 시 선택한 센터에서만 사용이 가능하며, 2시간 이용권으로만 사용 가능합니다. (영통센터는 2시간 30분 이용)',
	'일부 센터는 나이와 키에 따라 요금이 다르니 이용자에 맞게 구매하시기 바랍니다.',
	'다회권 결제 당일 1회도 사용하지 않은 경우에 한해 전액 환불이 가능합니다.',
	'전액 환불 기간이 지난 경우에도 유효기간 내에 환불이 가능하며, 결제 금액에서 위약금 10%와 사용된 횟수의 금액을(정상가 기준 차감) 공제하고 환불됩니다.',
	'유효기간이 만료된 후에는 환불이 불가합니다.',
];

const noticeContentSecond: string[] = [
	'일부 센터는 현장에서 티켓 사용 시, 이용자의 조건(키 또는 나이)과 맞지 않으면 현장에서 차액에 대해 추가 결제가 필요합니다.',
];

export interface IAgreeAllInfoDialogProps extends DialogProps {
	onClose: () => void;
	noticeFirstContent: string[];
	noticeSecondContent: string[];
	otherContent?: string[];
}
export const AgreeAllInfoDialog: React.FC<IAgreeAllInfoDialogProps> = ({
	open,
	onClose,
	noticeFirstContent,
	noticeSecondContent,
	otherContent,
}) => {
	return (
		<Dialog
			maxWidth='sm'
			open={open}
			onClose={onClose}>
			<DialogContent>
				<Stack>
					<Stack
						direction={'row'}
						justifyContent={'flex-end'}>
						<IconButton onClick={onClose}>
							<CloseIconBox
								height={'15px !important'}
								width={'15px !important'}
							/>
						</IconButton>
					</Stack>
					<Stack marginBottom={'1rem'}>
						<Typography
							color={'primary'}
							variant='h4'
							sx={{ mb: 2 }}>
							결제 및 환불 유의사항
						</Typography>
						<Divider />

						<NoticeCotnentBox>
							{noticeFirstContent.map((content, idx) => (
								<li
									key={idx}
									style={{ fontWeight: 500, fontSize: '16px' }}>
									{content === '' && <br />}
									{content}
								</li>
							))}
						</NoticeCotnentBox>
					</Stack>

					<Typography
						color={'primary'}
						variant='h4'
						sx={{ my: 2 }}>
						사용 유의사항
					</Typography>
					<Divider />
					<NoticeCotnentBox>
						{noticeSecondContent.map((content, idx) => (
							<li
								key={idx}
								style={{ fontWeight: 500, fontSize: '16px' }}>
								{content}
							</li>
						))}
						{otherContent &&
							otherContent.map((content, idx) => (
								<li
									key={idx}
									style={{ fontWeight: 500, fontSize: '16px' }}>
									{content}
								</li>
							))}
					</NoticeCotnentBox>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
export default AgreeAllInfoDialog;

const NoticeCotnentBox = styled(Box)(({ theme }) => ({
	marginTop: '20px',
}));
