import { VaunceState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Divider, Stack, Typography } from '@mui/material';
import infoImage from 'assets/vaunce/image/infofolder.png';
import { useAtomValue } from 'jotai';
import { LoginTitleBox } from '../../components/LoginTitleBox';
import { useVaunceAppRouter } from '../routes';
import { LoginPageHeader } from './LoginPageHeader';

export interface IFoundPwPageProps {}
export const FoundPwPage: React.FC<IFoundPwPageProps> = ({}) => {
	const findUserInfo = useAtomValue(VaunceState.findUserInfo);
	const { navigateLogin } = useVaunceAppRouter();
	return (
		<Stack direction={'column'}>
			<LoginPageHeader />
			<LoginTitleBox title='비밀번호 찾기' />

			{findUserInfo.success === true && (
				<Stack
					textAlign={'center'}
					alignItems={'center'}
					spacing={3}>
					<img
						src={infoImage}
						height={68}
						width={68}
					/>
					<Box>
						<Typography variant='h5'>회원님의 개인정보 보호를 위해 관련</Typography>
						<Typography variant='h5'>정보의 일부가 *로 표시됩니다.</Typography>
					</Box>

					<Divider sx={{ width: '100%', borderColor: 'rgba(255, 92, 137, 0.60)' }} />
					<Box>
						<Typography
							variant='h5'
							fontWeight={500}>
							고객님의 임시비밀번호가 아래
						</Typography>
						{findUserInfo.type === 'phone' ? (
							<>
								<Typography
									variant='h5'
									fontWeight={500}>
									전화번호로 전송 되었습니다.
								</Typography>
								<Typography
									variant='h4'
									color='primary'>
									{findUserInfo.masking}
								</Typography>
							</>
						) : (
							<>
								<Typography variant='h5'>이메일로 전송 되었습니다.</Typography>
								<Typography
									variant='h4'
									color='primary'>
									{findUserInfo.masking}
								</Typography>
							</>
						)}
					</Box>

					<Divider sx={{ width: '100%', borderColor: 'rgba(255, 92, 137, 0.60)' }} />

					<RoundedButton
						sx={{ height: 58, width: '100%' }}
						onClick={() => {
							navigateLogin();
						}}>
						로그인
					</RoundedButton>
				</Stack>
			)}
		</Stack>
	);
};
