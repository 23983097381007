import { Box, Stack, styled, SxProps, Theme, Typography } from '@mui/material';
import backIconImage from 'assets/vaunce/image/back.png';
import { useNavigate } from 'react-router-dom';

export interface IPageHeaderProps {
	title: string;
	isGoBackHidden?: boolean;
	showStep?: boolean;
	stepNum?: number;
	navigateAlterUrl?: () => void;
	style?: SxProps<Theme>;
}
export const PageHeader: React.FC<IPageHeaderProps> = ({
	title,
	showStep,
	stepNum,
	navigateAlterUrl,
	isGoBackHidden = false,
	style,
}) => {
	const navigate = useNavigate();
	return (
		<Stack
			direction={'row'}
			alignItems={'center'}
			sx={style ? style : { mb: 2, position: 'sticky' }}
			justifyContent={showStep ? 'space-between' : ''}>
			{isGoBackHidden ? (
				<EmptyBox />
			) : (
				<img
					src={backIconImage}
					height={24}
					width={24}
					onClick={
						navigateAlterUrl
							? navigateAlterUrl
							: () => {
									navigate(-1);
							  }
					}
				/>
			)}
			<Box sx={showStep ? { pl: 5 } : { flex: 1 }}>
				<Typography
					variant='h2'
					textAlign={'center'}>
					{title}
				</Typography>
			</Box>

			{!showStep && <EmptyBox />}

			{showStep && stepNum && (
				<Stack
					direction={'row'}
					spacing={1}>
					<StepGroup stepNum={stepNum} />
				</Stack>
			)}
		</Stack>
	);
};

export const StepGroup: React.FC<{ stepNum: number }> = ({ stepNum }) => {
	return (
		<>
			{stepNum === 1 ? <Stepper>{stepNum}</Stepper> : <EmptyStepper />}
			{stepNum === 2 ? <Stepper>{stepNum}</Stepper> : <EmptyStepper />}
			{stepNum === 3 ? <Stepper>{stepNum}</Stepper> : <EmptyStepper />}
		</>
	);
};
const Stepper = styled(Box)(() => ({
	width: '24px',
	height: '24px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#FF5C89',
	borderRadius: '50%',
	fontSize: '1.25em',
	fontWeight: 700,
	color: '#FFF',
	// paddingTop: '3.5px',
}));

const EmptyStepper = styled(Box)(() => ({
	backgroundColor: 'rgba(0, 0, 0, 0.10)',
	borderRadius: '50%',
	width: '12px',
	height: '12px',
	alignSelf: 'center',
}));

const EmptyBox = styled(Box)(({ theme }) => ({
	width: 24,
}));
