import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import warningImage from 'assets/vaunce/image/pinkwarning.png';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components/PageHeader';

export interface IWriteDonePageProps {}
export const WriteDonePage: React.FC<IWriteDonePageProps> = ({}) => {
	const { prev } = useParams();

	return (
		<Stack
			flex={1}
			overflow={'auto'}
			spacing={3}
			sx={{ pb: 2 }}>
			<PageHeader
				title={pageHeaderFunc(prev)}
				isGoBackHidden
			/>
			<Stack
				flex={1}
				justifyContent={'center'}
				alignItems={'center'}
				spacing={3}>
				<img src={warningImage} />
				<Typography
					variant='h5'
					textAlign={'center'}>
					{pageHeaderFunc(prev)} 문의 내용이 <br /> 성공적으로 접수되었습니다. <br /> 자세한내용은
					<span style={{ color: vaunceColors.lightPink }}>
						{' MY페이지 > 고객센터 > 1:1문의 메뉴에서 확인'}
					</span>{' '}
					하실수 있습니다.
				</Typography>
			</Stack>
			{prev === 'normal' && (
				<RoundedButton
					onClick={() => {
						location.replace('/questions');
					}}>
					<Typography variant='h5'>{'1:1 문의 내역'}</Typography>
				</RoundedButton>
			)}
		</Stack>
	);
};

const pageHeaderFunc = (url?: string) => {
	let title = '';
	switch (url) {
		case 'normal':
			title = '1:1 예약';
			break;
		case 'party':
			title = '파티/단체 예약';
			break;
		default:
			title = '1:1 문의';
	}
	return title;
};
