import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect } from 'react';
import { EmptyContent } from '../../components/EmptyContent';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { entranceLog } from '../../utils/TechLabsLogUtils';

export interface ICompleteFacilityPageProps {}
const NewRegisterStepCompletePage: React.FC<ICompleteFacilityPageProps> = ({}) => {
	const { navigateRegisteredQR } = useVaunceAppRouter();
	const usageId = useAtomValue(VaunceState.usageId);

	const [selectedUsageId, setSelectedUsageId] = useAtom(VaunceState.selectRecentUsageId);

	const finalResult = VaunceQueryState.useInfoByUsageIdQuery(
		selectedUsageId ? selectedUsageId : usageId
	);

	useEffect(() => {
		if (finalResult) {
			entranceLog(finalResult.vo.centerId);
		}
	}, [finalResult]);

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader
				title='시설 이용 등록'
				isGoBackHidden
			/>
			<Stack
				flex={1}
				spacing={5}
				justifyContent={'center'}>
				<Stack
					justifyContent={'center'}
					alignItems={'center'}>
					{/* <img src={warningImage} width={74} /> */}
					<EmptyContent title={''} />
					<Box textAlign={'center'}>
						<Typography
							variant='h5'
							fontWeight={500}
							color={vaunceColors.lightPink}>
							{finalResult.vo.applicant} 님,
						</Typography>
						<Typography
							variant='h5'
							fontWeight={500}
							color={vaunceColors.lightPink}>
							{finalResult.vo.centerDisplay}의
						</Typography>
						<Typography variant='h5'>이용 등록이 완료되었습니다.</Typography>
					</Box>
				</Stack>
				<Typography
					variant='h5'
					textAlign={'center'}>
					센터 방문 후 필히 이용 등록 인증 기기를 통해 <br /> 입장 신청을 하시기 바랍니다.
				</Typography>
			</Stack>

			<RoundedButton
				data-cy='agreeBtnInRegisterStepFour'
				onClick={() => {
					setSelectedUsageId(null);
					navigateRegisteredQR();
					loggingEvent(
						'시설 이용 등록 및 이전 등록 이력 STEP 2 > 다음',
						'route',
						'시설 이용 등록 및 이전 등록 이력 STEP 2 > 다음'
					);
				}}>
				등록된 QR코드 보기
			</RoundedButton>
		</Stack>
	);
};

export default NewRegisterStepCompletePage;
