import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { EmptyContent } from '../../components/EmptyContent';
import { PageHeader } from '../../components/PageHeader';
import QrCode from '../../components/QrCode';
import { useVaunceAppRouter } from '../routes';

export interface ISubmitQrPageProps {}
export const ShowRegisteredQR: React.FC<ISubmitQrPageProps> = ({}) => {
	const { navigateFacilityRegister } = useVaunceAppRouter();
	const userRecentOne = VaunceQueryState.useRecentlyOneQuery();

	const [time, setTime] = useState<number>(30);

	useEffect(() => {
		if (time > 0) {
			setTimeout(() => {
				setTime(time - 1);
			}, 1000);
		} else {
			navigateFacilityRegister();
		}
	});
	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader
				title='등록 QR코드'
				navigateAlterUrl={navigateFacilityRegister}
			/>
			{userRecentOne ? (
				<Stack
					flex={1}
					overflow={'auto'}>
					<TitleBox>
						<Stack
							direction={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}>
							<Typography
								variant='h3'
								color={'primary'}>
								{userRecentOne.centerDisplay}
							</Typography>
							<Typography variant='h5'>{userRecentOne.regDate}</Typography>
						</Stack>

						<Typography
							variant='h5'
							data-cy='applicantInRegisterQR'>
							신청인: {userRecentOne.applicant}
						</Typography>
						<Typography
							variant='h5'
							data-cy='facilityUsersInRegisterQR'>
							이용자: {userRecentOne.userList.join(',')}
						</Typography>
					</TitleBox>

					<Stack
						spacing={3}
						alignItems={'center'}>
						<QrCode
							qrData={userRecentOne.groupNo}
							style={{ marginTop: '4rem' }}
						/>

						<Stack>
							<Stack
								direction={'row'}
								spacing={1}>
								<Typography
									variant='h5'
									fontWeight={500}>
									위 QR코드를{' '}
								</Typography>
								<Typography
									variant='h5'
									fontWeight={500}
									color={'primary'}>
									센터 앞 입장
								</Typography>
							</Stack>

							<Stack direction={'row'}>
								<Typography
									variant='h5'
									fontWeight={500}
									color={'primary'}>
									신청 기기
								</Typography>
								<Typography
									variant='h5'
									fontWeight={500}>
									에 인증해주세요.
								</Typography>
							</Stack>
						</Stack>

						<Stack textAlign={'center'}>
							<Typography
								variant='h5'
								fontWeight={500}>
								본 QR코드는 등록 당일에 한하여 유효하며
							</Typography>
							<Stack direction={'row'}>
								<Typography
									variant='h5'
									fontWeight={500}
									color={'primary'}>
									{time}
								</Typography>
								<Typography
									variant='h5'
									fontWeight={500}>
									초 후 시설이용등록 첫 화면으로 이동합니다.
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			) : (
				<Stack
					flex={1}
					alignItems={'center'}
					justifyContent={'center'}>
					<Box>
						<EmptyContent title='등록된 QR 코드가 없습니다.' />
						<Stack direction={'row'}>
							<Typography
								variant='h5'
								fontWeight={500}
								color={'primary'}>
								{time}
							</Typography>
							<Typography
								variant='h5'
								fontWeight={500}>
								초 후 시설이용등록 첫 화면으로 이동합니다.
							</Typography>
						</Stack>
					</Box>
				</Stack>
			)}
		</Stack>
	);
};

const TitleBox = styled(Box)(({ theme }) => ({
	borderBottom: `1px solid ${vaunceColors.lightPink}`,
	borderTop: `1px solid ${vaunceColors.lightPink}`,
	padding: '15px 17px',
}));
