import { Coupon } from '@kinderlabs-pos/shared-data-type';
import { Box, Stack, Typography } from '@mui/material';
import qrImage from 'assets/vaunce/image/iconqr.png';
import qrImage2x from 'assets/vaunce/image/iconqr@2x.png';

import {
	CouponContentBox,
	CouponDescBox,
	CouponSubDescBox,
	ImageBox,
} from '../../components/CommonStyled';

export interface IUnavailableCouponPanelProps {
	couponList: Coupon[];
}
const UnavailableCouponPanel: React.FC<IUnavailableCouponPanelProps> = ({ couponList }) => {
	return (
		<Box
			marginTop={'10px'}
			height={'100%'}
			overflow={'auto'}>
			{/* {couponList.length === 0 && (
        <EmptyContent title="만료된 쿠폰이 없습니다" />
      )} */}
			{couponList &&
				couponList.map((coupon, idx) => (
					<CouponContentBox
						key={coupon.qrData}
						style={{
							display: 'flex',
							padding: '25px',
							backgroundColor: '#E3E3E3',
						}}>
						<ImageBox>
							<img
								src={qrImage}
								srcSet={qrImage2x}
								width={65}
								height={66}
							/>
						</ImageBox>
						<CouponDescBox>
							<Stack spacing={1}>
								<Typography variant='h3'>{coupon.title}</Typography>

								<CouponSubDescBox>
									<Typography
										color='primary'
										variant='h5'
										marginRight={'auto'}>
										{coupon.subTitle}
									</Typography>

									<Typography variant='h5'> {coupon.expiredAt} 까지</Typography>
								</CouponSubDescBox>
								{coupon.usedAt && (
									<Typography
										variant='h5'
										textAlign={'right'}
										color='primary'>
										{coupon.usedAt} 사용
									</Typography>
								)}
							</Stack>
						</CouponDescBox>
					</CouponContentBox>
				))}
		</Box>
	);
};
export default UnavailableCouponPanel;
