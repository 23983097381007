export const cmtNoticeFirstContent: string[] = [
	'구매 시 선택한 센터에서만 사용이 가능하며, 1일 1회 2시간 이용 가능합니다. (영통센터는 2시간 30분 이용)',
	'일부 센터는 나이와 키에 따라 요금이 다르니 이용자에 맞게 구매하시기 바랍니다.',
	'정기 회원권 결제일 7일 이내에 1회도 사용하지 않은 경우에 한해 전액 환불이 가능합니다.',
	'전액 환불 기간이 지난 경우에도 유효기간 내에 환불이 가능하며, 결제 금액에서 위약금 10%와 사용된 횟수의 금액을(정상가 기준 차감) 공제하고 잔여 금액을 남은 기간의 비율에 따라 차등 환불됩니다.',
	'유효기간이 만료된 후에는 환불이 불가합니다.',
];

export const cmtNoticeSecondContent: string[] = [
	'일부 센터는 현장에서 티켓 사용 시, 이용자의 조건(키 또는 나이)과 맞지 않으면 현장에서 차액에 대해 추가 결제가 필요합니다.',
	'정기 회원권으로 등록된 본인만 이용 가능하며, 본인 확인 불가 시 입장이 제한됩니다.',
];
