import { Badge, Stack, TextField, Typography } from '@mui/material';
import noImage from 'assets/vaunce/image/noimage.png';
import React, { useRef, useState } from 'react';
import { PageHeader } from '../../components/PageHeader';

import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import warningImage from 'assets/vaunce/image/submitwarning.png';
import { FormikProvider } from 'formik';
import { MemberInfoBox, ProfileImageBox } from '../../components/CommonStyled';
import GrayBox from '../../components/GrayBox';
import { PlusBox } from '../../components/PlusBox';
import { VaunceHelperText } from '../../components/VaunceHelperText';
import { vaunceAppFormiks } from '../../utils/useFormik';
import { UserPhotoNoticeDialog } from './dialog/UserPhotoNoticeDialog';

export interface IVipClubRegisterPageProps {}

const noticeContent = [
	'실제 이용하는 자녀 또는 본인의 정보를 입력하세요.',
	'최초 등록한 정보는 수정 불가합니다.',
	'얼굴 식별이 가능한 사진을 등록하세요.',
	'본인 확인이 불가한 경우 입장이 제한됩니다.',
];

const VipClubRegisterPage: React.FC<IVipClubRegisterPageProps> = ({}) => {
	const [imageFile, setImageFile] = useState<string>('');

	const { useVipClubRegisterFormik } = vaunceAppFormiks();
	const formik = useVipClubRegisterFormik();
	const {
		values,
		handleChange,
		isSubmitting,
		handleSubmit,
		errors,
		handleBlur,
		isValid,
		touched,
		setFieldValue,
	} = formik;

	const [open, setOpen] = useState<boolean>(true);

	const handleClose = () => {
		setOpen(false);
	};
	const fileInput = useRef<HTMLInputElement | null>(null);

	const handleChangeProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = (event.target.files as FileList)[0];
		setFieldValue('file', file);

		if (file) {
			const maxSizeInBytes = 5 * 1024 * 1024; // 5MB 제한 (예시)

			if (file.size > maxSizeInBytes) {
				alert('파일 크기가 너무 큽니다. 3MB 이하의 파일을 선택해주세요.');
				fileInput.current = null;
			} else {
				let imageFormData = new FormData();
				imageFormData.append('file', file);

				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					setImageFile(reader.result as string);
				};
			}
		}
	};

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader title='바운스 프리미엄 클럽' />
			<Stack overflow={'auto'}>
				<FormikProvider value={formik}>
					<MemberInfoBox>
						<ProfileImageBox>
							<form>
								<input
									data-cy='profileImgInVipClubRegister'
									type='file'
									style={{ display: 'none' }}
									accept='image/*'
									onChange={handleChangeProfileImage}
									ref={fileInput}
									id='profileImg'
								/>
							</form>

							<Badge
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								sx={{
									'& .MuiBadge-badge': {
										bottom: 12,
										right: 12,
									},
								}}
								badgeContent={<PlusBox />}>
								<img
									src={imageFile ? imageFile : noImage}
									width={64}
									style={{ borderRadius: '50%' }}
								/>
							</Badge>
						</ProfileImageBox>
					</MemberInfoBox>
					<GrayBox sx={{ display: 'flex', mb: '1rem' }}>
						<img
							src={warningImage}
							height={32}
						/>
						<Stack
							direction={'column'}
							sx={{ ml: '2rem' }}>
							<Typography
								color={'primary'}
								variant='h4'
								fontWeight={700}>
								꼭! 확인하세요.
							</Typography>
							<ul style={{ paddingLeft: 0 }}>
								{noticeContent.map((notice, idx) => (
									<li key={idx}>
										<Typography
											variant='h6'
											fontWeight={400}>
											{notice}
										</Typography>
									</li>
								))}
							</ul>
						</Stack>
					</GrayBox>
					<Stack spacing={3}>
						<TextField
							data-cy='vipNameInVipRegister'
							sx={{ mb: '3rem', fontSize: '16px' }}
							fullWidth
							label={
								<Typography
									variant='h6'
									fontWeight={400}>
									이름
								</Typography>
							}
							variant='standard'
							placeholder={'이름을 입력해주세요.'}
							name='vipClubName'
							value={values.vipClubName}
							onBlur={handleBlur}
							onChange={handleChange}
							helperText={
								<VaunceHelperText
									dataCy={'vipClubRegisterNameHelpText'}
									name={`vipClubName`}
								/>
							}
						/>
						<TextField
							data-cy='vipBirthInVipRegister'
							sx={{ mb: '3rem', fontSize: '16px' }}
							fullWidth
							label={
								<Typography
									variant='h6'
									fontWeight={400}>
									생년월일
								</Typography>
							}
							variant='standard'
							placeholder='YYYY/MM/DD'
							name='vipClubBirth'
							value={values.vipClubBirth}
							onBlur={handleBlur}
							onChange={handleChange}
							helperText={
								<VaunceHelperText
									dataCy={'vipClubRegisterBirthHelpText'}
									name={`vipClubBirth`}
								/>
							}
						/>
						<Typography variant='h2'>{open}</Typography>
						<Stack direction={'row'}>
							<RoundedSubmitButton
								data-cy='registerUserBtnInVipClubRegister'
								sx={{ height: 58 }}
								onClick={() => {
									handleSubmit();
								}}
								disabled={!isValid}
								isSubmitting={isSubmitting}
								text={'사용자 등록'}
							/>
						</Stack>
					</Stack>

					<UserPhotoNoticeDialog
						open={open}
						onClose={handleClose}
					/>
					{open}
				</FormikProvider>
			</Stack>
		</Stack>
	);
};

export default VipClubRegisterPage;
