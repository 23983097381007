import { Stack, styled, Typography } from '@mui/material';
import { StepGroup } from './PageHeader';

export interface ILoginTitleBox {
	title: string;
	subtitle?: string;
	showStep?: boolean;
	stepNum?: number;
}
export const LoginTitleBox: React.FC<ILoginTitleBox> = ({
	title,
	subtitle,
	showStep,
	stepNum,
	...others
}) => {
	return (
		<Stack
			direction={'row'}
			justifyContent={showStep ? 'space-between' : ''}>
			<Stack
				sx={{ flex: 1 }}
				direction={'row'}
				alignItems={'center'}>
				<LoginHeaderTypograpy {...others}>{title}</LoginHeaderTypograpy>
				{subtitle && (
					<Stack>
						<Typography
							color='primary'
							variant='h5'
							sx={{ ml: 2, fontWeight: 500 }}>
							{subtitle}
						</Typography>
					</Stack>
				)}
			</Stack>

			{showStep && stepNum && (
				<Stack
					direction={'row'}
					spacing={1}
					alignItems={'center'}>
					<StepGroup stepNum={stepNum} />
				</Stack>
			)}
		</Stack>
	);
};

export const LoginHeaderTypograpy = styled(Typography)(({ theme }) => ({
	fontSize: '35px',
	fontWeight: 700,
	fontFamily: 'Lato',
	marginBottom: theme.spacing(5),
	marginTop: theme.spacing(3),
}));
