import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import closeIconImage from 'assets/vaunce/image/close.png';
import infocardImage from 'assets/vaunce/image/infocard.png';
import qs from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginHeaderTypograpy } from '../../components/LoginTitleBox';
import { useVaunceAppRouter } from '../routes';
import { whitespace } from '@kinderlabs-pos/shared-util';

export interface ISignUpSixthStepProps {}
export const SignUpSixthStep: React.FC<ISignUpSixthStepProps> = ({}) => {
	const { navigateHomepage } = useVaunceAppRouter();
	const searchParams = useLocation().search;
	const query = qs.parse(searchParams, { ignoreQueryPrefix: true });

	const [name, setName] = useState<string>((query['name'] as string) ?? '');

	return (
		<Stack height={'100%'}>
			<img
				style={{ marginLeft: 'auto' }}
				src={closeIconImage}
				height={24}
				width={24}
				onClick={() => {
					navigateHomepage();
				}}
			/>
			<LoginHeaderTypograpy>가입완료</LoginHeaderTypograpy>
			<Stack
				spacing={2}
				alignItems={'center'}>
				<img
					src={infocardImage}
					width={68}
				/>

				<Typography
					color={'primary'}
					variant='h1'>
					{name} 님
				</Typography>
				<Typography variant='h5'>바운스 회원이 되신 것을 환영합니다.</Typography>
				<Stack spacing={1}>
					<Stack
						direction={'row'}
						justifyContent={'center'}>
						<Typography variant='h5'>신규 회원을 위한</Typography>
						<Typography
							variant='h5'
							fontWeight={700}>
							{whitespace}
							3,000 원
						</Typography>
					</Stack>
					<Typography variant='h5'>할인 쿠폰이 지급 되었습니다.</Typography>
				</Stack>

				<RoundedButton
					sx={{
						width: '100%',
					}}
					onClick={() => {
						navigateHomepage();
					}}>
					<Typography variant='h4'> 홈 화면으로 이동</Typography>
				</RoundedButton>
			</Stack>
		</Stack>
	);
};
