import { MembershipType, MyInfoBodyType } from '@kinderlabs-pos/shared-data-type';
import { CloseIconBox, WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Box, DialogContent, Divider, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import QrCode from '../components/QrCode';

export function getGradeMemberShip(membership: number): MembershipType {
	switch (membership) {
		case 1:
			return 'MINT';
		case 2:
			return 'PINK';
		case 3:
			return 'GOLD';
		default:
			return 'NONE';
	}
}

export interface IMemberQrDialogProps extends Omit<XlDialogProps, 'children'> {
	info: MyInfoBodyType;
}
const MemberQrDialog: React.FC<IMemberQrDialogProps> = ({ open, closeDialog, info }) => {
	return (
		<WithDialog.XlDialog
			titleSxProps={{ padding: 0 }}
			maxWidth={'lg'}
			open={open}
			closeDialog={closeDialog}
			fullWidth
			dialogTitle={
				<Box
					display={'flex'}
					justifyContent={'flex-end'}>
					<IconButton onClick={closeDialog}>
						<CloseIconBox />
					</IconButton>
				</Box>
			}>
			<DialogContent>
				<Stack
					spacing={2}
					sx={{ pb: 5 }}>
					<Box>
						<Stack
							direction={'row'}
							alignItems={'center'}>
							<Typography variant='h3'>{info.userName}</Typography>
							<Typography
								variant='h5'
								sx={{ mr: '1rem' }}>
								님
							</Typography>
							<Typography
								variant='h5'
								fontWeight={500}>
								등급 {info.membership}
							</Typography>
						</Stack>

						<Stack
							direction={'row'}
							spacing={0.5}>
							<Typography variant='h5'>포인트는</Typography>
							<Typography
								variant='h5'
								color={'primary'}>
								{info.point}P
							</Typography>
							<Typography variant='h5'>입니다.</Typography>
						</Stack>
					</Box>

					<Divider />

					<Stack
						textAlign={'center'}
						alignItems={'center'}
						spacing={1}>
						<QrCode qrData={info.uid.toString()} />
						<Typography variant='h5'>멤버십 QR 코드</Typography>
					</Stack>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};

export default MemberQrDialog;
