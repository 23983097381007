import { Box, Stack, styled } from '@mui/material';
import { ConsentType } from '../../TermsOfUsePage/termsOfUseData';

const BoldSpan = styled('span')(({ theme }) => ({
	fontWeight: 'bold',
}));

export const PersonAgreeData: ConsentType = [
	{
		title: '1. 개인정보의 수집 및 이용 목적',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<Stack spacing={2}>
								<Box>
									<BoldSpan>수집/이용 항목</BoldSpan>
									<br />
									<BoldSpan>필수</BoldSpan> : 아이디, 비밀번호, 성명, 성별, 생년월일, 휴대전화번호,
									이메일, 주소, CI(본인인증 회원), 법정대리인정보(성명, 관계, 연락처)
									<br />
									<BoldSpan>선택</BoldSpan> : 자녀정보(이름, 생년월일, 성별, 관계), 자주 이용하는
									센터, 추천인 ID
									<br />
									<BoldSpan>수집/이용 목적</BoldSpan>
									<br />
									이용자 식별, 회원 서비스 제공, 본인인증, 만 14세 미만 회원가입 시 확인, 멤버십
									혜택 및 각종 이벤트/행사 정보 안내, 상품수령
								</Box>
							</Stack>
						),
					},
				],
			},
		],
	},
	{
		title: '2. 개인정보의 보유기간 및 이용기간',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<Stack spacing={1}>
								<Box>
									① 당사는 회원 탈퇴 및 개인정보의 수집 목적 또는 제공받은 목적이 달성된 때에는
									회원의 개인정보를 지체 없이 파기합니다. 다만, 다음 각 호의 경우 일정기간 동안
									예외적으로 수집한 회원정보의 전부 또는 일부를 보관할 수 있습니다.
									<br />
									(1) 회원 가입정보
									<br />- 수집한 회원 정보를 회원 탈퇴 후 30일간 보유 (재가입 시 이전 아이디의 사용
									및 회원님의 활동 데이터를 복구 및 무분별한 회원탈퇴 및 재가입으로 인한 부정방지
									목적) 탈퇴한 회원이 자신의 게재물에 대한 삭제를 요청 시 즉각 삭제 처리합니다.
								</Box>
								<Box>
									<br />② 상법 및 ‘전자상거래 등에서 소비자보호에 관한 법률’ 등 관련 법령의 규정에
									의하여 다음과 같이 일정기간 보유해야 할 필요가 있을 경우에는 관련 법령이 정한 기간
									또는 다음 각 호의 기간 동안 회원정보를 보유할 수 있습니다. (1) 계약 또는 청약철회
									등에 관한 기록 (전자상거래법 : 5년) (2) 대금결제 및 재화 등의 공급에 관한 기록
									(전자상거래법 : 5년) (3) 소비자의 불만 또는 분쟁처리에 관한 기록 (전자상거래법 :
									3년) (4) 표시/광고에 관한 기록 (전자상거래법 : 6개월)
								</Box>
								<Box>
									※ 개인정보 수집 및 이용 동의를 거부하실 수 있으며, 이 경우 회원가입이 제한됩니다.
								</Box>
							</Stack>
						),
					},
				],
			},
		],
	},
];
