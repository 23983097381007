import { whitespace } from '@kinderlabs-pos/shared-util';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Box, Stack, Typography } from '@mui/material';
import { StyleTextField } from '../../components/CommonStyled';
import { vaunceAppFormiks } from '../../utils/useFormik';

export interface IPassWordPartProps {}
export const PassWordPart: React.FC<IPassWordPartProps> = ({}) => {
	const { usePasswordFormik } = vaunceAppFormiks();
	const { values, handleChange, isSubmitting, handleSubmit, errors, handleBlur, isValid, touched } =
		usePasswordFormik();

	return (
		<form onSubmit={handleSubmit}>
			<Stack spacing={4}>
				<StyleTextField
					fullWidth
					label={
						<Typography
							variant='h6'
							fontWeight={500}>
							현재 비밀번호
						</Typography>
					}
					type={'password'}
					variant='standard'
					placeholder={'비밀번호를 입력하세요.'}
					name={'pwd'}
					value={values.pwd}
					onChange={handleChange}
					onBlur={handleBlur}
					error={!!(touched.pwd && errors.pwd)}
					helperText={
						<Typography
							color={'primary'}
							fontWeight={500}>
							{touched.pwd ? errors.pwd : whitespace}
						</Typography>
					}
				/>
				<StyleTextField
					fullWidth
					label={
						<Typography
							variant='h6'
							fontWeight={500}>
							새로운 비밀번호
						</Typography>
					}
					type={'password'}
					variant='standard'
					placeholder={'8자리 이상'}
					name={'newPwd'}
					value={values.newPwd}
					onChange={handleChange}
					onBlur={handleBlur}
					error={!!(touched.newPwd && errors.newPwd)}
					helperText={
						<Typography
							color={'primary'}
							fontWeight={500}>
							{touched.newPwd ? errors.newPwd : whitespace}
						</Typography>
					}
				/>
				<StyleTextField
					fullWidth
					label={
						<Typography
							variant='h6'
							fontWeight={500}>
							비밀번호 재입력
						</Typography>
					}
					type={'password'}
					variant='standard'
					placeholder={'8자리 이상'}
					name={'confirmPwd'}
					value={values.confirmPwd}
					onChange={handleChange}
					onBlur={handleBlur}
					error={!!(touched.confirmPwd && errors.confirmPwd)}
					helperText={
						<Typography
							color={'primary'}
							fontWeight={500}>
							{touched.confirmPwd ? errors.confirmPwd : whitespace}
						</Typography>
					}
				/>
				<Stack direction={'row'}>
					<RoundedSubmitButton
						disabled={!isValid}
						isSubmitting={isSubmitting}
						text={'비밀번호 수정'}
						type={'submit'}
						sx={{ width: '100%' }}
					/>
				</Stack>
			</Stack>
		</form>
	);
};
