import { MyInfoBodyType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Badge, Box, Stack, styled, Typography } from '@mui/material';
import noticeImage from 'assets/vaunce/image/icon_Notice.png';
import qrInfoImage from 'assets/vaunce/image/icon_QR.png';
import noticeImage2x from 'assets/vaunce/image/icon_Notice@2x.png';
import qrInfoImage2x from 'assets/vaunce/image/icon_QR@2x.png';

import { useNavigate } from 'react-router-dom';
import MemberQrDialog, { getGradeMemberShip } from '../../dialog/MemberQrDialog';
import { useVaunceAppRouter } from '../../pages/routes';
import { GradeTextBox } from '../GradeTextBox';
import loginImage from 'assets/vaunce/image/login.png';
import loginImage2X from 'assets/vaunce/image/login@2x.png';

import { useAtom } from 'jotai';

export interface IMemberBoxInToolbarProps {}
export const MemberBoxInToolbar: React.FC<IMemberBoxInToolbarProps> = ({}) => {
	const navigate = useNavigate();
	const { navigateLogin } = useVaunceAppRouter();
	const [value, setValue] = useAtom(VaunceState.selectNoticeTabIdx);
	const myInfo = VaunceQueryState.useMyInfoQuery();

	return (
		<Stack
			direction={'row'}
			spacing={'10px'}>
			{myInfo ? (
				<>
					<Stack
						sx={{ cursor: 'pointer' }}
						direction={'column'}
						alignItems={'flex-end'}
						onClick={() => navigate('/vclub')}>
						<ToolBarRightBox>
							<GradeTextBox grade={myInfo.membership} />
						</ToolBarRightBox>
						<Typography
							variant='h6'
							fontWeight={500}
							color={'#FFF'}>
							{myInfo.point}P
						</Typography>
					</Stack>

					{/* <WithDialog
						dialog={(open, closeDialog) => (
							<MemberQrDialog
								open={open}
								closeDialog={closeDialog}
								info={myInfo}
							/>
						)}>
						{(openDialog) => (
							<img
								src={qrInfoImage}
								srcSet={qrInfoImage2x}
								width={32}
								height={32}
								onClick={openDialog}
								style={{ cursor: 'pointer' }}
							/>
						)}
					</WithDialog> */}
					<Badge
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						sx={{
							cursor: 'pointer',
							'& .MuiBadge-badge': {
								top: 10,
								right: 5,
							},
						}}
						badgeContent={<ArlimCountBox myInfo={myInfo} />}
						onClick={() => {
							setValue(0);
							navigate('/notice');
						}}>
						<img
							src={noticeImage}
							srcSet={noticeImage2x}
							width={32}
							height={32}
						/>
					</Badge>
				</>
			) : (
				<Stack direction={'row'}>
					<Stack
						direction={'column'}
						width={'100%'}
						marginRight={'10px'}>
						<Typography
							variant='h6'
							color={'#FFF'}
							fontWeight={'medium'}>
							로그인을 하시면 바운스 클럽의
						</Typography>
						<Typography
							variant='h6'
							color={'#FFF'}
							fontWeight={'medium'}>
							혜택을 받을 수 있습니다.
						</Typography>
					</Stack>
					<img
						height={32}
						width={32}
						data-cy='loginRouteImgBtn'
						src={loginImage}
						srcSet={loginImage2X}
						onClick={() => navigateLogin()}
					/>
				</Stack>
			)}
		</Stack>
	);
};

interface arlimBoxProps {
	myInfo: MyInfoBodyType;
}
const ArlimCountBox: React.FC<arlimBoxProps> = ({ myInfo }) => {
	return (
		<Box
			sx={{
				bgcolor: '#EB0000',
				borderRadius: '50%',
				minWidth: 20,
				height: 20,
				textAlign: 'center',
			}}>
			<Typography
				variant='h4'
				fontSize={'1em'}
				fontWeight={700}
				color='#FFF'>
				{myInfo.arlimCount}
			</Typography>
		</Box>
	);
};

const ToolBarRightBox = styled(Box)(({ theme }) => ({
	border: '1px solid rgba(255, 255, 255, 0.72)',
	borderRadius: '12px',
	color: '#FFF',
	width: 69,
	// height: 32,
	textAlign: 'center',
}));
