import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Stack, Typography } from '@mui/material';
import { PageHeader } from '../../components/PageHeader';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { useVaunceAppRouter } from '../routes';

export interface IEventPageProps {}
export const EventPage: React.FC<IEventPageProps> = ({}) => {
	const eventList = VaunceQueryState.useEventListQuery();

	// startDate 기준으로 가장 최신먼저 보여줌
	const sortedEventList = eventList.sort((a, b) => {
		const dateA = new Date(a.startDate);
		const dateB = new Date(b.startDate);
		return dateB.getTime() - dateA.getTime();
	});
	const { navigateEventDetail } = useVaunceAppRouter();

	return (
		<Stack
			flex={1}
			overflow={'auto'}
			direction={'column'}>
			<Stack padding={'16px 17px 10px'}>
				<PageHeader title='이벤트' />
			</Stack>
			<Stack
				flex={1}
				overflow={'auto'}
				spacing={3}
				sx={{ boxShadow: '3px 3px 10px rgb(0 0 0 / 24%)' }}
				paddingBottom={2}>
				{sortedEventList.map((event, key) => (
					<Stack
						key={key}
						sx={{
							boxShadow: '3px 3px 10px rgb(0 0 0 / 24%)',
							cursor: 'pointer',
						}}
						spacing={3}
						onClick={() => {
							navigateEventDetail(event.id);
							loggingEvent(
								`이벤트 상세보기(${event.topText})`,
								'route',
								`eventDetail(${event.id})`
							);
						}}>
						<Stack
							sx={{
								justifyContent: 'center',
								backgroundImage: `url(${event.imgUrl})`,
								height: 110,
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
								position: 'relative',
								color: 'white',
								textAlign: 'center',
							}}>
							<Typography variant='h2'>{event.topText}</Typography>
							<Typography variant='h2'>{event.bottomText}</Typography>
						</Stack>

						<Stack padding={'0px 17px 10px'}>
							<Typography variant='h4'>{event.title}</Typography>
							<Stack
								direction={'row'}
								spacing={1}>
								<Typography
									variant='h6'
									fontWeight={400}>
									기간 : {event.startDate}
								</Typography>
								<Typography
									variant='h6'
									fontWeight={400}>
									~
								</Typography>
								<Typography
									variant='h6'
									fontWeight={400}>
									{' '}
									{event.endDate}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};
