export interface INicePayPageProps {
	payRef: React.MutableRefObject<any>;
	payModel: any;
	ediDate: string;
	hashString: string;
}
export const NicePayPage: React.FC<INicePayPageProps> = ({
	payRef,
	payModel,
	ediDate,
	hashString,
}) => {
	return (
		<form
			ref={payRef}
			id='inputform'
			name='payForm'
			method='post'
			target='_self'
			action='https://web.nicepay.co.kr/v3/smart/smartPayment.jsp'
			accept-charset='euc-kr'>
			<div style={{ display: 'none' }}>
				<div>NICEPAY PAY REQUEST(UTF-8)</div>
				<div>
					<div>
						<table>
							<colgroup>
								<col width='30%' />
								<col width='*' />
							</colgroup>
							<tr>
								<th>
									<span>결제 수단</span>
								</th>
								<td>
									<select name='PayMethod'>
										<option value='CARD'>신용카드</option>
										<option value='BANK'>계좌이체</option>
										<option value='CELLPHONE'>휴대폰결제</option>
										<option value='VBANK'>가상계좌</option>
									</select>
								</td>
							</tr>
							<tr>
								<th>
									<span>PayMethod</span>
								</th>
								<td>
									<input
										type='hidden'
										name='PayMethod'
										value='CARD|BANK'
									/>
								</td>
							</tr>
							<tr>
								<th>
									<span>결제 상품명</span>
								</th>
								<td>
									<input
										type='hidden'
										name='GoodsName'
										value={payModel.goodsName}
									/>
								</td>
							</tr>
							<tr>
								<th>
									<span>결제 상품개수</span>
								</th>
								<td>
									<input
										type='hidden'
										name='GoodsCnt'
										value={payModel.goodsCnt}
									/>
								</td>
							</tr>
							<tr>
								<th>
									<span>결제 상품금액</span>
								</th>
								<td>
									<input
										type='hidden'
										name='Amt'
										value={payModel.price}
									/>
								</td>
							</tr>
							<tr>
								<th>
									<span>구매자명</span>
								</th>
								<td>
									<input
										type='hidden'
										name='BuyerName'
										value={payModel.buyerName}
									/>
								</td>
							</tr>
							<tr>
								<th>
									<span>구매자 연락처</span>
								</th>
								<td>
									<input
										type='hidden'
										name='BuyerTel'
										value={payModel.buyerTel}
									/>
								</td>
							</tr>
							<tr>
								<th>
									<span>상품 주문번호</span>
								</th>
								<td>
									<input
										type='hidden'
										name='Moid'
										value={payModel.moid}
									/>
								</td>
							</tr>
							<tr>
								<th>
									<span>상점 아이디</span>
								</th>
								<td>
									<input
										type='hidden'
										name='MID'
										value={payModel.mid}
									/>
								</td>
							</tr>

							<input
								type='hidden'
								name='MallIP'
								value={payModel.hostIp}
							/>

							<input
								type='hidden'
								name='VbankExpDate'
								id='vExp'
							/>
							<input
								type='hidden'
								name='BuyerEmail'
								value={payModel.buyerEmail}
							/>
							<input
								type='hidden'
								name='GoodsCl'
								value={payModel.goodsCl}
							/>
							<input
								type='hidden'
								name='CharSet'
								value='utf-8'
							/>
							<input
								type='hidden'
								name='ReturnURL'
								value={payModel.returnURL}
							/>
							<input
								type='text'
								name='ReqReserved'
								value={payModel.reqReserved}
							/>

							<input
								type='hidden'
								name='ediDate'
								value={ediDate}
							/>
							<input
								type='hidden'
								name='EncryptData'
								value={hashString}
							/>
							<input
								type='hidden'
								name='TrKey'
								value=''
							/>
							<input
								type='hidden'
								name='AcsNoIframe'
								value='Y'
							/>
							<input
								type='hidden'
								name='WapUrl'
								value={payModel.WapUrl}
							/>
							<input
								type='hidden'
								name='IspCancelUrl'
								value={payModel.IspCancelUrl}
							/>
						</table>
					</div>
				</div>
			</div>
		</form>
	);
};
