import { useNavigate } from 'react-router-dom';
import { useVaunceAppRouter } from '../pages/routes';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { MenuType } from '../components/SidebarDrawer/DrawerMenuItem';

export const useVaunceAppNavigationWithAuthGuard = () => {
	const { navigateLogin } = useVaunceAppRouter();
	const navigate = useNavigate();

	// 아래처럼 하면 Login 에 대한 기준이 애매해진다. SNS 로그인 유저가 있기 때문.
	// const isLoginned = localStorage.getItem('accessToken') ? true : false;

	const myInfo = VaunceQueryState.useMyInfoQuery();
	const isLoginned = !!myInfo;

	const navigateWithLoginGuard = (menu: MenuType) => {
		if (menu.needLoginAssertion && !isLoginned) {
			alert('로그인이 필요한 메뉴입니다.');
			navigateLogin();
		} else {
			navigate(menu.url);
		}
	};

	return {
		navigateWithLoginGuard,
		isLoginned,
	};
};
