import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Stack, Switch, Typography } from '@mui/material';
import packageJson from 'package.json';
import { useState } from 'react';
import { PageHeader } from '../../components/PageHeader';

export interface ISettingPageProps {}
export const SettingPage: React.FC<ISettingPageProps> = ({}) => {
	const setting = VaunceQueryState.useSettingQuery();
	const changePushAgreeMutate = VaunceQueryState.useChangePushAgreeMutation();
	const changeAdAgreeMutate = VaunceQueryState.useChangeAdAgreeMutation();

	const [pushCheck, setPushCheck] = useState<boolean>(setting.push);
	const [marketCheck, setMarketCheck] = useState<boolean>(setting.ad === 'AGREE' ? true : false);

	return (
		<Stack height={'100%'}>
			<PageHeader title='설정' />
			<Stack spacing={3}>
				<Stack
					p={1}
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.14)' }}>
					<Typography variant='h3'>Push 동의</Typography>
					<Switch
						checked={pushCheck}
						onChange={async () => {
							const res = await changePushAgreeMutate.mutateAsync(pushCheck);
							if (res) {
								alert(pushCheck ? '푸쉬 수신 거부하셨습니다.' : '푸쉬 수신 동의하셨습니다.');
							}
							setPushCheck(!pushCheck);
						}}
					/>
				</Stack>

				<Stack
					p={1}
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.14)' }}>
					<Typography variant='h3'>마켓팅 및 광고 활용 동의</Typography>
					<Switch
						checked={marketCheck}
						onChange={async () => {
							const res = await changeAdAgreeMutate.mutateAsync(marketCheck);
							if (res) {
								alert(marketCheck ? '광고성 수신 거부하셨습니다.' : '광고성 수신 동의하셨습니다.');
							}
							setMarketCheck(!marketCheck);
						}}
					/>
				</Stack>

				<Typography
					sx={{ p: 1 }}
					variant='h3'
					color={vaunceColors.pink}>
					시스템
				</Typography>
				<Stack
					p={1}
					direction={'row'}
					alignItems={'center'}
					justifyContent={'space-between'}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.14)' }}>
					<Typography variant='h3'>버전정보</Typography>
					<Typography variant='h3'>{packageJson.version}</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};
