import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Stack, Typography } from '@mui/material';
import account from 'assets/vaunce/image/account.png';
import logout from 'assets/vaunce/image/logout.png';

import account2x from 'assets/vaunce/image/account@2x.png';
import logout2x from 'assets/vaunce/image/logout@2x.png';

import { useNavigate } from 'react-router-dom';

export interface ISidebarDrawerHeaderProps {}
export const SidebarDrawerHeader: React.FC<ISidebarDrawerHeaderProps> = ({}) => {
	const navigate = useNavigate();

	const myInfo = VaunceQueryState.useMyInfoQuery();

	if (!myInfo) throw Error();

	return (
		<Stack
			direction={'row'}
			justifyContent={'space-between'}
			width={'100%'}
			px={1}>
			<Stack direction={'row'}>
				<Typography variant='h3'>{myInfo.userName} 님</Typography>
				<img
					src={account}
					srcSet={account2x}
					width={24}
					height={24}
					style={{ marginLeft: '10px' }}
				/>
			</Stack>

			<Stack
				direction={'row'}
				alignItems={'center'}>
				<img
					src={logout}
					srcSet={logout2x}
					width={24}
					height={24}
				/>
				<Typography
					onClick={() => {
						alert('로그아웃을 하였습니다.');
						localStorage.removeItem('accessToken');
						localStorage.removeItem('refreshToken');
						localStorage.removeItem('auth');
						localStorage.removeItem('provider');
						navigate('/');
					}}
					variant='h5'
					color={'primary'}
					sx={{ cursor: 'pointer' }}>
					Logout
				</Typography>
			</Stack>
		</Stack>
	);
};
