import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { List, ListItemButton, Stack, Typography } from '@mui/material';
import QuestionIcon from 'assets/vaunce/image/question.png';
import QuestionIcon2x from 'assets/vaunce/image/question@2x.png';

import React from 'react';
import { PageHeader } from '../../components/PageHeader';
import { FAQData } from './FAQData';
import { FAQDialog } from './FAQDialog';

export interface I자주묻는질문PageProps {}
export const FAQPage: React.FC<I자주묻는질문PageProps> = ({}) => {
	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='자주 묻는 질문' />
			<List
				sx={{
					borderTop: `1px solid ${vaunceColors.lightGray}`,
					py: 0,
					flex: 1,
					overflow: 'inherit',
				}}>
				{FAQData.map((item, idx) => {
					return (
						<WithDialog
							key={idx}
							dialog={(open, closeDilalog) => (
								<FAQDialog
									open={open}
									closeDialog={closeDilalog}
									title={item.title}
									contents={item.description}
								/>
							)}>
							{(openDialog) => (
								<ListItemButton
									onClick={openDialog}
									sx={{
										borderBottom: `1px solid ${vaunceColors.lightGray}`,
										px: '10px',
										py: '15px',
									}}>
									<Stack
										direction={'row'}
										spacing={1}
										alignItems={'center'}>
										<img
											src={QuestionIcon}
											srcSet={QuestionIcon2x}
											style={{ width: 18, height: 18 }}
										/>
										<Typography
											variant='h4'
											sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
											{item.title}
										</Typography>
									</Stack>
								</ListItemButton>
							)}
						</WithDialog>
					);
				})}
			</List>
		</Stack>
	);
};
