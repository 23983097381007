import { Stack, Typography } from '@mui/material';
import errorImage from 'assets/vaunce/image/wifi_remove.png';

export interface IVaunceErrorComponentProps {}
export const VaunceErrorComponent: React.FC<IVaunceErrorComponentProps> = ({}) => {
	return (
		<Stack p={3}>
			<Typography variant='h1'>오프라인 상태입니다.</Typography>
			<Stack
				alignItems={'center'}
				mt={20}
				spacing={10}>
				<img
					src={errorImage}
					width={74}
				/>
				<Stack textAlign={'left'}>
					<Typography variant='h5'>다음을 시도:</Typography>
					<li style={{ fontSize: '14px', fontWeight: 400 }}>비행기 모드 사용 중지</li>
					<li style={{ fontSize: '14px', fontWeight: 400 }}>모바일 데이터 또는 Wi-Fi 사용 설정</li>
					<li style={{ fontSize: '14px', fontWeight: 400 }}>현재 지역의 신호확인</li>
				</Stack>
			</Stack>
		</Stack>
	);
};
