import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Box, InputLabel, NativeSelect, Stack, Typography } from '@mui/material';
import { FieldArray, FormikProvider } from 'formik';
import { StyleFormControl, StyleTextField } from '../../components/CommonStyled';
import VaunceButton from '../../components/VaunceButton';
import { vaunceAppFormiks } from '../../utils/useFormik';

export interface IExtraInfoPartProps {}
export const ExtraInfoPart: React.FC<IExtraInfoPartProps> = ({}) => {
	const { useExtraInfoFormik } = vaunceAppFormiks();
	const formik = useExtraInfoFormik();
	const { initialValues, values, handleChange, isSubmitting, handleSubmit, isValid } = formik;
	return (
		<FormikProvider value={formik}>
			<FieldArray name='centerList'>
				{({ remove, push }) => (
					<>
						{values.centerList.map((center, idx) => (
							<Stack
								direction={'row'}
								alignItems={'center'}
								key={idx}
								sx={
									values.centerList.length > 1
										? { pb: 3, borderBottom: `1px dashed ${vaunceColors.lightPink}`, mb: 3 }
										: {}
								}>
								<StyleFormControl style={{ flex: 1 }}>
									<InputLabel
										sx={{
											fontWeight: 400,
										}}
										variant='standard'
										htmlFor='uncontrolled-native'>
										자주이용하는 센터(선택)
									</InputLabel>
									<NativeSelect
										sx={{
											fontSize: '16px',
											fontWeight: 400,
										}}
										name={`centerList.${idx}`}
										value={center}
										onChange={handleChange}>
										{values.centerCollections.map((centerColect, index) => (
											<option
												value={centerColect.centerId}
												key={index}>
												{centerColect.centerDisplay}
											</option>
										))}
									</NativeSelect>
								</StyleFormControl>
								<VaunceButton
									style={{ width: '20%', height: '50%' }}
									onClick={() => {
										remove(idx);
									}}>
									삭제
								</VaunceButton>
							</Stack>
						))}
						<VaunceButton
							sx={{ width: '100%', mb: '3rem' }}
							onClick={() => push(0)}>
							+ 자주이용하는 센터
						</VaunceButton>
						<StyleTextField
							fullWidth
							InputProps={{
								style: { fontSize: '16px', fontWeight: 400 },
							}}
							label={
								<Typography
									variant='h6'
									fontWeight={400}>
									추천인 ID (선택)
								</Typography>
							}
							name={'recommender'}
							value={values.recommender}
							onChange={handleChange}
							variant='standard'
							placeholder='추천인 ID를 입력하세요.'
						/>
						<Box>
							<RoundedSubmitButton
								disabled={!isValid}
								isSubmitting={isSubmitting}
								onClick={() => handleSubmit()}
								text={'추가정보 저장'}
								type={'submit'}
								sx={{ width: '100%' }}
							/>
						</Box>
					</>
				)}
			</FieldArray>
		</FormikProvider>
	);
};
