import { SNSProviderType } from '@kinderlabs-pos/shared-data-type';
import { Box, IconButton } from '@mui/material';
import { loggingEvent } from 'apps/vaunce-app/src/utils/firebaseAnalytics';
import appleImage from 'assets/vaunce/image/applelogoRect.png';
import kakaoImage from 'assets/vaunce/image/kakao.png';
import naverImage from 'assets/vaunce/image/naver.png';
import kakaoImage2x from 'assets/vaunce/image/kakao@2x.png';
import naverImage2x from 'assets/vaunce/image/naver@2x.png';
import { backendApiRootUrl } from '@kinderlabs-pos/shared-const';

type SNSImageType = SNSProviderType | 'naver2x' | 'kakao2x' | 'apple2x';
const imageMap: Record<SNSImageType, string> = {
	naver: naverImage,
	kakao: kakaoImage,
	apple: appleImage,
	naver2x: naverImage2x,
	kakao2x: kakaoImage2x,
	apple2x: appleImage,
};

export const SNSLoginButton = ({ provider }: { provider: SNSProviderType }) => {
	const handleClickButton = async () => {
		// 추후 user 가 atom 에 있는지 등으로 확인
		localStorage.setItem('auth', 'sns');

		if (provider === 'kakao') {
			window.location.href = `${backendApiRootUrl}/oauth2/authorization/kakao?redirect_uri=${backendApiRootUrl}/reactsignsns.html`;
		} else if (provider === 'naver')
			window.location.href = `${backendApiRootUrl}/oauth2/authorization/naver?redirect_uri=${backendApiRootUrl}/reactsignsns.html`;
		else if (provider === 'apple') {
			window.location.href = `${backendApiRootUrl}/oauth2/authorization/apple?redirect_uri=${backendApiRootUrl}/reactsignsns.html`;
		}
		loggingEvent(`SNS 로그인(${provider})`, 'login', `${provider}login`);
	};

	return (
		<IconButton onClick={handleClickButton}>
			<Box style={{ height: 32, overflow: 'hidden', borderRadius: '50%' }}>
				<img
					src={imageMap[provider]}
					srcSet={imageMap[`${provider}2x`]}
					style={{ width: 32, height: 32 }}
				/>
			</Box>
		</IconButton>
	);
};
