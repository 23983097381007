import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import messageBullet from 'assets/vaunce/image/message-bulleted.png';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { vaunceColors } from '@kinderlabs-pos/shared-const';
export interface IPopUpPushDialogProps {
	open: boolean;
	handleClose: () => void;
}
export const PopUpPushDialog: React.FC<IPopUpPushDialogProps> = ({ open, handleClose }) => {
	const [popUpCookie, setPopupCookie] = useCookies(['popup-push']);
	const handleClick = (type: boolean) => {
		setPopupCookie('popup-push', type, { path: '/', expires: dayjs().add(3, 'M').toDate() });
		handleClose();
	};
	return (
		<Dialog
			open={open}
			onClose={handleClose}>
			<DialogContent>
				<Stack>
					<Stack
						justifyContent={'center'}
						alignItems={'center'}
						mb={'25px'}>
						<img src={messageBullet} />
					</Stack>
					<Stack
						justifyContent={'center'}
						alignItems={'center'}
						mb={'35px'}>
						<Typography variant='h4'>{'새로운 서비스 및 이벤트 정보등 안내를'}</Typography>
						<Typography variant='h4'>{'위한 알림 및 푸시에 동의 하십니까?'}</Typography>
					</Stack>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={2}>
						<RoundedButton
							data-cy='disagreePushBtn'
							onClick={() => {
								handleClick(false);
								loggingEvent(`푸시 > 동의 안함`, 'agree', `pushAgreeN`);
							}}
							sx={{
								flex: 1,
								backgroundColor: ' white',
								color: vaunceColors.pink,
								fontSize: '16px',
								fontWeight: 500,
								height: 38,
							}}>
							{'동의안함'}
						</RoundedButton>
						<RoundedButton
							data-cy='agreePushBtn'
							onClick={() => {
								handleClick(true);
								loggingEvent(`푸시 > 동의함`, 'agree', `pushAgreeY`);
							}}
							sx={{ flex: 1, fontSize: '16px', fontWeight: 500, height: 38 }}>
							{'동의'}
						</RoundedButton>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
