import { ButtonProps } from '@mui/material';
import VaunceButton from './VaunceButton';
export interface IVaunceOptionButtonProps extends ButtonProps {
	options: boolean;
	text: string;
}

export const VaunceOptionButton: React.FC<IVaunceOptionButtonProps> = ({
	options,
	text,
	...others
}) => {
	return (
		<VaunceButton
			sx={
				options
					? {
							flex: 1,
							height: '38px',
							color: 'rgba(0, 0, 0, 0.40)',
							border: '1px solid #F4F4F4',
							backgroundColor: '#F4F4F4',
							borderRadius: '6px',
							p: '6px 0',
					  }
					: { flex: 1, height: '38px' }
			}
			{...others}>
			{text}
		</VaunceButton>
	);
};
