import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Box, FormControl, FormControlLabel, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { VaunceCheckBox } from '../../components/VaunceCheckBox';

import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginTitleBox } from '../../components/LoginTitleBox';
import { LoginPageHeader } from '../Login/LoginPageHeader';
import { useVaunceAppRouter } from '../routes';
import { MarketingAgreeDialog } from './dialog/MarketingAgreeDialog';
import { PersonInfoAgreeDialog } from './dialog/PersonInfoAgreeDialog';
import { ServiceAgreeDialog } from './dialog/ServiceAgreeDialog';
import { useAtom } from 'jotai';
import { VaunceState } from '@kinderlabs-pos/state';

export interface ISignUpSecondStepProps {}

export const SignUpSecondStep: React.FC<ISignUpSecondStepProps> = ({}) => {
	const navigate = useNavigate();
	const searchParams = useLocation().search;
	const query = qs.parse(searchParams, { ignoreQueryPrefix: true });

	//우선 apple은 userId를 snsToken 안에 넣어둔다..
	const userIdForApple = (query['userIdForApple'] as string) ?? '';
	if (userIdForApple) {
		localStorage.setItem('provider', 'apple');
		localStorage.setItem('snsToken', userIdForApple);
	}
	const { navigateSignUpStepThree } = useVaunceAppRouter();

	const [checked, setChecked] = React.useState([false, false, false]);

	const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, event.target.checked, event.target.checked]);
		localStorage.setItem('adAgree', event.target.checked ? 'Y' : 'N');
	};

	const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, checked[1], checked[2]]);
	};

	const handleChangeSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([checked[0], event.target.checked, checked[2]]);
	};

	const handleChangeThird = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([checked[0], checked[1], event.target.checked]);
		localStorage.setItem('adAgree', event.target.checked ? 'Y' : 'N');
	};

	const children = (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Stack flexDirection={'row'}>
				<CheckLabelBox
					label={<Typography variant='h5'>[필수] 서비스 이용약관</Typography>}
					sx={{ fontSize: '1.3em' }}
					control={
						<VaunceCheckBox
							checked={checked[0]}
							onChange={handleChangeFirst}
							size={'small'}
						/>
					}
				/>
				<ServiceAgreeBtn />
			</Stack>

			<Stack flexDirection={'row'}>
				<CheckLabelBox
					label={<Typography variant='h5'>[필수] 개인정보 이용 동의</Typography>}
					sx={{ fontSize: '1.3em' }}
					control={
						<VaunceCheckBox
							checked={checked[1]}
							onChange={handleChangeSecond}
							size={'small'}
						/>
					}
				/>
				<PersonInfoAgreeBtn />
			</Stack>
			<Stack flexDirection={'row'}>
				<CheckLabelBox
					label={<Typography variant='h5'>[선택] 마케팅 활용 동의</Typography>}
					style={{ fontSize: '1.3em' }}
					control={
						<VaunceCheckBox
							checked={checked[2]}
							onChange={handleChangeThird}
							size={'small'}
						/>
					}
				/>
				<MarketingAgreeBtn />
			</Stack>
		</Box>
	);

	return (
		<Stack>
			<LoginPageHeader />
			<LoginTitleBox
				title='약관동의'
				showStep
				stepNum={1}></LoginTitleBox>
			<FormControl
				fullWidth
				sx={{ mb: 2 }}>
				<Box
					display={'flex'}
					sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }}>
					<FormControlLabel
						label={
							<Typography
								variant='h5'
								color={vaunceColors.lightPink}>
								전체동의
							</Typography>
						}
						sx={{
							mr: 'auto',
							fontSize: '1.3em',
						}}
						control={
							<VaunceCheckBox
								checked={checked[0] && checked[1] && checked[2]}
								onChange={handleChangeAll}
							/>
						}
					/>
				</Box>
				{children}
			</FormControl>
			<RoundedButton
				disabled={!checked[0] || !checked[1]}
				onClick={() => {
					navigateSignUpStepThree();
				}}>
				약관 동의 완료
			</RoundedButton>
		</Stack>
	);
};

const CheckLabelBox = styled(FormControlLabel)(({ theme }) => ({
	marginRight: 'auto',
}));

export const ServiceAgreeBtn = () => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<ServiceAgreeDialog
					open={open}
					closeDialog={closeDialog}
				/>
			)}>
			{(openDialog) => (
				<DetailBox
					sx={{ cursor: 'pointer' }}
					onClick={openDialog}>
					<Typography
						color='primary'
						variant='h6'>
						자세히 보기
					</Typography>
					<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}`, height: 15 }} />
				</DetailBox>
			)}
		</WithDialog>
	);
};

export const PersonInfoAgreeBtn = () => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<PersonInfoAgreeDialog
					open={open}
					closeDialog={closeDialog}
				/>
			)}>
			{(openDialog) => (
				<DetailBox
					sx={{ cursor: 'pointer' }}
					onClick={openDialog}>
					<Typography
						color='primary'
						variant='h6'>
						자세히 보기
					</Typography>
					<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}`, height: 15 }} />
				</DetailBox>
			)}
		</WithDialog>
	);
};

export const MarketingAgreeBtn = () => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<MarketingAgreeDialog
					open={open}
					closeDialog={closeDialog}
				/>
			)}>
			{(openDialog) => (
				<DetailBox
					sx={{ cursor: 'pointer' }}
					onClick={openDialog}>
					<Typography
						color='primary'
						variant='h6'>
						자세히 보기
					</Typography>
					<ArrowForwardIosIcon sx={{ color: `${vaunceColors.lightPink}`, height: 15 }} />
				</DetailBox>
			)}
		</WithDialog>
	);
};

const DetailBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
}));
