import { Stack, styled, Typography } from '@mui/material';
import { PageHeader } from '../../components/PageHeader';
import { TermsOfUseData } from './termsOfUseData';

export const TermsOfUsePage = ({}) => {
	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='이용약관' />
			<Stack
				spacing={2}
				sx={{ flex: 1, overflow: 'inherit' }}>
				<Typography variant='h4'>
					㈜바운스의 모바일 서비스 및 멤버십 프로그램 이용과 관련하여 필요한 사항을 규정합니다.
				</Typography>
				{TermsOfUseData.map((item, idx) => (
					<Stack
						key={idx}
						spacing={2}>
						<Typography variant='h4'>{item.title}</Typography>
						{item.contents.map((item, idx) => (
							<Stack
								spacing={1}
								key={idx}>
								<Typography variant='h3'>{item.subtitle}</Typography>
								{item.type === 'text' ? (
									<Typography variant='h4'>{item.actualContent[0].detail}</Typography>
								) : (
									<ol>
										{item.actualContent.map((item, idx) => (
											<>
												<Typography
													variant='h4'
													key={idx}>
													<li>{item.detail}</li>
												</Typography>
												{item.subDetail ? (
													<ol>
														{item.subDetail.map((item, idx) => (
															<Typography
																key={idx}
																variant='h4'>
																<li>{item}</li>
															</Typography>
														))}
													</ol>
												) : (
													<></>
												)}
											</>
										))}
									</ol>
								)}
							</Stack>
						))}
					</Stack>
				))}
				<Typography variant='h3'>[부칙] 본 약관은 2021년 12월 1일부터 시행합니다.</Typography>
			</Stack>
		</Stack>
	);
};
