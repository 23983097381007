import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import warningImage from 'assets/vaunce/image/pinkwarning.png';
import { useAtom, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';
import { loggingEvent } from '../../utils/firebaseAnalytics';

export interface IFacilityHistoryPageProps {}
const FacilityRegisterHistoryPage: React.FC<IFacilityHistoryPageProps> = ({}) => {
	const { navigateNewRegisterStepOne, navigateNewRegisterStepTwo } = useVaunceAppRouter();

	const [selectedUsageId, setSelectedUsageId] = useAtom(VaunceState.selectRecentUsageId);

	const usageFacility = VaunceQueryState.useFacilityUsageQuery();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedUsageId(Number((event.target as HTMLInputElement).value));
	};

	const setUsageId = useSetAtom(VaunceState.usageId);

	const myInfo = VaunceQueryState.useMyInfoQuery();

	if (!myInfo) return <></>;

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='등록 이력' />
			{usageFacility.list.length === 0 ? (
				<>
					<Stack
						flex={1}
						overflow={'inherit'}
						textAlign={'center'}
						justifyContent={'center'}>
						<Stack spacing={2}>
							<Box>
								<img src={warningImage} />
							</Box>
							<Typography
								color={'primary'}
								variant='h5'
								fontWeight={500}>
								{myInfo.userName}님
							</Typography>
							<Box>
								<Typography
									variant='h5'
									fontWeight={500}>
									시설 이용 등록된 이력이 없습니다.
								</Typography>
								<Typography
									variant='h5'
									fontWeight={500}>
									신규 등록해 주시기 바랍니다.
								</Typography>
							</Box>
						</Stack>
					</Stack>
					<Stack mt={'auto'}>
						<RoundedButton onClick={navigateNewRegisterStepOne}>신규 등록하기</RoundedButton>
					</Stack>
				</>
			) : (
				<Stack spacing={3}>
					<RadioGroup onChange={handleChange}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell>등록일/센터명</TableCell>
									<TableCell>신청자</TableCell>
									<TableCell>이용자</TableCell>
									<TableCell>삭제</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{usageFacility.list.map((elem, idx) => (
									<TableRow key={idx}>
										<TableCell>
											<FormControlLabel
												value={elem.usageId}
												control={<Radio />}
												label=''
											/>
										</TableCell>
										<TableCell>
											<Typography variant='h5'>{elem.regDate}</Typography>
											<Typography variant='h5'>{elem.centerDisplay}</Typography>
										</TableCell>
										<TableCell>{elem.guardianName}</TableCell>
										<TableCell>{elem.centerUserList[0].name}</TableCell>
										<TableCell>
											<DeleteIcon
												style={{ color: `${vaunceAppColors.lightPink}` }}
												onClick={async () => {
													const data = await VaunceAppApis.deleteFacilityByUsageId(elem.usageId);
													if (data) {
														//TODO 만약 성공하면 제거 formik 을 적용하던지 해야함
														console.log('success delete api', data);
													}
													loggingEvent(
														`시설 이용(이전 등록 이력) STEP 1 > 삭제`,
														'route',
														'시설 이용(이전 등록 이력) STEP 1 > 삭제'
													);
												}}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</RadioGroup>
					<RoundedButton
						onClick={async () => {
							loggingEvent(
								`시설 이용(이전 등록 이력) STEP 1 > 다음`,
								'route',
								'시설 이용(이전 등록 이력) STEP 1 > 다음'
							);
							// setUsageId(selectedUsageId);
							navigateNewRegisterStepTwo();
						}}>
						등록정보 사용하기
					</RoundedButton>
				</Stack>
			)}
		</Stack>
	);
};

export default FacilityRegisterHistoryPage;
