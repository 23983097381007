import { vaunceColors } from '@kinderlabs-pos/shared-const';
import {
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from '@mui/material';
import { FormikValues } from 'formik';
import { RoundBox } from '../../../components/RoundBox';
export interface INewRegisterRadioAvaliableTypeProps {
	formik: FormikValues;
}

const radioOptionObject = [
	{ value: 'ENTER', title: '입장 (보호자 입장권을 구매하는 경우)' },
	{ value: 'USING', title: '입장 + 시설이용 (본인이 직접 시설을 이용하는 경우)' },
	{ value: 'NOT_USING', title: '입장 안 함 (자녀만 입장하는 경우)' },
];

export const NewRegisterRadioAvaliableType: React.FC<INewRegisterRadioAvaliableTypeProps> = ({
	formik,
}) => {
	const { values, handleChange } = formik;

	return (
		<Stack spacing={2}>
			<Typography variant='h2'>이용 여부 선택</Typography>
			<RoundBox>
				<Typography
					variant='h5'
					color={vaunceColors.lightPink}>
					신청자(보호자)의 시설 이용 여부를 선택해 주세요.
				</Typography>
			</RoundBox>

			<FormControl>
				<RadioGroup
					data-cy='facilityRadioInRegisterStepOne'
					value={values.availableType}
					name='availableType'
					onChange={handleChange}>
					{radioOptionObject.map((option) => (
						<>
							<FormControlLabel
								sx={{ py: 1 }}
								value={option.value}
								control={<Radio />}
								label={<Typography variant='h5'>{option.title}</Typography>}
							/>
							<Divider />
						</>
					))}
				</RadioGroup>
			</FormControl>
		</Stack>
	);
};
