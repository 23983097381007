import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import {
	CenterCollectionsType,
	HoursValueArray,
	HoursValueTypeLabel,
	HourValueArray,
	HourValueTypeLabel,
	MinutesTypeLabel,
	MinutesValueArray,
	PartyQuestionType,
	PartyQuestionTypeArray,
	PartyTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { RoundedButton, RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import {
	Checkbox,
	FormControl,
	InputLabel,
	NativeSelect,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { FormikProvider } from 'formik';

import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { VaunceHelperText } from '../../components/VaunceHelperText';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { vaunceAppFormiks } from '../../utils/useFormik';
import { useVaunceAppRouter } from '../routes';

export const PartyQeustionStepOnePage = ({}) => {
	const { navigatePartyQuestionStepTwo } = useVaunceAppRouter();
	const [appliedInfo, setAppliedInfo] = useAtom(VaunceState.partyQuestionRequestInfo);

	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();
	const { usePartyQuestionFormik } = vaunceAppFormiks();
	const formik = usePartyQuestionFormik();
	const {
		isSubmitting,
		handleSubmit,
		isValid,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		setValues,
		setFieldValue,
	} = formik;

	return (
		<Stack flex={1}>
			<PageHeader
				title={'예약문의'}
				showStep
				stepNum={1}
			/>
			<Stack spacing={4}>
				<Typography variant='h3'>{'프로그램 안내'}</Typography>
				<FormikProvider value={formik}>
					<Stack>
						<FormControl
							variant='standard'
							fullWidth
							sx={{ fontSize: '16px' }}>
							<InputLabel>
								<Typography
									variant='h6'
									fontWeight={400}>
									{'센터'}
								</Typography>
							</InputLabel>
							<NativeSelect
								sx={{ fontSize: '16px', fontWeight: 400, opacity: 0.6 }}
								value={values.centerSelect}
								name='centerSelect'
								data-cy='centerInPartyQuestion'
								onChange={(e) => {
									setFieldValue('centerSelect', e.target.value);
								}}
								onBlur={handleBlur}>
								{centerCollectionData.map((item, idx) => (
									<option
										key={idx}
										value={item.centerId}>
										{item.centerDisplay}
									</option>
								))}
							</NativeSelect>
						</FormControl>
					</Stack>
					<Stack>
						<FormControl
							variant='standard'
							fullWidth
							sx={{ fontSize: '16px' }}>
							<InputLabel>
								<Typography
									variant='h6'
									fontWeight={400}>
									{'상품'}
								</Typography>
							</InputLabel>
							<NativeSelect
								sx={{ fontSize: '16px', fontWeight: 400, opacity: 0.6 }}
								data-cy='goodsInPartyQuestion'
								onBlur={handleBlur}
								value={values.goods}
								name='goods'
								onChange={(e) => {
									setFieldValue('goods', e.target.value as PartyQuestionType);
								}}>
								{PartyQuestionTypeArray.map((item) => (
									<option
										key={item}
										value={item}>
										{PartyTypeLabel[item]}
									</option>
								))}
							</NativeSelect>
						</FormControl>
					</Stack>
					<RoundedButton
						data-cy='partyQuestionstepOneBtn'
						onClick={() => {
							setAppliedInfo({
								...values,
								centerSelect: values.centerSelect,
								goods: values.goods,
							});
							navigatePartyQuestionStepTwo();
						}}>
						다음단계
					</RoundedButton>
				</FormikProvider>
			</Stack>
		</Stack>
	);
};

export const PartyQeustionStepTwoPage = ({}) => {
	const { navigatePartyQuestionStepThree } = useVaunceAppRouter();

	const UsageInfo = VaunceQueryState.useMyInfoQuery();

	const setAppliedInfo = useSetAtom(VaunceState.partyQuestionRequestInfo);

	const { usePartyQuestionFormik } = vaunceAppFormiks();
	const formik = usePartyQuestionFormik();
	const { handleSubmit, isValid, values, handleChange, handleBlur, touched, errors } = formik;

	useEffect(() => {
		if (values.isSameWithApplicant && UsageInfo) {
			setAppliedInfo({
				...values,
				memberinfoName: UsageInfo.userName,
				memberinfoPhone: UsageInfo.userPhone,
			});
		} else {
			setAppliedInfo({
				...values,
				memberinfoName: '',
				memberinfoPhone: '',
			});
		}
	}, [values.isSameWithApplicant]);

	return (
		<Stack flex={1}>
			<PageHeader
				title={'예약문의'}
				showStep
				stepNum={2}
			/>
			<FormikProvider value={formik}>
				<Stack
					direction={'row'}
					alignItems={'center'}>
					<Typography variant='h3'>신청인 정보</Typography>
					<Checkbox
						data-cy='checkboxInPartyQuestion'
						checked={values.isSameWithApplicant}
						name='isSameWithApplicant'
						onChange={handleChange}
						sx={{ fontSize: 28 }}
					/>
					<Typography
						variant='h5'
						sx={{
							color: values.isSameWithApplicant ? vaunceAppColors.lightPink : 'black',
						}}>
						회원 정보와 동일
					</Typography>
				</Stack>
				<Stack spacing={5}>
					<Stack spacing={2}>
						<TextField
							value={values.memberinfoName}
							name='memberinfoName'
							fullWidth
							label={
								<Typography
									variant='h6'
									fontWeight={400}>
									이름
								</Typography>
							}
							variant='standard'
							placeholder='이름을 입력하세요.'
							sx={{ fontSize: '16px', pt: 1 }}
							onBlur={handleBlur}
							onChange={handleChange}
							data-cy='memberinfoNameInPartyQuestion'
							helperText={
								<VaunceHelperText
									dataCy={'memberinfoHelperTextNameInPartyQuestion'}
									name={`memberinfoName`}
								/>
							}
						/>
						<TextField
							value={values.memberinfoPhone}
							name='memberinfoPhone'
							fullWidth
							label={
								<Typography
									variant='h6'
									fontWeight={400}>
									전화번호
								</Typography>
							}
							variant='standard'
							placeholder='(-) 없이 휴대전화번호를 입력하세요.'
							sx={{ fontSize: '16px', pt: 1 }}
							onChange={handleChange}
							onBlur={handleBlur}
							data-cy='memberinfoPhoneInPartyQuestion'
							helperText={
								<VaunceHelperText
									dataCy={'memberinfoHelperTextPhoneInPartyQuestion'}
									name={`memberinfoPhone`}
								/>
							}
						/>
					</Stack>
					<RoundedButton
						data-cy='partyQuestionstepTwoBtn'
						disabled={values.memberinfoName !== '' && values.memberinfoPhone !== '' ? false : true}
						onClick={() => {
							setAppliedInfo({
								...values,
								memberinfoName: values.memberinfoName,
								memberinfoPhone: values.memberinfoPhone,
							});
							navigatePartyQuestionStepThree();
						}}>
						다음단계
					</RoundedButton>
				</Stack>
			</FormikProvider>
		</Stack>
	);
};

export const PartyQuestionStepThreePage = ({}) => {
	const { usePartyQuestionFormik } = vaunceAppFormiks();
	const formik = usePartyQuestionFormik();
	const {
		values,
		setValues,
		handleChange,
		handleSubmit,
		isSubmitting,
		handleBlur,
		isValid,
		touched,
		errors,
		setFieldValue,
	} = formik;
	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader
				title={'예약문의'}
				showStep
				stepNum={3}
			/>
			<FormikProvider value={formik}>
				<Typography variant='h3'>이용 정보</Typography>
				<Stack
					spacing={3}
					flex={1}
					overflow={'auto'}>
					<TextField
						type={'number'}
						value={values.numberOfUsers}
						name='numberOfUsers'
						onChange={handleChange}
						onBlur={handleBlur}
						fullWidth
						InputProps={{
							style: { fontSize: '16px', fontWeight: 500 },
						}}
						label={
							<Typography
								variant='h6'
								fontWeight={400}>
								이용 인원
							</Typography>
						}
						variant='standard'
						placeholder='이용 인원을 입력해주세요.'
						data-cy='numberOfUsersInPartyQuestion'
						sx={{ fontSize: '1.5em', mt: 1 }}
						error={!!(touched.numberOfUsers && errors.numberOfUsers)}
						helperText={
							<VaunceHelperText
								dataCy={'numberOfUsersHelperTextInPartyQuestion'}
								name={'numberOfUsers'}
							/>
						}
					/>
					<TextField
						label={
							<Typography
								variant='h6'
								fontWeight={400}>
								예약일
							</Typography>
						}
						InputProps={{
							style: { fontSize: '16px', fontWeight: 500 },
						}}
						name='date'
						type={'date'}
						value={values.date}
						onChange={handleChange}
						onBlur={handleBlur}
						error={!!(touched.date && errors.date)}
						fullWidth
						data-cy='dateInPartyQuestion'
						variant='standard'
						sx={{ fontSize: '1.5em', pt: 2 }}
						helperText={
							<VaunceHelperText
								dataCy={'dateHelperTextInPartyQuestion'}
								name={'date'}
							/>
						}
					/>
					<Stack>
						<Typography
							variant='h6'
							fontWeight={400}>
							희망 이용 시간
						</Typography>

						<Stack
							direction={'row'}
							spacing={1}
							alignItems={'baseline'}
							sx={{ width: '100%' }}>
							<Stack flex={1}>
								<FormControl
									variant='standard'
									fullWidth
									sx={{ fontSize: '16px' }}>
									<NativeSelect
										sx={{ fontSize: '16px', fontWeight: 500 }}
										variant='standard'
										name='hour'
										value={values.hour}
										data-cy='hourInPartyQuestion'
										onChange={(e) => {
											setFieldValue('hour', e.target.value);
										}}
										onBlur={handleBlur}>
										{HourValueArray.map((item) => (
											<option
												key={item}
												value={item}>
												{HourValueTypeLabel[item]}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Stack>
							<Stack flex={1}>
								<FormControl
									variant='standard'
									fullWidth
									sx={{ fontSize: '16px' }}>
									<NativeSelect
										sx={{ fontSize: '16px', fontWeight: 500 }}
										variant='standard'
										name='minutes'
										value={values.minutes}
										data-cy='minutesInPartyQuestion'
										onChange={(e) => {
											setFieldValue('minutes', e.target.value);
										}}
										onBlur={handleBlur}>
										{MinutesValueArray.map((item) => (
											<option
												key={item}
												value={item}>
												{MinutesTypeLabel[item]}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Stack>
							<Typography
								variant='h4'
								sx={{ justifyContent: 'center' }}>
								{'부터 ~'}
							</Typography>
							<Stack flex={1}>
								<FormControl
									variant='standard'
									fullWidth
									sx={{ fontSize: '16px' }}>
									<NativeSelect
										sx={{ fontSize: '16px', fontWeight: 500 }}
										variant='standard'
										data-cy='hourEndInPartyQuestion'
										name='hours'
										value={values.hours}
										onChange={(e) => {
											setFieldValue('hours', e.target.value);
										}}
										onBlur={handleBlur}>
										{HoursValueArray.map((item) => (
											<option
												key={item}
												value={item}>
												{HoursValueTypeLabel[item]}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Stack>
						</Stack>
					</Stack>
					<Stack spacing={2}>
						<Typography variant='h3'>{'추가문의사항'}</Typography>
						<TextField
							name='memo'
							data-cy='memoInPartyQuestion'
							value={values.memo}
							onChange={handleChange}
							variant='filled'
							multiline
							rows={5}
							placeholder={'추가 문의 사항을 입력해주세요.'}
							sx={{ fontSize: '16px' }}
							InputProps={{
								style: { fontSize: '16px', fontWeight: 500 },
							}}
						/>
					</Stack>
					<Stack direction={'row'}>
						<RoundedSubmitButton
							data-cy='partyQuestionstepThreeBtn'
							type='submit'
							disabled={!isValid}
							isSubmitting={isSubmitting}
							onClick={() => {
								handleSubmit();
								loggingEvent('예약 문의', 'submit', 'reserve');
							}}
							text={'문의접수'}
							sx={{ width: '100%', mt: 2 }}
						/>
					</Stack>
				</Stack>
			</FormikProvider>
		</Stack>
	);
};
