import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { CloseIconBox, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { vaunceAppColors } from '@kinderlabs-pos/shared-const';

export interface IDeleteDialogProps {
	open: boolean;
	closeDialog: () => void;
}
export const DeleteDialog: React.FC<IDeleteDialogProps> = ({ open, closeDialog }) => {
	const deleteAlarmReadAll = VaunceQueryState.useAlarmDeleteQuery();

	return (
		<WithDialog.XlDialog
			fullWidth
			maxWidth={'lg'}
			open={open}
			closeDialog={closeDialog}
			dialogTitle={
				<Box
					display={'flex'}
					justifyContent={'flex-end'}>
					<IconButton onClick={closeDialog}>
						<CloseIconBox sx={{ width: 18, height: 18 }} />
					</IconButton>
				</Box>
			}>
			<DialogContent>
				<Stack
					spacing={5}
					sx={{ pb: 5 }}>
					<Typography
						variant='h4'
						textAlign={'center'}>
						{'알림 내역을 삭제하시겠습니까?'}
					</Typography>
					<Stack
						direction={'row'}
						spacing={2}
						alignItems={'center'}>
						<RoundedButton
							onClick={closeDialog}
							sx={{ flex: 1, backgroundColor: '#FFFFFF', color: vaunceAppColors.lightPink }}>
							{'취소'}
						</RoundedButton>
						<RoundedButton
							onClick={() => {
								deleteAlarmReadAll.mutate();
								closeDialog();
							}}
							sx={{ flex: 1 }}>
							{'삭제'}
						</RoundedButton>
					</Stack>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
