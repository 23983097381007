import { Button, ButtonProps, styled } from '@mui/material';
import React from 'react';

export interface IVaunceButtonProps extends ButtonProps {}
const VaunceButton: React.FC<IVaunceButtonProps> = ({ ...others }) => {
	return <StyleButton {...others} />;
};
export default VaunceButton;

const StyleButton = styled(Button)(({ theme }) => ({
	border: `1px solid ${theme.palette.primary.main}`,
	borderRadius: '6px',
	color: `${theme.palette.primary.main}`,
	padding: '6px 0',
}));
