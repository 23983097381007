import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, Checkbox, Stack, Typography } from '@mui/material';
import alarmDelete from 'assets/vaunce/image/allimdelete.png';
import alarmRefresh from 'assets/vaunce/image/allimrefresh.png';

import alarmDelete2x from 'assets/vaunce/image/allimdelete@2x.png';
import alarmRefresh2x from 'assets/vaunce/image/allimrefresh@2x.png';

import { useEffect, useState } from 'react';
import { DeleteDialog } from './DeleteDialog';

export interface IAlarmTabPageProps {}
export const AlarmTabPage: React.FC<IAlarmTabPageProps> = ({}) => {
	const alarmList = VaunceQueryState.useAlarmQuery();
	const updateAlarmReadAll = VaunceQueryState.useAlarmReadAllQuery();

	const [isReadAlarm, setIsReadAlarm] = useState<boolean[]>(
		alarmList.map((item) => (item.readArlim === 'Y' ? true : false))
	);

	useEffect(() => {
		setIsReadAlarm(alarmList.map((item) => (item.readArlim === 'Y' ? true : false)));
	}, [alarmList]);

	return (
		<Stack
			spacing={3}
			sx={{ mt: 1 }}>
			<Stack
				direction={'row'}
				justifyContent={'center'}
				spacing={2}
				sx={{ px: 1 }}>
				<Button
					disabled={isReadAlarm.length === 0 ? true : false}
					onClick={() => {
						updateAlarmReadAll.mutate();
					}}
					variant='text'
					sx={{
						borderRadius: 3,
						boxShadow: `0px 0px 12px ${vaunceColors.lightGray}`,
						flex: 1,
						py: 1.5,
					}}>
					<Stack
						direction={'row'}
						spacing={1}
						alignItems={'center'}>
						<Typography
							variant='h6'
							fontWeight={500}>
							{'모두 읽음으로 처리'}
						</Typography>
						<img
							src={alarmRefresh}
							srcSet={alarmRefresh2x}
							width={'18px'}
							height={'18px'}
						/>
					</Stack>
				</Button>
				<WithDialog
					dialog={(open, closeDialog) => (
						<DeleteDialog
							open={open}
							closeDialog={closeDialog}
						/>
					)}>
					{(openDialog) => (
						<Button
							variant='text'
							sx={{
								borderRadius: 3,
								boxShadow: `0px 0px 12px ${vaunceColors.lightGray}`,
								flex: 1,
								py: 1.5,
							}}
							disabled={isReadAlarm.length === 0 ? true : false}
							onClick={() => {
								openDialog();
							}}>
							<Stack
								direction={'row'}
								spacing={1}>
								<Typography
									variant='h6'
									fontWeight={500}>
									{'알림 비우기'}
								</Typography>
								<img
									src={alarmDelete}
									srcSet={alarmDelete2x}
									width={'18px'}
									height={'18px'}
								/>
							</Stack>
						</Button>
					)}
				</WithDialog>
			</Stack>
			<Stack
				flex={1}
				overflow={'inherit'}>
				{alarmList.map((item, idx) => (
					<Stack
						direction={'row'}
						key={idx}
						sx={{
							padding: 2,
							borderBottom: `1px solid ${vaunceColors.lightGray}`,
							backgroundColor: isReadAlarm[idx] ? 'rgba(0, 0, 0, 0.05)' : '',
						}}
						spacing={1}>
						<Stack>
							<Checkbox
								sx={{ pt: 0, pl: 0 }}
								checked={isReadAlarm[idx]}
								onChange={async () => {
									const newArray = [...isReadAlarm];
									newArray[idx] = !newArray[idx];
									setIsReadAlarm(newArray);
									await VaunceAppApis.getOneAlarmRead(item.id);
								}}
							/>
						</Stack>
						<Stack>
							<Typography variant='h4'>{item.contents}</Typography>
							<Typography
								variant='h6'
								fontWeight={400}>
								{item.create_date}
							</Typography>
						</Stack>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};
