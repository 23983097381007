import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Stack, TextField, Typography } from '@mui/material';
export interface INewRegisterApplicantInfoProps {}
export const NewRegisterApplicantInfo: React.FC<INewRegisterApplicantInfoProps> = ({}) => {
	const myUsage = VaunceQueryState.useUserUsageQuery();
	return (
		<Stack spacing={2}>
			<Typography variant='h2'>신청자(보호자)</Typography>
			<Stack
				direction={'row'}
				spacing={2}>
				<TextField
					data-cy='registerApplicant'
					label={
						<Typography
							variant='h6'
							fontWeight={400}>
							이름
						</Typography>
					}
					defaultValue={myUsage.applicant}
					variant='filled'
					sx={{ flex: 1, fontSize: '1.5em', pt: 1 }}
					InputProps={{
						readOnly: true,
					}}
				/>
				<TextField
					data-cy='registerApplicantPhone'
					label={
						<Typography
							variant='h6'
							fontWeight={400}>
							연락처
						</Typography>
					}
					defaultValue={myUsage.applicantPhone}
					variant='filled'
					sx={{ flex: 1, fontSize: '1.5em', pt: 1 }}
					InputProps={{
						readOnly: true,
					}}
				/>
			</Stack>
		</Stack>
	);
};
