import { Box, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { useVaunceAppNavigationWithAuthGuard } from '../utils/useVaunceAppNavigationWithAuthGuard';

import homeImage from 'assets/vaunce/image/icon_home.png';
import qrImage from 'assets/vaunce/image/icon_member.png';
import otherImage from 'assets/vaunce/image/icon_office.png';
import userImage from 'assets/vaunce/image/icon_user.png';
import wishImage from 'assets/vaunce/image/icon_wish.png';

import homeImage2X from 'assets/vaunce/image/icon_home@2x.png';
import qrImage2X from 'assets/vaunce/image/icon_member@2x.png';
import otherImage2X from 'assets/vaunce/image/icon_office@2x.png';
import userImage2X from 'assets/vaunce/image/icon_user@2x.png';
import wishImage2X from 'assets/vaunce/image/icon_wish@2x.png';
import { MenuType } from './SidebarDrawer/DrawerMenuItem';

const footerHeight = 58;
interface HomeFooterProps {
	toggleDrawer: () => void;
}
export const HomeFooter: React.FC<HomeFooterProps> = ({ toggleDrawer }) => {
	const { navigateWithLoginGuard, isLoginned } = useVaunceAppNavigationWithAuthGuard();
	const mainMenuList = getMainMenuList(() => toggleDrawer());

	return (
		<>
			<Stack
				position={'absolute'}
				width={'100%'}
				bottom={'0'}
				sx={(theme) => ({
					backgroundColor: 'rgba(0, 0, 0, 0.36)',
					filter: 'brightness(1.8)',
					boxShadow: '0 -6px 20px rgba(0, 0, 0, 0.13)',
					paddingTop: 1,
					paddingBottom: [
						`calc(env(safe-area-inset-bottom) + ${theme.spacing(1)})`,
						`calc(constant(safe-area-inset-bottom) + ${theme.spacing(1)})`,
					],
					height: [
						`calc(env(safe-area-inset-bottom) + ${footerHeight}px)`,
						`calc(constant(safe-area-inset-bottom) + ${footerHeight}px)`,
					],
				})}>
				<Stack
					height={'100%'}
					mx={'20px'}
					direction='row'
					spacing={3}
					justifyContent={'center'}
					alignItems={'center'}>
					{mainMenuList.map((menuItem, idx) => {
						if (menuItem.type === 'navigate') {
							return (
								<FooterBox
									key={idx}
									onClick={() => navigateWithLoginGuard(menuItem)}>
									<img
										src={menuItem.iconUrl}
										srcSet={menuItem.srcsetUrl}
										width={24}
										height={24}
									/>
									<Typography
										variant='body1'
										fontWeight={400}
										color={'white'}>
										{menuItem.label}
									</Typography>
								</FooterBox>
							);
						} else {
							return (
								<FooterBox onClick={menuItem.onClick}>
									<React.Fragment key={'right'}>
										<img
											src={menuItem.iconUrl}
											srcSet={menuItem.srcsetUrl}
											width={24}
											height={24}
										/>
										<Typography
											variant='body1'
											fontWeight={400}
											color={'white'}>
											{menuItem.label}
										</Typography>
									</React.Fragment>
								</FooterBox>
							);
						}
					})}
				</Stack>
			</Stack>
			{/* JIN: 위 Absolute 를 맞추기 위해 Dummy 추가. Mui 도 Appbar 를 사용할 때 이런 테크닉을 활용함. 나도 Admin 에 이미 활용했음 */}
			<Box
				className={'dummy'}
				sx={{
					height: [
						`calc(env(safe-area-inset-bottom) + ${footerHeight}px)`,
						`calc(constant(safe-area-inset-bottom) + ${footerHeight}px)`,
					],
				}}
				height={footerHeight}
			/>
		</>
	);
};

const getMainMenuList: (
	onClick더보기: () => void
) => (
	| ({ type: 'navigate' } & MenuType)
	| { type: 'click'; label: string; iconUrl: string; srcsetUrl: string; onClick: () => void }
)[] = (onClick더보기) => [
	{
		label: '홈',
		type: 'navigate',
		url: '/',
		iconUrl: homeImage,
		srcsetUrl: homeImage2X,
		needLoginAssertion: false,
	},
	{
		label: '멤버십안내',
		type: 'navigate',
		url: '/membershipinfo',
		iconUrl: qrImage,
		srcsetUrl: qrImage2X,
		needLoginAssertion: false,
	},
	{
		label: 'MY페이지',
		type: 'navigate',
		url: '/my',
		iconUrl: userImage,
		srcsetUrl: userImage2X,
		needLoginAssertion: true,
	},
	{
		label: '찜',
		type: 'navigate',
		url: '/wishlist',
		iconUrl: wishImage,
		srcsetUrl: wishImage2X,
		needLoginAssertion: true,
	},
	{
		label: '더보기',
		type: 'click',
		iconUrl: otherImage,
		srcsetUrl: otherImage2X,
		onClick: onClick더보기,
	},
];

const FooterBox = styled(Box)(({ theme }) => ({
	width: '25%',
	textAlign: 'center',
	cursor: 'pointer',
}));
