import { axiosAppClient } from '@kinderlabs-pos/apis/axios';
import { CheckOneCenterFavorite, ToggleWishListType, WishListType } from '@kinderlabs-pos/shared-data-type';

export const getWishListAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<WishListType>(`${rootUrl}/favorite-center/get_favorite_center_list`)
	).data;

interface ToggleWishListProps {
	centerId: number;
	toggle: boolean;
}

export const toggleWishListAsync =
	(rootUrl: string) =>
	async ({ centerId, toggle }: ToggleWishListProps) =>
		(
			await axiosAppClient.get<ToggleWishListType>(
				`${rootUrl}/favorite-center/save_favorite?centerId=${centerId}&favorite=${toggle}`
			)
		).data;

export const getCheckWishListAsync = (rootUrl: string) => async (centerId: number) =>
	(
		await axiosAppClient.get<CheckOneCenterFavorite>(`${rootUrl}/favorite-center/get_favorite_centerid`, {
			params: {
				centerId: centerId,
			},
		})
	).data;
