import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PageHeader } from '../../components/PageHeader';

import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { VaunceState } from '@kinderlabs-pos/state';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import ruleImage1 from 'assets/vaunce/image/rules01.png';
import ruleImage2 from 'assets/vaunce/image/rules02.png';
import ruleImage3 from 'assets/vaunce/image/rules03.png';
import ruleImage4 from 'assets/vaunce/image/rules04.png';
import ruleImage5 from 'assets/vaunce/image/rules05.png';
import ruleImage6 from 'assets/vaunce/image/rules06.png';
import ruleImage7 from 'assets/vaunce/image/rules07.png';
import ruleImage8 from 'assets/vaunce/image/rules08.png';
import ruleImage9 from 'assets/vaunce/image/rules09.png';

import ruleImage1x from 'assets/vaunce/image/rules01@2x.png';
import ruleImage2x from 'assets/vaunce/image/rules02@2x.png';
import ruleImage3x from 'assets/vaunce/image/rules03@2x.png';
import ruleImage4x from 'assets/vaunce/image/rules04@2x.png';
import ruleImage5x from 'assets/vaunce/image/rules05@2x.png';
import ruleImage6x from 'assets/vaunce/image/rules06@2x.png';
import ruleImage7x from 'assets/vaunce/image/rules07@2x.png';
import ruleImage8x from 'assets/vaunce/image/rules08@2x.png';
import ruleImage9x from 'assets/vaunce/image/rules09@2x.png';

import { useAtomValue } from 'jotai';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { useVaunceAppRouter } from '../routes';

export interface ISaftyRulesPageProps {}
export const NewRegisterStepThreePage: React.FC<ISaftyRulesPageProps> = ({}) => {
	const { navigateNewRegisterComplete } = useVaunceAppRouter();
	const usageId = useAtomValue(VaunceState.usageId);
	const selectRecentUsageId = useAtomValue(VaunceState.selectRecentUsageId);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const saftyList = [
		{
			title: '미끄럼 방지용 GRIP SOCKS를 착용해 주세요.',
			imgUrl: ruleImage1,
			srcsetUrl: ruleImage1x,
		},
		{ title: '본인 역량에 맞는 동작을 시도하세요.', imgUrl: ruleImage2, srcsetUrl: ruleImage2x },
		{
			title: '하나의 매트 위에는 한 사람씩 이용하세요.',
			imgUrl: ruleImage3,
			srcsetUrl: ruleImage3x,
		},
		{
			title: '트램폴린을 가로질러 점핑, 달리는 행동을 금지합니다.',
			imgUrl: ruleImage4,
			srcsetUrl: ruleImage4x,
		},
		{
			title: '발, 등, 엉덩이를 이용하여 매트 위에 착지하세요.',
			imgUrl: ruleImage5,
			srcsetUrl: ruleImage5x,
		},
		{
			title: '패드 위에서는 점프하거나 착지하지 마세요.',
			imgUrl: ruleImage6,
			srcsetUrl: ruleImage6x,
		},
		{
			title: '음식 및 음료를 가지고 입장하실 수 없습니다.',
			imgUrl: ruleImage7,
			srcsetUrl: ruleImage7x,
		},
		{
			title: '주머니에 있는 물건들은 락커에 보관해 주세요.',
			imgUrl: ruleImage8,
			srcsetUrl: ruleImage8x,
		},
		{
			title: '사진은 트램폴린 시설 밖에서만 촬영하실 수 있습니다.',
			imgUrl: ruleImage9,
			srcsetUrl: ruleImage9x,
		},
	];

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='안전 수칙 동의' />
			<Grid container>
				{saftyList.map((rule) => (
					<Grid
						item
						xs={6}
						key={rule.title}>
						<Stack
							spacing={3}
							padding={2}>
							<Box>
								<img
									src={rule.imgUrl}
									srcSet={rule.srcsetUrl}
									width={106}
									height={106}
								/>
							</Box>
							<Typography variant='h4'>{rule.title}</Typography>
						</Stack>
					</Grid>
				))}
			</Grid>

			<Box>
				<RoundedSubmitButton
					data-cy='agreeBtnInRegisterStepThree'
					sx={{ width: '100%' }}
					onClick={async () => {
						setIsSubmitting(true);
						if (selectRecentUsageId) {
							const res = await VaunceAppApis.recestEnrollmentUsage(selectRecentUsageId);
							if (res) {
								setIsSubmitting(false);
								navigateNewRegisterComplete();
							}
							loggingEvent(
								'시설 이용(이전 등록 이력) STEP 3 > 다음',
								'submit',
								'시설 이용(이전 등록 이력) STEP 3 > 다음'
							);
						} else {
							const res = await VaunceAppApis.updateEnrollment(usageId);
							if (res) {
								setIsSubmitting(false);
								navigateNewRegisterComplete();
							}
							loggingEvent(
								'시설 이용 등록 STEP 3 > 다음',
								'submit',
								'시설 이용 등록 STEP 3 > 다음'
							);
						}
					}}
					isSubmitting={isSubmitting}
					text={'동의하고 다음'}>
					{'동의하고 다음'}
				</RoundedSubmitButton>
			</Box>
		</Stack>
	);
};
