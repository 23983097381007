import { axiosAppClient, axiosAppPostClient } from '@kinderlabs-pos/apis/axios';
import { CouponListType, EventListBodyType } from '@kinderlabs-pos/shared-data-type';

export const getCouponListAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<CouponListType>(`${rootUrl}/users/coupons`)
	).data;

//TODO response 형식이 body, header가 아님..
export const getEventListAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppPostClient.get<EventListBodyType[]>(`${rootUrl}/event/getEventList`)
	).data;

export const getEventOne = (rootUrl: string) => async (id: string) =>
	await (
		await axiosAppPostClient.get<EventListBodyType>(`${rootUrl}/event/getOne?id=${id}`)
	).data;

// TODO res로 string 만 던져서 준다 참나
export const getRegisterCouponAsync = (rootUrl: string) => async (couponText: string) =>
	await (
		await axiosAppPostClient.get<string>(`${rootUrl}/coupons/reg_coupon?couponText=${couponText}`)
	).data;
