export const QuestionFirstTypeArray = ['normal', 'party'] as const;
export type QuestionFirstType = typeof QuestionFirstTypeArray[number];
export const QuestionFirstTypeLabel: Record<QuestionFirstType, string> = {
	normal: '일반',
	party: '단체',
};
// export type QuestionFirstType = "normal" | "party"

export const NormalQuestionTypeArray = [
	'normal',
	'compliment',
	'proposal',
	'discontent',
	'membership',
	'facility',
	'cancel',
] as const;
export type NormalQuestionType = typeof NormalQuestionTypeArray[number];

export const NormalQuestionTypeLabel: Record<NormalQuestionType, string> = {
	normal: '일반',
	compliment: '칭찬',
	proposal: '제안',
	discontent: '불만',
	membership: '멤버쉽',
	facility: '시설',
	cancel: '취소 및 환불',
};

export const PartyQuestionTypeArray = ['party', 'group', 'workshop'] as const;

export type PartyQuestionType = typeof PartyQuestionTypeArray[number];

export const PartyTypeLabel: Record<PartyQuestionType, string> = {
	party: '파티',
	group: '그룹',
	workshop: '워크샵',
};

export const HourValueArray = [
	'9:00',
	'10:00',
	'11:00',
	'12:00',
	'13:00',
	'14:00',
	'15:00',
	'16:00',
	'17:00',
	'18:00',
	'19:00',
	'20:00',
	'21:00',
] as const;

export type HourValueType = typeof HourValueArray[number];

export const HourValueTypeLabel: Record<HourValueType, string> = {
	'9:00': '09시',
	'10:00': '10시',
	'11:00': '11시',
	'12:00': '12시',
	'13:00': '13시',
	'14:00': '14시',
	'15:00': '15시',
	'16:00': '16시',
	'17:00': '17시',
	'18:00': '18시',
	'19:00': '19시',
	'20:00': '20시',
	'21:00': '21시',
};

export const MinutesValueArray = [
	'0minute',
	'5minutes',
	'10minutes',
	'15minutes',
	'20minutes',
	'25minutes',
	'30minutes',
	'35minutes',
	'40minutes',
	'45minutes',
	'50minutes',
	'55minutes',
] as const;

export type MinutesValueType = typeof MinutesValueArray[number];

export const MinutesTypeLabel: Record<MinutesValueType, string> = {
	'0minute': '00분',
	'5minutes': '5분',
	'10minutes': '10분',
	'15minutes': '15분',
	'20minutes': '20분',
	'25minutes': '25분',
	'30minutes': '30분',
	'35minutes': '35분',
	'40minutes': '40분',
	'45minutes': '45분',
	'50minutes': '50분',
	'55minutes': '55분',
};

export const HoursValueArray = [
	'1hour',
	'2hours',
	'3hours',
	'4hours',
	'5hours',
	'6hours',
	'7hours',
	'8hours',
	'9hours',
	'10hours',
	'11hours',
	'12hours',
	'13hours',
] as const;

export type HoursValueType = typeof HoursValueArray[number];

export const HoursValueTypeLabel: Record<HoursValueType, string> = {
	'1hour': '1시간',
	'2hours': '2시간',
	'3hours': '3시간',
	'4hours': '4시간',
	'5hours': '5시간',
	'6hours': '6시간',
	'7hours': '7시간',
	'8hours': '8시간',
	'9hours': '9시간',
	'10hours': '10시간',
	'11hours': '11시간',
	'12hours': '12시간',
	'13hours': '13시간',
};

export type QuestionInfoResponseType = {
	id: number;
	userId: string;
	replyId?: string;
	questionType: QuestionFirstType;
	title: string;
	memo: string;
	noticeNum: string;
	replyContents: string;
	replyDate?: Date;
	readData?: Date;
	center: string;
	goods: typeof NormalQuestionTypeArray[number] | typeof PartyQuestionTypeArray[number];
	memberinfoName: string;
	memberinfoPhone: string;
	numberOfUsers?: string;
	usedate?: string;
	usehour?: string;
	useminute?: string;
	usehours?: HoursValueType;
	status: string;
	create_date: string;
	insert_date: number;
};

export type PartyQuestionRequestType = {
	centerSelect?: number;
	goods?: PartyQuestionType;
	isSameWithApplicant?: boolean;
	memberinfoName: string;
	memberinfoPhone: string;
	numberOfUsers: string;
	date: string;
	hour?: HourValueType;
	minutes?: MinutesValueType;
	hours?: string;
	memo: string;
};
