import { axiosAppClient } from '@kinderlabs-pos/apis/axios';
import {
	NormalQuestionType,
	PartyQuestionRequestType,
	QuestionFirstType,
} from '@kinderlabs-pos/shared-data-type';

export const updateNormalQuestAsync =
	(rootUrl: string) =>
	async ({ questionType, category, title, memo, noticeNum }: SendNormalQuestionProps) => {
		if (category === undefined) return undefined;
		else {
			return await axiosAppClient.get<SendQuestResponseType>(`${rootUrl}/question/send_quest`, {
				params: {
					goods: category,
					title: title,
					memo: memo,
					questionType: questionType.toUpperCase(),
					noticeNum: noticeNum,
				},
			});
		}
	};

interface SendNormalQuestionProps {
	category: NormalQuestionType | undefined;
	title: string;
	memo: string;
	questionType: QuestionFirstType;
	noticeNum: number;
}

type SendQuestResponseType = {
	question: string;
};

export const updatePartyQuestionAsync =
	(rootUrl: string) => async (props: SendPartyQeustionProps) => {
		return await axiosAppClient.get<SendQuestResponseType>(
			`${rootUrl}/question/send_group_reservation`,
			{
				params: { ...props },
			}
		);
	};

type SendPartyQeustionProps = Omit<PartyQuestionRequestType, 'isSameWithApplicant'>;
