import { axiosAppClient } from '@kinderlabs-pos/apis/axios';
import {
	FacilityResponseType,
	FacilityUsageType,
	SearchedByUsageId,
} from '@kinderlabs-pos/shared-data-type';

export const updateFacilityReqeustAsync = (rootUrl: string) => async (data: FormData) =>
	(await axiosAppClient.post<FacilityResponseType>(`${rootUrl}/usage`, data)).data;

export const enrollmentFacilityAsync = (rootUrl: string) => async (id: number) =>
	await axiosAppClient.post(`${rootUrl}/usage/${id}/enrollment`);

export const getInfoByUsageId = (rootUrl: string) => async (id: number) =>
	(await axiosAppClient.get<SearchedByUsageId>(`${rootUrl}/usage/info/${id}`)).data;

export const getUserRecentOneAsync = (rootUrl: string) => async () =>
	(await axiosAppClient.get<SearchedByUsageId>(`${rootUrl}/usage/users-recently-one`)).data;

export const getUsageFacilityAsync = (rootUrl: string) => async () =>
	(await axiosAppClient.get<FacilityUsageType>(`${rootUrl}/usage`)).data;

export const deleteFacilityByUsageId = (rootUrl: string) => async (usageId: number) =>
	await axiosAppClient.get<any>(`${rootUrl}/usage/delete/${usageId}`);

export const recestEnrollmentUsage = (rootUrl: string) => async (usageId: number) =>
	await axiosAppClient.post<any>(`${rootUrl}/usage/${usageId}/enrollment-loading
`);
