import { Box, Stack, styled, Typography } from '@mui/material';
import { ConsentType } from '../TermsOfUsePage/termsOfUseData';

const BoldSpan = styled('span')(({ theme }) => ({
	fontWeight: 'bold',
}));

export const PrivacyTermData: ConsentType = [
	{
		title: '1. 개인정보의 수집 및 이용 목적',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<Stack spacing={2}>
								<Box>
									① 당사는 회원에게 다양한 서비스를 제공하기 위해 아래와 같은 개인정보를 수집하고
									있습니다. 단, 회원의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족,
									사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)와
									주민등록번호는 법령상 근거 없이 불필요하게 수집하지 않습니다.
									<br />② 개인정보 수집 및 이용 목적
								</Box>
								<Box>1. 개인정보 수집 항목 및 목적</Box>
								<Box>
									<BoldSpan>수집/이용 항목</BoldSpan>
									<br />
									<BoldSpan>필수</BoldSpan> : 아이디, 비밀번호, 성명, 성별, 생년월일, 휴대전화번호,
									이메일, 주소, CI(본인인증 회원), 법정대리인정보(성명, 관계, 연락처)
									<br />
									<BoldSpan>선택</BoldSpan> : 자녀정보(이름, 생년월일, 성별, 관계), 자주 이용하는
									센터, 추천인 ID
									<br />
									<BoldSpan>수집/이용 목적</BoldSpan>
									<br />
									이용자 식별, 회원 서비스 제공, 본인인증, 만 14세 미만 회원가입 시 확인, 멤버십
									혜택 및 각종 이벤트/행사 정보 안내, 상품수령
								</Box>
								<Box>
									③ 서비스 이용 또는 사업처리 과정에서 생성 수집되는 각종정보 서비스이용기록,
									접속로그, 쿠키, 결제기록, 이용정지기록, 유료멤버십/멀티패스/정기회원권 이용
									정보(상품 및 결제정보) 등
								</Box>
							</Stack>
						),
					},
				],
			},
		],
	},
	{
		title: '2. 개인정보 수집 방법 및 수집에 대한 동의',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								① 개인정보 수집 방법
								<br />
								당사는 홈페이지, 고객센터, 전화, 팩스, 이벤트 응모, 스마트폰용 어플리케이션 등,
								‘쿠키(cookie)’에 의한 방법으로 개인정보를 수집합니다.
								<br />
								②개인정보 수집에 대한 동의 절차
								<br />
								회원께서 개인정보처리방침과 서비스이용약관 등에 대해 『동의합니다.』 버튼을 클릭하면
								개인정보 수집에 대해 동의한 것으로 봅니다.
								<br />③ 동의거부 권리와 불이익
								<br />
								회원은 서비스 제공 및 이용의 목적으로 개인정보를 제공하는 것에 동의하지 않을 수
								있습니다.
							</>
						),
					},
				],
			},
		],
	},
	{
		title: '3. 개인정보의 보유기간 및 이용기간',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								① 당사는 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 회원의 개인정보를
								지체 없이 파기합니다. 다만, 다음 각 호의 경우 일정기간 동안 예외적으로 수집한
								회원정보의 전부 또는 일부를 보관할 수 있습니다.
								<br />
								1. 회원 가입정보
								<br />- 수집한 회원 정보를 회원 탈퇴 후 30일간 보유
								<br />
								(재가입 시 이전 아이디의 사용 및 회원님의 활동 데이터를 복구 및 무분별한 회원탈퇴 및
								재가입으로 인한 부정방지 목적)
								<br />
								탈퇴한 회원이 자신의 게재물에 대한 삭제를 요청 시 즉각 삭제 처리합니다.
								<br />
								<br />② 상법 및 ‘전자상거래 등에서 소비자보호에 관한 법률’ 등 관련 법령의 규정에
								의하여 다음과 같이 일정기간 보유해야 할 필요가 있을 경우에는 관련 법령이 정한 기간
								또는 다음 각 호의 기간 동안 회원정보를 보유할 수 있습니다.
								<br />
								1. 계약 또는 청약철회 등에 관한 기록 (전자상거래법 : 5년)
								<br />
								2. 대금결제 및 재화 등의 공급에 관한 기록 (전자상거래법 : 5년)
								<br />
								3. 소비자의 불만 또는 분쟁처리에 관한 기록 (전자상거래법 : 3년)
								<br />
								4. 표시/광고에 관한 기록 (전자상거래법 : 6개월)
								<br />
								<br />③ 개인정보 파기 방법
								<br />
								1. 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각
								<br />
								2. 전자적 파일형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여
								삭제
								<br />
								<br />④ 장기 미이용 회원 개인정보 분리보관
								<br />
								1. 당사는 다른 법령에서 별도의 기간을 정하고 있거나 고객의 요청이 있는 경우를
								제외하면, 당사의 서비스를 1년 이상 재이용하지 아니하는 회원의 개인정보를 다른 회원의
								개인정보와 분리하여 별도로 저장 및 관리합니다.
								<br />
								2. 당사는 휴면 전환 30일 전까지 휴면 예정 회원에게 별도 분리 보관되는 사실 및 휴면
								예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 서면, 모사전송, 전화 또는 이와
								유사한 방법 중 어느 하나의 방법으로 회원에게 알립니다.
								<br />
								3. 장기 미이용 하여 별도 분리 보관된 고객이 추후 온·오프라인을 통한 상품 구매 등
								기타 서비스를 이용할 경우 분리 보관된 계정은 복구됩니다.
							</>
						),
					},
				],
			},
		],
	},
	{
		title: '4. 개인정보의 제3자 제공',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								① 당사는 회원의 개인정보를 회원의 동의없이 제3자에게 제공하지 않으며, 제3자에게
								제공하는 경우, 홈페이지 또는 앱을 통해 고지 및 별도 동의 후 제공합니다.
							</>
						),
					},
				],
			},
		],
	},
	{
		title: '5. 개인정보처리의 위탁',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<Stack spacing={2}>
								<Box>
									① 당사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
									위탁하고 있습니다.
								</Box>
								<Box>
									<BoldSpan>위탁대상자(수탁자)</BoldSpan>
									<br />
									<br />
									<BoldSpan>나이스페이먼츠</BoldSpan>
									<br />
									<BoldSpan>위탁내용 및 목적:</BoldSpan> 신용카드 결제서비스 위탁
									<br />
									<BoldSpan>보유 및 이용기간:</BoldSpan> 회원 탈퇴 및 계약 만료 시까지
									<br />
									<br />
									<BoldSpan>㈜다날</BoldSpan>
									<br />
									<BoldSpan>위탁내용 및 목적:</BoldSpan> 본인인증 확인
									<br />
									<BoldSpan>보유 및 이용기간:</BoldSpan> 회원 탈퇴 및 계약 만료 시까지
									<br />
									<br />
									<BoldSpan>비지에프네트웍스</BoldSpan>
									<br />
									<BoldSpan>위탁내용 및 목적:</BoldSpan> 문자 발송업무 대행
									<br />
									<BoldSpan>보유 및 이용기간:</BoldSpan> 회원 탈퇴 및 계약 만료 시까지
									<br />
									<br />
									<BoldSpan>넥스트스토리</BoldSpan>
									<br />
									<BoldSpan>위탁내용 및 목적:</BoldSpan> 결제, 포인트 적립 및 사용, 쿠폰 사용
									<br />
									<BoldSpan>보유 및 이용기간:</BoldSpan> 회원 탈퇴 및 계약 만료 시까지
									<br />
									<br />
									<BoldSpan>킨더랩스</BoldSpan>
									<br />
									<BoldSpan>위탁내용 및 목적:</BoldSpan> APP/WEB 개발 및 운영, 결제, 포인트 적립 및
									사용, 쿠폰 사용
									<br />
									<BoldSpan>보유 및 이용기간:</BoldSpan> 회원 탈퇴 및 계약 만료 시까지
								</Box>
							</Stack>
						),
					},
				],
			},
		],
	},
	{
		title: '6. 개인정보의 자동 수집 장치에 관한 사항',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								① 당사는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다.
								쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주
								작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 쿠키는 이용자의
								컴퓨터를 식별하지만, 이용자를 개인적으로 식별하지는 않습니다.
								<br />
								<br />② 당사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
								<br />
								1. 쿠키 등 사용 목적 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
								관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟
								마케팅 및 개인 맞춤 서비스 제공
								<br />
								2. 쿠키 설정 거부 방법 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
								이용자는 웹 브라우저에서 옵션을 설정함으로써 쿠키의 전부 허용, 일부 허용, 전부 거부
								등의 선택이 가능합니다.
								<br />
								<br />※ 설정방법(인터넷 익스플로러의 경우)
								<br />
								인터넷 화면 작업표시줄 [도구]를 클릭한 후 [인터넷 옵션]을 선택하고, [개인정보 탭]을
								선택한 후, [설정]에서 쿠키허용 여부를 설정합니다. 단, 이용자가 쿠키 설치를
								거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
							</>
						),
					},
				],
			},
		],
	},
	{
		title: '7. 개인정보 주체의 권리와 의무',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								① 개인정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할
								수 있습니다.
								<br />
								1. 개인정보 열람요구(정보주체 및 정보주체 이외로 부터 수집된 개인정보의 출처,
								처리목적, 이용내역에 대한 열람)
								<br />
								2. 오류 등이 있을 때 정정요구
								<br />
								3. 개인정보 삭제요구
								<br />
								4. 처리정지 요구
								<br />
								상기 항목에 대한 권리행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을
								통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
								<br />
								<br />② 정보주체는 개인정보보호법 등 관계법령을 위반하는 수준으로, 회사가 처리하고
								있는 정보주체 본인 및 타인의 개인정보 및 사생활을 침해하지 않도록 해야 할 의무가
								있습니다.
							</>
						),
					},
				],
			},
		],
	},
	{
		title: '8. 개인정보의 열람 및 정정, 삭제 방법',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								회원은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 정정하실 수 있습니다.
								회원의 개인정보에 대한 열람 또는 정정을 하고자 할 경우에는 모바일 앱 내 정보관리를
								클릭하여 본인 확인 절차를 거치신 후 직접 열람 또는 정정하거나, 위 개인정보 관련 민원
								처리 부서로 연락하시면 지체 없이 조치하겠습니다.
								<br />
								고객님은 언제든지 '회원탈퇴'등을 통해 개인정보의 수집 및 이용 동의를 철회할 수
								있습니다.
								<br />만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정할
								권리, 수집 및 이용 동의를 철회할 권리를 가집니다.
								<br />
								개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를
								이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는
								정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
							</>
						),
					},
				],
			},
		],
	},
	{
		title: '9. 만14세 미만 아동의 개인정보 보호',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								만 14세 미만 아동의 경우, 법정대리인을 통하여 이용이 가능하며, 해당 서비스 수행에
								필요한 최소한의 개인정보를 수집합니다. 만14세 미만 아동의 법정대리인은 아동의
								개인정보 열람, 정정을 요구하거나, 개인정보의 수집과 이용, 제공, 위탁에 대한 동의
								철회를 할 수 있습니다. 만 14세 미만 아동의 개인정보는 법정대리인의 동의 없이
								제3자에게 제공하거나 공유되지 않습니다.
							</>
						),
					},
				],
			},
		],
	},
	{
		title: '10. 개인정보보호를 위한 안전성 확보조치',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								① 당사는 이용자의 개인정보를 처리함에 있어 개인정보의 분실, 도난, 누출, 외부로부터의
								공격, 위·변조, 해킹 등을 방지하고 최상의 안전성을 확보하기 위하여 다음과 같은
								기술적•관리적•물리적 대책을 강구하고 있습니다.
								<br />
								1. 개인정보 취급 직원의 최소화 및 교육
								<br />- 개인정보를 취급하는 직원을 지정하고 있으며, 개인정보 처리 업무를 고려한
								담당자를 최소화 하며, 지속적인 개인정보보호 교육을 시행하고 있습니다.
								<br />
								2. 내부관리계획의 수립 및 시행
								<br />- 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
								<br />
								3. 개인정보의 암호화
								<br />- 이용자의 개인정보는 법령에서 정하는 기준에 따라 암호화되어 저장∙관리되고
								있으며, 또한 파일 및 전송 데이터를 암호화 등 적용하고 있습니다.
								<br />
								4. 해킹 등에 대비한 기술적 대책
								<br />- 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
								위하여 보안프로그램을 설치하고 주기적인 갱신∙점검을 하며 외부로부터 접근이 통제된
								구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
								<br />
								5. 개인정보에 대한 접근 제한
								<br />- 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를
								통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을
								이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
								<br />
								6. 접속기록의 보관 및 위∙변조 방지
								<br />- 개인정보처리시스템에 접속한 기록을 보관 및 관리하고 있으며, 접속 기록이
								위조, 변조, 도난 및 분실되지 않도록 관리하고 있습니다.
								<br />
								7. 문서보안을 위한 잠금장치 사용
								<br />- 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에
								보관하고 있습니다.
								<br />
								8. 비인가자에 대한 출입 통제
								<br />- 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제
								절차를 수립, 운영하고 있습니다.
							</>
						),
					},
				],
			},
		],
	},
	{
		title: '11. 개인정보 보호책임자 및 담당자 안내',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<Stack spacing={2}>
								<Box>
									당사는 고객의 개인정보를 안전하게 보호하고 개인정보와 관련된 불만 및 민원사항을
									처리하기 위하여 개인정보 보호책임자 및 민원처리 담당부서를 지정하고 있습니다.
									개인정보와 관련된 문의사항이 있으시면 연락주시기 바랍니다. 기준에 따라 성실하고
									신속하게 답변드리도록 하겠습니다. 당사는 고객의 개인정보를 안전하게 보호하고
									개인정보와 관련된 불만 및 민원사항을 처리하기 위하여 개인정보 보호책임자 및
									민원처리 담당부서를 지정하고 있습니다. 개인정보와 관련된 문의사항이 있으시면
									연락주시기 바랍니다. 기준에 따라 성실하고 신속하게 답변드리도록 하겠습니다.
								</Box>
								<Box>
									<BoldSpan>개인정보보호 책임자</BoldSpan>
									<br />
									성명 : 정현섭
									<br />
									소속 : 사업기획실
									<br />
									직위 : 이사
									<br />
									이메일 : info@vaunce.co.kr
									<br />
									전화 : 1599-9583
								</Box>
								<Box>
									<BoldSpan>개인정보보호 담당자</BoldSpan>
									<br />
									성명 : 류상우
									<br />
									소속 : 사업기획실 마케팅팀
									<br />
									직위 : 부장
									<br />
									이메일 : info@vaunce.co.kr
									<br />
									전화 : 1599-9583
								</Box>
							</Stack>
						),
					},
				],
			},
		],
	},
	{
		title: '12. 개인정보침해 관련 상담 및 신고',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<>
								개인정보침해에 대한 신고·상담이 필요하신 경우에는 개인정보보호책임자에 전화 또는
								서면, 이메일 주소로 연락하시거나, 한국인터넷진흥원(KISA)내 개인정보침해신고센터로
								문의하시기 바랍니다.
								<ul style={{ listStyleType: 'square' }}>
									<Typography variant='h4'>
										<li>개인정보침해 신고센터 (http://privacy.kisa.or.kr / 국번 없이 118)</li>
									</Typography>
									<Typography variant='h4'>
										<li>대검찰청 사이버수사과 (www.spo.go.kr / 국번 없이 1301)</li>
									</Typography>
									<Typography variant='h4'>
										<li>경찰청 사이버안전국 (www.ctrc.go.kr / 국번 없이 182)</li>
									</Typography>
									<Typography variant='h4'>
										<li>개인정보 분쟁조정위원회(www.kopico.go.kr / 1833-6972)</li>
									</Typography>
								</ul>
							</>
						),
					},
				],
			},
		],
	},

	{
		title: '13. 영상정보처리기기 운영방침',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<Stack spacing={2}>
								<Box>
									① 당사는 영상정보처리기기 운영방침을 통해 당사에서 처리하는 영상정보가 어떠한
									용도와 방식으로 이용되고 있는지 알려드립니다.
									<br />
									1. 영상정보처리기기의 설치 근거 및 설치 목적
									<br />
									당사는 개인정보보호법 제25조 제1항에 따라 다음과 같은 목적으로 영상정보처리기기를
									설치, 운영합니다.
									<br />- 시설안전 및 화재 예방
									<br />- 고객의 안전을 위한 범죄 예방
								</Box>

								<Box>
									2. 설치 대수, 설치 위치 및 촬영범위
									<br />
									<br />
									<BoldSpan>설치 대수</BoldSpan>
									<br />
									센터 출입구 또는 센터 내부 설치 대수 별도 안내
									<br />
									<BoldSpan>설치 위치 및 촬영 범위</BoldSpan>
									<br />
									센터 내 외부
								</Box>
								<Box>
									3. 관리책임자 및 접근권한자
									<br />
									고객의 영상정보를 보호하고 개인영상정보와 관련한 불만을 처리하기 위하여, 아래와
									같이 개인영상정보 관리책임자를 두고 있습니다.
									<br />
									<br />
									<BoldSpan>관리책임자</BoldSpan>
									<br />
									성명: 정현섭
									<br />
									직위: 이사
									<br />
									연락처: 1599 - 9583
									<br />
									<BoldSpan>접근권한자</BoldSpan>
									<br />
									이름: 각 센터 센터장
									<br />
									직책: 센터장
									<br />
									<>{'각 센터 전화번호 (홈페이지 > 센터 > 각 센터)'}</>
								</Box>
								<Box>
									4. 영상정보의 촬영시간, 보관기간, 보관장소 및 처리방법
									<br />
									<br />
									<BoldSpan>촬영시간</BoldSpan>
									<br />
									24시간
									<br />
									<BoldSpan>보관기간</BoldSpan>
									<br />
									촬영일로부터 약 30일
									<br />
									<BoldSpan>보관장소</BoldSpan>
									<br />
									센터 사무실 내 특정장소
									<br />
									<br />- 처리방법 : 개인영상정보의 목적 외 이용, 제3자 제공, 파기, 열람 등 요구에
									관한 사항을 기록관리하고, 보관기간 만료 시 복원이 불가능한 방법으로 영구
									삭제(출력물의 경우 파쇄 또는 소각)합니다.
								</Box>
								<Box>
									5. 개인영상정보의 확인 방법 및 장소에 관한 사항
									<br />
									개인영상정보 보호책임자에게 미리 연락하면 당사가 지정하는 특정 장소에서 확인이
									가능합니다.
								</Box>
								<Box>
									6. 정보주체의 영상정보 열람 등 요구에 대한 조치
									<br />
									개인영상정보에 관하여 열람 또는 존재 확인, 삭제를 원하는 경우 언제든지 당사에
									요구하실 수 있습니다.단, 귀하가 촬영된 개인영상정보 및 명백히 정보주체의 급박한
									생명, 신체, 재산의 이익을 위하여 필요한 개인영상정보에 한정됩니다.당사는
									개인영상정보에 관하여 열람 또는 존재 확인, 삭제를 요구한 경우 해당 요구를 검토하여
									지체없이 필요한 조치를 취할 것이며 불가피하에 해당 요구를 거부하는 경우에는 그
									사유를 통지하도록 하겠습니다.
								</Box>
								<Box>
									7. 영상정보의 안전성 확보조치
									<br />
									당사는 폐쇄 네트웍 또는 방화벽 등의 보안시스템을 통하여 고객의 영상정보를 보호하고
									있습니다.또한 개인영상정보 보호를 위하여 해당 영상정보를 취급하는 인원을
									최소한으로 제한하고 있으며 지속적인 교육을 시행하고 있습니다.
									<br />
									개인영상정보의 임의적인 취급을 제한하기 위하여 개인영상정보의 열람 시 열람 목적,
									열람자, 열람 일시 등을 기록, 관리하고 있으며 영상정보의 보관장소는 보안구역으로
									정하고 사전 승인된 인력 이외에는 출입을 통제하고 있습니다.
								</Box>
								<Box>
									8. 영상정보처리기기 운영·관리 방침 변경에 관한 사항
									<br />이 영상정보처리기기 운영·관리 방침은 2021년 11월 11일에 제정되었으며 법령,
									정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 시행하기
									최소 7일전에 당사 홈페이지를 통해 변경사유 및 내용 등을 공지하도록 하겠습니다.
									<br />
									공고일자 : 2021년 12월 1일 / 시행일자 : 2021년 12월 10일
								</Box>
							</Stack>
						),
					},
				],
			},
		],
	},
	{
		title: '14. 개인정보처리방침 변경 등에 대한 고지',
		contents: [
			{
				type: 'text',
				subtitle: '',
				actualContent: [
					{
						detail: (
							<Stack spacing={2}>
								<Box>
									개인정보처리방침내용의 추가, 삭제 및 수정이 있을 시에는 개정내용이 반영되기 전에
									웹 사이트의 공지사항을 통하여 고지할 것입니다.또한 개인정보 처리방침에 버전번호 및
									개정일자 등을 부여하여 개정여부를 쉽게 알 수 있도록 하며, 중요한 사항일 경우,
									고객의 이메일로 안내사항을 전달해 드리도록 하겠습니다.
								</Box>
								<Box>
									개인정보처리방침 시행일자 2021년 12월 01일
									<br />
									개인정보처리방침 최종변경일자 2021년 12월 01일
								</Box>
							</Stack>
						),
					},
				],
			},
		],
	},
];
