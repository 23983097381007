import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { whitespace } from '@kinderlabs-pos/shared-util';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { PageHeader } from '../../components/PageHeader';
import { vaunceAppFormiks } from '../../utils/useFormik';
import { ConfirmCouponDialog } from './ConfirmCouponDialog';

export interface IRegisterCouponProps {}
export const RegisterCoupon: React.FC<IRegisterCouponProps> = ({}) => {
	const [couponState, setCouponStatue] = useState<string>('');
	const { useCouponRegisterFormik } = vaunceAppFormiks();

	const { values, handleSubmit, handleChange, setValues, isValid, isSubmitting } =
		useCouponRegisterFormik({
			onSubmit: async () => {
				const data = await VaunceAppApis.getRegisterCouponAsync(values.couponText);
				if (data) {
					setCouponStatue(decodeURI(data));
				}
			},
		});

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader title='내 쿠폰' />
			<form onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<Stack alignItems={'center'}>
						<Stack direction={'row'}>
							<Typography variant='h5'>보유하고 계신</Typography>
							<Typography
								variant='h5'
								color='primary'>
								{whitespace}
								쿠폰번호
							</Typography>
							<Typography variant='h5'>를 입력하신 후</Typography>
						</Stack>
						<Typography variant='h5'>등록 버튼을 눌러 주시기 바랍니다.</Typography>
					</Stack>

					<TextField
						name='couponText'
						onChange={handleChange}
						sx={{ fontSize: '14px' }}
						fullWidth
						label={'쿠폰 번호 입력'}
						placeholder={'쿠폰 번호를 입력하세요.'}
						variant='standard'
					/>
					<Stack
						direction={'row'}
						p={1}>
						<WithDialog
							dialog={(open, closeDialog) =>
								couponState && (
									<ConfirmCouponDialog
										open={open}
										onClose={closeDialog}
										couponState={couponState}
									/>
								)
							}>
							{(openDialog) => (
								<RoundedSubmitButton
									fullWidth
									sx={{ height: 80 }}
									onClick={() => {
										handleSubmit();
										openDialog();
										setCouponStatue('');
									}}
									text='등록'
									isSubmitting={isSubmitting}
									disabled={!isValid}
								/>
							)}
						</WithDialog>
					</Stack>
				</Stack>
			</form>
		</Stack>
	);
};
