import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { MembershipType } from '@kinderlabs-pos/shared-data-type';
import { BoxProps, Stack, Typography } from '@mui/material';

export interface IMintTextBoxProps extends BoxProps {
	grade: MembershipType;
	fontSize?: string;
	withBackgroundColor?: boolean;
	longVer?: boolean;
}
export const GradeTextBox: React.FC<IMintTextBoxProps> = ({
	grade,
	withBackgroundColor: withBackground,
	fontSize,
	longVer,
}) => {
	const colors = {
		mint: vaunceColors.greenMint,
		pink: vaunceColors.lightPink,
		gold: vaunceColors.gold,
	};
	return (
		<Stack
			sx={
				withBackground
					? {
							textTransform: 'uppercase',
							width: 101,
							height: 26,
							// padding: '4px 20px 0px',
							borderRadius: '20px',
							backgroundColor:
								grade === 'MINT' ? colors.mint : grade === 'PINK' ? colors.pink : colors.gold,
					  }
					: {
							textTransform: 'uppercase',
							letterSpacing: '-0.025em',
					  }
			}>
			<Stack
				alignItems={'center'}
				justifyContent={'center'}>
				<Typography
					variant='h6'
					fontWeight={'medium'}
					fontSize={fontSize ? fontSize : '300'}
					color={'#FFF'}>
					{longVer ? `${grade} 회원` : grade}
				</Typography>
			</Stack>
		</Stack>
	);
};
