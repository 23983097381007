import guidecoupon from 'assets/vaunce/image/guidecoupon.png';
import guideparty from 'assets/vaunce/image/guideparty.png';
import guidepoint from 'assets/vaunce/image/guidepoint.png';
import guidepremium from 'assets/vaunce/image/guidepremium.png';
import guideregister from 'assets/vaunce/image/guideregister.png';

import guidecoupon2x from 'assets/vaunce/image/guidecoupon@2x.png';
import guideparty2x from 'assets/vaunce/image/guideparty@2x.png';
import guidepoint2x from 'assets/vaunce/image/guidepoint@2x.png';
import guidepremium2x from 'assets/vaunce/image/guidepremium@2x.png';
import guideregister2x from 'assets/vaunce/image/guideregister@2x.png';

export const AppGuideData = [
	{
		imageUrl: guidepoint,
		srcsetUrl: guidepoint2x,
		title: '포인트',
		contents:
			'바운스 전국 매장을 방문하고 결제하면 포인트 적립 가능하며, 자유롭게 사용 가능합니다.',
	},
	{
		imageUrl: guidecoupon,
		srcsetUrl: guidecoupon2x,
		title: '쿠폰',
		contents: '신규 가입, 등급별 쿠폰 등 다양한 쿠폰을 드립니다.',
	},
	{
		imageUrl: guideregister,
		srcsetUrl: guideregister2x,
		title: '사전 이용 등록',
		contents:
			'앱에서 미리 이용 정보를 등록하면 현장에서 별도의 정보 입력없이 QR코드만 찍으면 입장이 가능합니다.',
	},
	{
		imageUrl: guidepremium,
		srcsetUrl: guidepremium2x,

		title: '바운스 프리미엄 클럽',
		contents:
			'다회권, 정기권 등 바운스를 자주 방문하는 고객을 위한 프리미엄 상품을 합리적인 가격에 구매하고 사용할 수 있습니다.',
	},
	{
		imageUrl: guideparty,
		srcsetUrl: guideparty2x,
		title: '파티, 단체, 워크샵',
		contents:
			'바운스는 생일 파티, 어린이집/유치원/학교 등 단체 및 회사 워크샵 이용도 가능하며 앱에서 편리하게 예약하세요.',
	},
];
