import { CenterCollectionsType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box, Stack, styled, Typography } from '@mui/material';
import centerImage from 'assets/vaunce/image/center.png';
import centerImage2x from 'assets/vaunce/image/center@2x.png';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';

export interface ICenterPageProps {}
export const CenterPage: React.FC<ICenterPageProps> = ({}) => {
	const { navigateCenterDetail, navigateMypage } = useVaunceAppRouter();
	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();
	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader
				title='센터안내'
				navigateAlterUrl={navigateMypage}
			/>
			<TitleBox>
				<img
					src={centerImage}
					srcSet={centerImage2x}
					width={64}
					height={64}
				/>
				<Box
					display={'flex'}
					flexDirection={'column'}
					marginLeft={'1rem'}>
					<Typography variant='h3'>센터 바로 가기</Typography>
					<Typography
						variant='body1'
						fontWeight={500}>
						센터명을 선택하면,
					</Typography>
					<Typography
						variant='body1'
						fontWeight={500}>
						센터를 자세히 둘러 볼 수 있습니다.
					</Typography>
				</Box>
			</TitleBox>

			<ContentBox>
				{centerCollectionData.map((center) => (
					<ContentInnerBox
						key={center.centerId}
						onClick={() => {
							navigateCenterDetail(center.centerId);
						}}>
						<Typography
							variant='h3'
							marginRight={'auto'}>
							{center.centerDisplay} {center.centerId === 50 && '(폐점)'}
						</Typography>
						<ArrowCircleRightIcon sx={{ color: '#FF5C89' }} />
					</ContentInnerBox>
				))}
			</ContentBox>
		</Stack>
	);
};

const TitleBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	backgroundColor: '#F4F4F4',
	borderRadius: '12px',
	padding: '15px',
	marginTop: '20px',
}));

const SmallTypography = styled(Typography)(({ theme }) => ({
	color: 'rgba(0, 0, 0, 0.60)',
	fontSize: '1.2em',
}));

const ContentBox = styled(Box)(({ theme }) => ({
	borderTop: '1px solid rgba(0, 0, 0, 0.40)',
	height: '100%',
	overflowY: 'scroll',
	marginTop: '1rem',
}));

const ContentInnerBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
	padding: '25px 17px',
	cursor: 'pointer',
}));
