import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { styled, Tabs, TabsProps } from '@mui/material';
import React from 'react';

export interface IVauncePinkTabsProps extends TabsProps {}
const VauncePinkTabs: React.FC<IVauncePinkTabsProps> = ({ ...others }) => {
	return <StyledTabs {...others} />;
};
export default VauncePinkTabs;

const StyledTabs = styled(Tabs)(({ theme }) => ({
	'& .Mui-selected': {
		// color: `${theme.palette.lightPink} !important`,
		color: `${vaunceAppColors.lightPink} !important`,
	},
	'& .MuiTabs-indicator': {
		backgroundColor: `${vaunceAppColors.lightPink}`,
		// backgroundColor: `${theme.palette.lightPink}`,
		height: '0.2em',
	},
}));
