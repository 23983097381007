import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { PopupType } from '@kinderlabs-pos/shared-data-type';
export interface IAdminPopupDialogProps {
	open: boolean;
	handleClose: () => void;
	popup: PopupType;
}
export const AdminPopupDialog: React.FC<IAdminPopupDialogProps> = ({
	open,
	handleClose,
	popup,
}) => {
	const [adminPopupCookie, setAdminPopupCookie] = useCookies(['ad-modal-today-close']);
	const handleClick = (type: number) => {
		setAdminPopupCookie('ad-modal-today-close', type, {
			path: '/',
			expires: dayjs().add(1, 'd').toDate(),
		});
		handleClose();
	};
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth={'xl'}>
			<DialogContent>
				<Stack spacing={1}>
					<img
						src={popup.popupImgUrl}
						onClick={() => {
							location.href = popup.linkUrl;
						}}
					/>
					{/* <Stack
						sx={{
							minWidth: window.innerWidth * 0.7,
							minHeight: window.innerHeight * 0.7,
							backgroundImage: `url(${popup.popupImgUrl})`,
							backgroundSize: 'cover',
						}}
						onClick={() => {
							location.href = popup.linkUrl;
						}}
					/> */}
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={2}>
						<RoundedButton
							onClick={() => {
								handleClick(popup.id);
								loggingEvent(`어드민 푸시 > 오늘 그만보기`, 'agree', `adminpushStop`);
							}}
							sx={{
								flex: 1,
								backgroundColor: 'white',
								color: 'black',
								borderRadius: 0,
								height: 35,
							}}>
							<Typography variant='h5'> {'오늘 그만보기'}</Typography>
						</RoundedButton>
						<RoundedButton
							onClick={() => {
								handleClose();
								loggingEvent(`어드민 푸시 > 닫기`, 'agree', `adminpushClose`);
							}}
							sx={{
								flex: 1,
								backgroundColor: 'black',
								color: 'white',
								borderRadius: 0,
								height: 35,
							}}>
							<Typography
								color='white'
								variant='h5'>
								{'닫기'}
							</Typography>
						</RoundedButton>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
