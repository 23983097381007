import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, CheckboxProps } from '@mui/material';

interface IVaunceCheckBoxProps extends CheckboxProps {}
export const VaunceCheckBox: React.FC<IVaunceCheckBoxProps> = ({ ...others }) => {
	return (
		<Checkbox
			icon={<CheckBoxOutlineBlankIcon style={{ height: '20px', width: '20px' }} />}
			checkedIcon={<CheckBoxIcon style={{ height: '20px', width: '20px' }} />}
			{...others}
		/>
	);
};
