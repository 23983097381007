import { Box, Stack, styled, Typography } from '@mui/material';
import multiPassImage from 'assets/vaunce/image/multipass.png';
import oneStopImage from 'assets/vaunce/image/onestop.png';
import cmtTicketImage from 'assets/vaunce/image/seasonticket.png';

import multiPassImage2x from 'assets/vaunce/image/multipass@2x.png';
import oneStopImage2x from 'assets/vaunce/image/onestop@2x.png';
import cmtTicketImage2x from 'assets/vaunce/image/seasonticket@2x.png';

import GrayBox from '../../components/GrayBox';
import { PageHeader } from '../../components/PageHeader';
import { useVaunceAppRouter } from '../routes';

export interface IOneStopPageProps {}
export const OneStopPage: React.FC<IOneStopPageProps> = ({}) => {
	const { navigateMultiUseTickets, navigateCommutationTickets, navigateOneTickets } =
		useVaunceAppRouter();
	return (
		<Stack
			height={'100%'}
			flexDirection={'column'}>
			<PageHeader title='바운스 프리미엄 클럽' />
			<GrayBox display={'flex'}>
				<img
					src={oneStopImage}
					srcSet={oneStopImage2x}
					width={64}
					height={64}
					style={{ marginRight: '15px' }}
				/>
				<ContentBox>
					<Typography
						marginBottom={'0.5rem'}
						variant='h3'>
						비용은 줄이고, 혜택은 늘리고!
					</Typography>
					<Typography variant='body1'>
						바운스 매니아라면 놓쳐서는 안될 세가지 맞춤형 멤버십 프로그램!
					</Typography>
				</ContentBox>
			</GrayBox>

			<PassBox>
				<PassInnerBox style={{ borderBottom: '1px solid rgba(112, 112, 112, 0.50)' }}>
					<ImageContentBox>
						<img
							src={multiPassImage}
							srcSet={multiPassImage2x}
							width={34}
							height={34}
						/>
					</ImageContentBox>

					<ContentBox
						onClick={() => {
							navigateMultiUseTickets();
						}}>
						<Typography
							marginBottom={'0.5rem'}
							color={'primary'}
							variant='h2'>
							다회권
						</Typography>
						<Typography
							variant='h3'
							fontWeight={700}>
							5회권 구매 시, 1회 추가 증정!
							{/* 5회권, 10회권 */}
						</Typography>
					</ContentBox>
				</PassInnerBox>

				<PassInnerBox>
					<ImageContentBox>
						<img
							src={cmtTicketImage}
							srcSet={cmtTicketImage2x}
							width={34}
							height={34}
						/>
					</ImageContentBox>

					<ContentBox
						onClick={() => {
							navigateCommutationTickets();
						}}>
						<Typography
							variant='h2'
							color={'primary'}
							marginBottom={'0.5rem'}>
							정기 회원권
						</Typography>
						<Typography
							variant='h3'
							fontWeight={700}>
							기간 내 무제한으로 이용하세요!
						</Typography>
					</ContentBox>
				</PassInnerBox>
			</PassBox>
		</Stack>
	);
};

const ContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: '1rem',
	cursor: 'pointer',
}));

const PassBox = styled(Box)(({ theme }) => ({
	width: '100%',
	marginTop: '26px',
}));

const PassInnerBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: '20px 9px 25px 13px',
	textAlign: 'left',
}));

const ImageContentBox = styled(Box)(({ theme }) => ({
	textAlign: 'center',
}));
