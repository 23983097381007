import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, FormControlLabel, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../components/PageHeader';
import { VaunceCheckBox } from '../../components/VaunceCheckBox';
import { loggingEvent } from '../../utils/firebaseAnalytics';

const noticeList: string[] = [
	'보유 중인 포인트 및 쿠폰은 소멸합니다.',
	'구매하신 티켓 중 미사용 티켓이 있으면 탈퇴 처리가 불가하며, 이 경우 고객센터로 문의해주시기를 바랍니다.',
	'탈퇴 처리 후 재가입은 7일 이후 가입할 수 있으며 기존 ID는 사용하실 수 없고 재가입 시 신규회원에게 주어지는 혜택은 주어지지 않습니다.',
	'(카카오, 네이버 간편 가입은 동일 아이디 사용 가능)',
];

export interface ILeavePageProps {}
export const LeavePage: React.FC<ILeavePageProps> = ({}) => {
	const navigate = useNavigate();
	const [checked, setChecked] = React.useState<boolean>(false);

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader title='회원탈퇴' />
			<Typography
				variant='h3'
				color='primary'
				textAlign={'center'}>
				바운스 회원 탈퇴 전 확인하세요.
			</Typography>
			<ContentBox>
				{noticeList.map((notice, idx) => (
					<li
						style={
							idx === 3
								? { fontSize: '1.5em', listStyle: 'none', marginLeft: '2rem' }
								: { fontSize: '1.5em' }
						}>
						{notice}
					</li>
				))}
			</ContentBox>

			<Stack
				display={'flex'}
				mb={2}>
				<FormControlLabel
					style={{ fontSize: '1.5em' }}
					label={
						<Typography variant='h5'>안내 사항을 모두 확인하였으며, 이에 동의합니다.</Typography>
					}
					control={
						<VaunceCheckBox
							checked={checked}
							onChange={() => {
								setChecked(!checked);
							}}
							size={'small'}
						/>
					}
				/>
			</Stack>

			<Stack
				display={'flex'}
				flexDirection={'row'}
				width={'100%'}>
				<RoundedButton
					sx={{
						width: '50%',
						height: 60,
						fontSize: '16px',
						backgroundColor: '#FFF',
						color: '#FF5C89',
						marginRight: '3rem',
					}}
					onClick={async () => {
						const data = await VaunceAppApis.getWithdrawal();
						if (data && data.success) {
							navigate('/');
						}
						loggingEvent(`회원탈퇴`, 'submit', `leave`);
					}}>
					탈퇴하기
				</RoundedButton>
				<RoundedButton
					sx={{ width: '50%', height: 60, fontSize: '16px' }}
					onClick={() => {
						navigate('/');
					}}>
					취소
				</RoundedButton>
			</Stack>
		</Stack>
	);
};

const ContentBox = styled(Box)(({ theme }) => ({
	padding: '20px 9px 25px 13px',
	textAlign: 'left',
	borderBottom: '1px solid rgba(112, 112, 112, 0.50)',
}));
