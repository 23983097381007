import logoImage from 'assets/vaunce/image/logo.png';
import logoImage2X from 'assets/vaunce/image/logo@2x.png';
import { useNavigate } from 'react-router-dom';
import { MemberBoxInToolbar } from './MemberBoxInToolbar';
import { styled } from '@mui/material';

export const MainHeader = () => {
	const navigate = useNavigate();

	return (
		<ToolBarBox>
			<img
				src={logoImage}
				srcSet={logoImage2X}
				width={78}
				height={32}
				style={{ marginRight: 'auto' }}
				onClick={() => {
					navigate('/');
				}}
			/>
			<MemberBoxInToolbar />
		</ToolBarBox>
	);
};

const ToolBarBox = styled('div')(({ theme }) => ({
	display: 'flex',
	margin: '0 2rem 0 2rem',
	alignItems: 'center',
	height: 77,
}));
