import { KioskLocalSettingsType, PosLocalSettingsType } from '@kinderlabs-pos/ipc/common';
import { ReactKioskMainPageIPC, ReactPosMainPageIPC } from '@kinderlabs-pos/ipc/react';
import { useQuery } from '@tanstack/react-query';
import { QueryClient } from '../QueryClient';

const queryKey = ['electron'];

const usePosMainPageLocalSettings = () =>
	useQuery<PosLocalSettingsType>({
		queryKey: [...queryKey, 'pos-main'],
		// initialData: {
		// 	useNewDesignOnLabelPrinter1: false,
		// 	useNewDesignOnLabelPrinter2: false,
		// 	recentLoginId: undefined,
		// 	recentBells: [],
		// 	printStacks: ['PRODUCT', 'CARD', 'PAYMENT'],
		// 	usingDefaultPosFilter: false,
		// 	usingLargeTextOnKitchenReceiptPrinter: false,
		// 	goAutoMainPageAfterCompleteOrder: false,
		// },
		queryFn: async () => {
			const data = await ReactPosMainPageIPC.localSettings.loadSettings();

			return {
				useNewDesignOnLabelPrinter1: data.useNewDesignOnLabelPrinter1 ?? false,
				useNewDesignOnLabelPrinter2: data.useNewDesignOnLabelPrinter2 ?? false,
				recentLoginId: data.recentLoginId,
				recentBells: data.recentBells ?? [],
				printStacks: data.printStacks ?? ['PRODUCT', 'CARD', 'PAYMENT'],
				usingDefaultPosFilter: data.usingDefaultPosFilter ?? false,
				usingLargeTextOnKitchenReceiptPrinter: data.usingLargeTextOnKitchenReceiptPrinter ?? false,
				usingKitchenCancelBills: data.usingKitchenCancelBills ?? false,
				goAutoMainPageAfterCompleteOrder: data.goAutoMainPageAfterCompleteOrder ?? false,
				using알림톡전화번호입력시결제모듈로전화번호입력설정:
					data.using알림톡전화번호입력시결제모듈로전화번호입력설정 ?? false,
				using응대고객없는경우정보입력활성화설정:
					data.using응대고객없는경우정보입력활성화설정 ?? false,
				callWith응대: data.callWith응대 ?? false,
				usingBarcodeModel: data.usingBarcodeModel,
				defaultSidebar: data.defaultSidebar ?? 'VISIT',
			};
		},
		keepPreviousData: false,
		refetchOnWindowFocus: false,
	});

const useSavePosLocalSettings = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (settings: PosLocalSettingsType) => {
			return await ReactPosMainPageIPC.localSettings.saveSettings(settings);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...queryKey]);
			},
		}
	);
};

const defaultKioskLocalSettings: KioskLocalSettingsType = {
	recentLoginInfo: undefined,
	kioskInfo: undefined,
	recentBells: [],
	isMemberInfoRequiredForFABKiosk: false,
	hideSoldOutItems: false,
	receiptAutoPrint: false,
	showScrollButtons: false,
	// 온라인권 영수증에 표기될 메세지
	onlineTicketReceiptMessage: {
		kor: undefined,
		eng: undefined,
	},
	skipMembership: false,
	// 온라인권 안내 문구 메세지
	onlineTicketMessage: {
		kor: undefined,
		eng: undefined,
	},
	usingBarcodeModel: 'QRCODE',
	i18n: {
		eng: false,
		jpn: false,
		chn: false,
	},
	카드삽입여부검사: true,
};
const useKioskMainPageLocalSettings = () =>
	useQuery<KioskLocalSettingsType>({
		queryKey: [...queryKey, 'kiosk-main'],
		queryFn: async () => {
			const data = await ReactKioskMainPageIPC.localSettings.loadSettings();
			return {
				...defaultKioskLocalSettings,
				...data,
			};
		},
		keepPreviousData: false,
		refetchOnWindowFocus: false,
	});

const useSaveKioskLocalSettings = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (settings: KioskLocalSettingsType) => {
			return await ReactKioskMainPageIPC.localSettings.saveSettings(settings);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([...queryKey]);
			},
		}
	);
};

export const PosElectronStoreState = {
	usePosMainPageLocalSettings,
	useSavePosLocalSettings,
};

export const KioskElectronStoreState = {
	useKioskMainPageLocalSettings,
	useSaveKioskLocalSettings,
};
