import { axiosAppClient } from '@kinderlabs-pos/apis/axios';
import { CenterHistoryListType, FavoritCenterBodyType } from '@kinderlabs-pos/shared-data-type';

export const getCenterCollectionAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<any>(`${rootUrl}/center-collection`)
	).data;

export const getCenterHistoryAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<CenterHistoryListType>(`${rootUrl}/center-history`)
	).data;

// 자주이용하는센터와 추천인 ID를 함께 저장하는 API
export const postUserExtraInfoAsync = (rootUrl: string) => async (formData: FormData) =>
	await (
		await axiosAppClient.post<FavoritCenterBodyType>(`${rootUrl}/users/favorite-center`, formData)
	).data;
