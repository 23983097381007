import {
	TeckLabsEntranceLog,
	TeckLabsFavoritCenterLog,
	TeckLabsPageRouteLog,
	TeckLabsTicketPurchaseLog,
} from '@kinderlabs-pos/shared-data-type';
import axios from 'axios';

export const postTechLabsLogging =
	(rootUrl: string) =>
	async (
		data:
			| TeckLabsPageRouteLog
			| TeckLabsTicketPurchaseLog
			| TeckLabsEntranceLog
			| TeckLabsFavoritCenterLog
	) =>
		await axios.post(`${rootUrl}`, data);
