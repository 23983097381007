import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { PayListBodyType, PayPeriod } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas } from '@kinderlabs-pos/shared-util';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, Stack, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { EmptyContent } from '../../components/EmptyContent';
import { PageHeader } from '../../components/PageHeader';
import VaunceButton from '../../components/VaunceButton';

export interface IPayHistoryPageProps {}
const PayHistoryPage: React.FC<IPayHistoryPageProps> = ({}) => {
	const sixmonthPayList = VaunceQueryState.usePayQuery('sixmonths');

	const [payList, setPayList] = useState<PayListBodyType[]>(sixmonthPayList);
	const [period, setPeriod] = useState<PayPeriod>('sixmonths');

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader title='결제내역' />
			{sixmonthPayList.length === 0 && <EmptyContent title={'결제 내역이 없습니다.'} />}

			{payList.length > 0 && (
				<PayContentBox>
					<PeroidBtnGroupBox
						display={'flex'}
						flexDirection={'row'}
						alignItems={'center'}
						marginTop={'1rem'}
						marginBottom={'2rem'}>
						<Typography
							marginRight={'auto'}
							marginLeft={'auto'}
							variant='h5'>
							조회 기간:
						</Typography>
						<VaunceButton
							style={
								period !== 'sixmonths'
									? {
											width: '20%',
											color: 'rgba(0, 0, 0, 0.40)',
											border: '1px solid #F4F4F4',
											backgroundColor: '#F4F4F4',
											borderRadius: '6px',
											padding: '6px 0',
									  }
									: { width: '20%' }
							}
							onClick={async () => {
								setPeriod('sixmonths');
								const data = await VaunceAppApis.getPayAsync('sixmonths');
								if (data) {
									setPayList(data.vo);
								}
							}}>
							6개월
						</VaunceButton>
						<VaunceButton
							style={
								period !== 'oneyear'
									? {
											width: '20%',
											color: 'rgba(0, 0, 0, 0.40)',
											border: '1px solid #F4F4F4',
											backgroundColor: '#F4F4F4',
											borderRadius: '6px',
											padding: '6px 0',
									  }
									: { width: '20%' }
							}
							onClick={async () => {
								setPeriod('oneyear');
								const data = await VaunceAppApis.getPayAsync('oneyear');
								if (data) {
									setPayList(data.vo);
								}
							}}>
							1년
						</VaunceButton>
						<VaunceButton
							style={
								period !== 'lookupAll'
									? {
											width: '20%',
											color: 'rgba(0, 0, 0, 0.40)',
											border: '1px solid #F4F4F4',
											backgroundColor: '#F4F4F4',
											borderRadius: '6px',
											padding: '6px 0',
									  }
									: { width: '20%' }
							}
							onClick={async () => {
								setPeriod('lookupAll');
								const data = await VaunceAppApis.getPayAsync('lookupAll');
								if (data) {
									setPayList(data.vo);
								}
							}}>
							전체
						</VaunceButton>
					</PeroidBtnGroupBox>

					<Box sx={{ overflow: 'auto', marginBottom: '10rem' }}>
						{payList.map((payData, idx) => (
							<PayInnerContentBox key={idx}>
								<Stack
									direction={'column'}
									marginRight={'auto'}
									width={'100%'}>
									<Stack direction={'row'}>
										<Typography
											variant='h4'
											sx={{ marginRight: 'auto' }}>
											{payData.ticket_title}
										</Typography>

										<Typography
											variant='h6'
											fontWeight={500}>
											{numberWithCommas(Number(payData.pg_amt))} 원
										</Typography>
									</Stack>

									<Stack
										direction={'row'}
										spacing={2}>
										<Typography
											variant='h6'
											fontWeight={500}
											color={vaunceAppColors.lightGray}>
											{payData.pg_paid_at}
										</Typography>
										<Typography
											variant='h6'
											fontWeight={500}
											color={vaunceAppColors.lightGray}>
											{payData.pg_card_quota}개월
										</Typography>
									</Stack>

									<Stack>
										{payData.pg_done_cancel_at && payData.pg_cancel_amount && (
											<Stack
												direction={'row'}
												width={'100%'}
												justifyContent={'space-between'}>
												<Typography
													variant='h6'
													fontWeight={500}
													color={'primary'}>
													결제 취소
												</Typography>
												<Typography
													variant='h6'
													fontWeight={500}
													color={vaunceAppColors.lightGray}>
													{payData.pg_done_cancel_at}
												</Typography>
												<Typography
													variant='h6'
													fontWeight={500}
													color={vaunceAppColors.lightGray}>
													취소금액 {numberWithCommas(Number(payData.pg_cancel_amount))} 원
												</Typography>
											</Stack>
										)}
									</Stack>
								</Stack>
							</PayInnerContentBox>
						))}
					</Box>
				</PayContentBox>
			)}
		</Stack>
	);
};

export default PayHistoryPage;

const ContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
}));

const PayContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	height: '100%',
	flexDirection: 'column',
}));

const PayInnerContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	borderBottom: '1px solid rgba(0, 0, 0, 0.40)',
	padding: '9px 15px 11px',
}));

const PeroidBtnGroupBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	marginTop: '1rem',
	marginBottom: '2rem',
}));
