import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { Box, Stack, styled, Typography } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoginHeaderTypograpy } from '../../components/LoginTitleBox';
import { useVaunceAppRouter } from '../routes';
import { LoginPageHeader } from './LoginPageHeader';
import { SNSLoginButton } from './SNSLoginRedirectPage/SNSLoginButton';
import { isIOS } from '@kinderlabs-pos/shared-util';

export interface ISignUpFirstPageProps {}
export const SignUpFirstPage: React.FC<ISignUpFirstPageProps> = ({}) => {
	const { navigateSignUpStepTwo } = useVaunceAppRouter();
	return (
		<Stack>
			<LoginPageHeader />
			<LoginHeaderTypograpy>SIGN UP</LoginHeaderTypograpy>
			<Typography variant='h3'>가입유형을 선택해주세요.</Typography>
			<SignBox
				onClick={() => {
					navigateSignUpStepTwo();
				}}>
				<Typography
					color='primary'
					variant='h3'
					mb={2}>
					일반 회원가입
				</Typography>
				<Stack
					direction={'row'}
					alignItems={'center'}>
					<Typography
						color='primary'
						variant='h5'>
						만 14세 이상 국내 거주 한국인
					</Typography>
					<ArrowForwardIosIcon
						sx={{ color: `${vaunceAppColors.lightPink}`, marginLeft: 'auto', height: 17 }}
					/>
				</Stack>
			</SignBox>

			<SignBox>
				<Typography
					color='primary'
					variant='h3'
					mb={2}>
					SNS 회원가입
				</Typography>
				<Stack
					direction={'row'}
					alignItems={'center'}
				/>
				<SNSLoginButton provider='kakao' />
				<SNSLoginButton provider='naver' />
				{/* {isIOS() && <SNSLoginButton provider='apple' />} */}
			</SignBox>
		</Stack>
	);
};

const SignBox = styled(Box)(({ theme }) => ({
	border: `1px solid ${vaunceAppColors.lightPink}`,
	backgroundColor: '#FFF',
	borderRadius: '12px',
	boxShadow: '0 4px 16px rgb(0 0 0 / 16%)',
	cursor: 'pointer',
	padding: '22px 19px 25px',
	marginTop: '2rem',
}));
