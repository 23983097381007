import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { ChildListType, ChildrenBodyType } from '@kinderlabs-pos/shared-data-type';
import { whitespace } from '@kinderlabs-pos/shared-util';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Box, InputLabel, NativeSelect, Stack, Typography } from '@mui/material';
import { FieldArray, FormikErrors, FormikProvider } from 'formik';
import { StyleFormControl, StyleTextField } from '../../components/CommonStyled';
import VaunceButton from '../../components/VaunceButton';
import { vaunceAppFormiks } from '../../utils/useFormik';
import { VaunceOptionButton } from '../../components/VaunceOptionButton';

export interface IChildEnrollProps {}
export const ChildEnrollPart: React.FC<IChildEnrollProps> = ({}) => {
	const deleteChildren = async (child: ChildListType) => {
		if (child.id) {
			const data = await VaunceAppApis.deleteChildrenAsync(child.id);
			if (data) {
				if ((data.vo as ChildrenBodyType).message === 'success') {
					console.log('success');
				}
			}
		}
	};

	const newTemplate = {
		birth: '',
		name: '',
		relation: 0,
		gender: 'M',
	};

	const { useChildrenFormik } = vaunceAppFormiks();
	const formik = useChildrenFormik();
	const updateChildren = VaunceQueryState.useChildrenQuery();
	const {
		values,
		handleChange,
		initialValues,
		handleSubmit,
		errors,
		handleBlur,
		isValid,
		isSubmitting,
	} = formik;

	return (
		<FormikProvider value={formik}>
			<FieldArray name='childList'>
				{({ insert, remove, push }) => (
					<>
						{values.childList.map((child, idx) => (
							<Stack
								key={idx}
								spacing={3}
								sx={
									values.childList.length > 1
										? { pb: 4, borderBottom: `1px dashed ${vaunceColors.lightPink}` }
										: { borderBottom: '' }
								}>
								<Stack>
									<Stack
										direction={'row'}
										alignItems={'flex-end'}>
										<StyleTextField
											fullWidth
											InputProps={{
												style: { fontSize: '16px', fontWeight: 400 },
											}}
											label={
												<Typography
													variant='h6'
													fontWeight={400}>
													자녀 이름
												</Typography>
											}
											variant='standard'
											placeholder='이름을 입력하세요.'
											name={`childList.${idx}.name`}
											defaultValue={child.name}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<Stack>
											<VaunceButton
												style={{ width: '20%', height: '50%', flex: 1 }}
												onClick={() => {
													remove(idx);
													deleteChildren(child);
												}}>
												삭제
											</VaunceButton>
										</Stack>
									</Stack>
									<Typography
										color={'primary'}
										textAlign={'left'}>
										{(errors.childList &&
											errors.childList[idx] &&
											(errors.childList[idx] as FormikErrors<ChildListType>).name &&
											(errors.childList[idx] as FormikErrors<ChildListType>).name) ||
											whitespace}
									</Typography>
								</Stack>

								<Stack>
									<Stack
										direction={'row'}
										alignItems={'flex-end'}>
										<Stack
											direction='column'
											alignItems='flex-start'>
											<StyleTextField
												fullWidth
												InputProps={{
													style: { fontSize: '16px', fontWeight: 400 },
												}}
												label={
													<Typography
														variant='h6'
														fontWeight={400}>
														생년월일(8자리)
													</Typography>
												}
												variant='standard'
												placeholder='예) 20230101'
												name={`childList.${idx}.birth`}
												defaultValue={child.birth}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Stack>

										<VaunceOptionButton
											options={child.gender !== 'M'}
											name={`childList.${idx}.gender`}
											value={'M'}
											onClick={handleChange}
											text={'남성'}
										/>
										<VaunceOptionButton
											options={child.gender !== 'F'}
											name={`childList.${idx}.gender`}
											value={'F'}
											onClick={handleChange}
											text={'여성'}
										/>
									</Stack>
									{errors.childList && (
										<Typography
											color={'primary'}
											textAlign={'left'}>
											{(errors.childList &&
												errors.childList[idx] &&
												(errors.childList[idx] as FormikErrors<ChildListType>).birth &&
												(errors.childList[idx] as FormikErrors<ChildListType>).birth) ||
												whitespace}
										</Typography>
									)}
								</Stack>
								<Stack>
									<StyleFormControl style={{ flex: 1 }}>
										<InputLabel
											variant='standard'
											htmlFor='uncontrolled-native'>
											<Typography
												variant='h6'
												fontWeight={400}>
												관계
											</Typography>
										</InputLabel>
										<NativeSelect
											sx={{
												fontSize: '16px',
												fontWeight: 400,
											}}
											name={`childList.${idx}.relation`}
											value={child.relation}
											onChange={handleChange}
											onBlur={handleBlur}>
											<option value={0}> 선택 </option>
											<option value={1}> 아들 </option>
											<option value={2}> 딸 </option>
										</NativeSelect>
									</StyleFormControl>

									<Typography
										fontWeight={400}
										fontSize={'1.2em'}
										color={'primary'}
										textAlign={'left'}>
										{(errors.childList &&
											errors.childList[idx] &&
											(errors.childList[idx] as FormikErrors<ChildListType>).relation &&
											(errors.childList[idx] as FormikErrors<ChildListType>).relation) ||
											whitespace}
									</Typography>
								</Stack>
							</Stack>
						))}
						<VaunceButton
							style={{ width: '100%' }}
							onClick={() => push(newTemplate)}>
							+추가 자녀 등록
						</VaunceButton>
						<Box>
							<RoundedSubmitButton
								sx={{ width: '100%' }}
								type='submit'
								disabled={!isValid}
								isSubmitting={isSubmitting}
								text={'자녀 등록'}
								onClick={() => {
									handleSubmit();
								}}
							/>
						</Box>
					</>
				)}
			</FieldArray>
		</FormikProvider>
	);
};
