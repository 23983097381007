import { PremiumMutType } from '@kinderlabs-pos/shared-data-type';
import { CloseIconBox } from '@kinderlabs-pos/ui-atoms';
import {
	Box,
	Dialog,
	DialogContent,
	DialogProps,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import QrCode from 'apps/vaunce-app/src/components/QrCode';

export interface ICmtTicketQrDialogProps extends DialogProps {
	ticket: PremiumMutType;
	onClose: () => void;
}
export const CmtTicketQrDialog: React.FC<ICmtTicketQrDialogProps> = ({ open, onClose, ticket }) => {
	return (
		<Dialog
			maxWidth='md'
			open={open}
			onClose={onClose}
			fullWidth>
			<DialogContent>
				<Stack
					direction={'row'}
					justifyContent={'flex-end'}>
					<IconButton onClick={onClose}>
						<CloseIconBox />
					</IconButton>
				</Stack>

				<Stack>
					<Stack
						textAlign={'center'}
						margin={'40px 0 40px'}
						alignItems={'center'}
						spacing={2}>
						<Typography
							variant='h4'
							fontWeight={700}
							color='primary'>
							사용자 정보
						</Typography>
						<Box
							display={'flex'}
							width={'140px'}
							height={'140px'}
							alignItems={'center'}>
							<img
								src={ticket.imageUrl ?? 'http://101.101.217.174/image/logo@2x.png'}
								style={{ borderRadius: '50%', width: '150px', height: '150px' }}
							/>
						</Box>

						<Typography variant='h2'>{ticket.name}</Typography>
						<Stack>
							<Typography variant='h5'>바운스 프리미엄 클럽</Typography>
							<Typography variant='h5'>유효기간 ({ticket.expiredAt} 까지)</Typography>
						</Stack>

						<QrCode
							qrData={ticket.orderNum}
							style={{ marginTop: '40px' }}
						/>
						<Typography variant='h5'>정기권 QR 코드</Typography>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};
