import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { Coupon } from '@kinderlabs-pos/shared-data-type';
import { DialogProps, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedDialog } from '../../components/FormattedDialog';
import QrCode from '../../components/QrCode';
import { replaceAll } from '@kinderlabs-pos/shared-util';

export interface IQrImageDialogProps extends DialogProps {
	coupon: Coupon;
	onClose: () => void;
}
const QrImageDialog: React.FC<IQrImageDialogProps> = ({ open, onClose, coupon }) => {
	return (
		<FormattedDialog
			open={open}
			closeDialog={onClose}>
			<Stack sx={{ pb: 2 }}>
				<Stack
					textAlign={'center'}
					alignItems='center'
					spacing={2}>
					<Typography
						variant='h3'
						color={vaunceColors.lightPink}>
						{coupon.title}
					</Typography>
					<QrCode qrData={coupon.qrData} />
					<Typography
						variant='h5'
						fontWeight={500}>
						{coupon.idx}
					</Typography>
					<Typography
						variant='h5'
						fontWeight={500}
						sx={{ opacity: 0.6 }}>
						유효기간 {coupon.expiredAt} 까지
					</Typography>
				</Stack>
				<Stack
					sx={{ mt: '2rem' }}
					borderTop={'1px solid rgba(0, 0, 0, 0.60)'}>
					<Typography
						variant='h4'
						sx={{ my: '1rem', color: '#001243' }}>
						사용안내
					</Typography>
					{coupon.usingDescription && (
						<Typography
							variant='h6'
							fontWeight={400}
							dangerouslySetInnerHTML={{
								__html: decodeURI(coupon.usingDescription).replace(/(\n|\r\n)/g, '<br>'),
							}}
						/>
					)}
				</Stack>
			</Stack>
		</FormattedDialog>
	);
};

export default QrImageDialog;
