import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import submitWarning from 'assets/vaunce/image/submitwarning.png';
import GrayBox from '../../components/GrayBox';
import { PageHeader } from '../../components/PageHeader';
import { CenterGuideData } from './CenterGuideData';

export interface I센터이용가이드PageProps {}
export const CenterGuidePage: React.FC<I센터이용가이드PageProps> = ({}) => {
	const theme = useTheme();
	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<PageHeader title='센터이용 가이드' />
			<Stack
				spacing={2}
				sx={{ flex: 1, overflow: 'inherit' }}>
				<Grid2 container>
					{CenterGuideData.map((item, idx) => (
						<Grid2
							xs={6}
							key={idx}
							sx={{ mt: 1 }}>
							<Stack
								alignItems={'center'}
								spacing={1}>
								<Box>
									<img src={item.imageUrl} />
								</Box>
								<Typography
									variant='h4'
									sx={{ color: `${vaunceColors.lightPink}`, fontWeight: 700 }}>
									{item.title}
								</Typography>

								<Typography variant='h5'>{item.description}</Typography>
							</Stack>
						</Grid2>
					))}
				</Grid2>
				<GrayBox>
					<Stack
						direction={'row'}
						spacing={2}
						alignItems={'center'}>
						<Box>
							<img src={submitWarning} />
						</Box>
						<Box>
							<Typography
								variant='h4'
								sx={{ color: `${vaunceColors.lightPink}`, fontWeight: 700 }}>
								꼭! 확인하세요.
							</Typography>
							<Typography variant='h5'>일부 센터는 이용 절차가 다를 수 있습니다.</Typography>
						</Box>
					</Stack>
				</GrayBox>
			</Stack>
		</Stack>
	);
};
