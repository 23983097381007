import { axiosAppClient } from '@kinderlabs-pos/apis/axios';
import { QuestionInfoResponseType } from '@kinderlabs-pos/shared-data-type';

export const getNormalQuestionListAsync = (rootUrl: string) => async () =>
	(await axiosAppClient.get<QuestionArrayType>(`${rootUrl}/question/get_question_nomal`)).data;

export const getPartyQuestionListAsync = (rootUrl: string) => async () =>
	(await axiosAppClient.get<QuestionArrayType>(`${rootUrl}/question/get_question_party`)).data;

type QuestionArrayType = {
	Question: QuestionInfoResponseType[];
};

export const deleteQuestion = (domain: string) => async (questionId: number) =>
	await (
		await axiosAppClient.get<any>(`${domain}/admin/delete_question_normal?questionId=${questionId}`)
	).data;
