import { Stack } from '@mui/material';

export const FAQData = [
	{
		title: '바운스는 몇살부터 이용 가능한가요?',
		description: (
			<>
				바운스는 나이가 아닌 신장(키)으로 이용여부가 결정됩니다. <br /> 80cm/100cm/110cm 세가지
				조건으로 구분되며 센터 별로 적용 기준이 달라 방문을 원하시는 센터의 정보를 확인해주세요.
			</>
		),
	},
	{
		title: '성인도 이용 가능한가요?',
		description:
			'일부 센터에 한하여 성인 이용이 가능합니다.(가능 센터 : 대구, 거제, 삼성, 광주, 원주, 제주, 의왕, 하남, 천안센터)',
	},
	{
		title: '이용권과 보호자 입장권의 차이는 무엇인가요?',
		description:
			'이용권은 센터 내 시설을 자유롭게 이용 가능하며(일부 시설 제한), 보호자 입장권은 센터에 입장하여 시설 이용은 불가, 자녀의 이용을 관람할 수 있습니다.',
	},
	{
		title: '미끄럼방지 양말은 꼭 신어야 하나요?',
		description: (
			<>
				일반 양말 착용 후 시설을 이용하는 경우 굉장히 미끄러워 사고가 발생할 수 있습니다. 안전상의
				이유로 미끄럼방지 양말을 필수로 착용해야 합니다. <br /> 미끄럼방지 양말이 없으신 경우 이용권
				구매 시, 구매가 가능하며 타사 제품이어도 소지하신 경우 프론트에서 확인 후 입장이 가능합니다.
				<br /> (단, 바닥의 80% 이상 미끄럼방지 소재여야함)
			</>
		),
	},
	{
		title: '이용시간이 초과하면 어떻게 되나요?',
		description: '이용권별 시간이 초과되면 10분당 추가요금이 과금됩니다. (센터 별 상이)',
	},
	{
		title: '보호자가 꼭 동반 입장을 해야 하나요?',
		description:
			'통상적으로 100cm ~ 110cm 미만(센터 별 상이)의 아동이 이용하는 경우에는 안전의 이유로 아동 1명당 보호자 1인 필수 입장해야 하며, 보호자도 미끄럼방지 양말 착용 필수입니다.',
	},
	{
		title: '외부음식 반입 가능한가요?',
		description: '외부음식은 센터 내 반입이 불가합니다. 단, 이유식 또는 환자식의 경우 가능합니다.',
	},
	{
		title: '예약이 가능한가요?',
		description:
			'사전 예약은 불가하며, 현장에서 입장 신청 후 이용이 가능합니다. 대기가 있는 경우 입장 번호 부여 및 대기가 진행됩니다.',
	},
	{
		title: '운영시간과 운영요일은 어떻게 되나요?',
		description: '센터 별로 운영시간 및 휴무일이 다릅니다. 센터 안내 메뉴에서 꼭 확인해 주세요.',
	},
	{
		title: '단체 이용 시 할인이 가능한가요?',
		description:
			'단체의 경우 통상 15명 이상 이용 시, 날짜 협의 후 할인 금액이 결정됩니다. (문의 및 예약 필수)',
	},
	{
		title: '파티 프로그램이 있나요?',
		description:
			'일부 센터에서 생일파티/모임 등 파티 프로그램 진행이 가능합니다. (문의 및 예약 필수)',
	},
	{
		title: '회사 워크샵 프로그램은 있나요?',
		description:
			'일부 센터에서는 기업 전용 워크샵 프로그램을 직접 기획/구성하여 진행해 드립니다. (문의 및 예약 필수)',
	},
	{
		title: '바운스 이용 절차는 어떻게 되나요?',
		description: (
			<Stack spacing={1}>
				바운스 이용 절차는 다음과 같습니다. <br />
				<span>
					1. 센터 방문 후, 현장에 구비된 PC 또는 테블릿 또는 QR코드 입장 신청
					<br />
					2. 입장 신청 시 신청인(보호자) 정보 및 이용자 정보 입력, 이용 동의
					<br />
					3. 카카오톡 또는 문자로 입장 번호 전송
					<br />
					4. 호출 번호 확인 후 프론트에서 이용권 구매 및 결제
					<br />
					5. 입장 후 재밌게 놀기
				</span>
			</Stack>
		),
	},
	{
		title: '유모차 보관이 가능한가요?',
		description:
			'센터 내 유모차 반입은 불가하며, 매장 입구, 프론트 인근 또는 카페 인근에 보관은 가능하나 분실 시 책임은 지지 않습니다.',
	},
	{
		title: '분실물 발생 시 어떻게 하나요?',
		description:
			'분실물 습득 시, 프론트에서 보관하며 습득일로부터 30일 이후 폐기됩니다. 분실물 발생 시 각 센터로 연락 후 확인 바랍니다.',
	},
	{
		title: '센터 내 화장실이 있나요?',
		description: '대부분의 센터 내에 화장실이 있으나, 일부 센터의 경우 외부 화장실을 이용합니다. ',
	},
];
