import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Box, InputLabel, NativeSelect, Stack, Typography } from '@mui/material';
import { StyleFormControl } from '../../components/CommonStyled';
import { AddressMap } from '../../dummyData';
import { vaunceAppFormiks } from '../../utils/useFormik';

export interface IAddressPartProps {}
export const AddressPart: React.FC<IAddressPartProps> = ({}) => {
	const { useAddressFormik } = vaunceAppFormiks();
	const { isSubmitting, values, handleChange, isValid, handleSubmit, handleBlur } =
		useAddressFormik();

	return (
		<form onSubmit={handleSubmit}>
			<Stack spacing={4}>
				<Stack
					direction={'row'}
					spacing={2}>
					<StyleFormControl style={{ flex: 1 }}>
						<InputLabel variant='standard'>
							<Typography
								variant='h6'
								fontWeight={400}>
								주소
							</Typography>
						</InputLabel>
						<NativeSelect
							sx={{
								fontSize: '16px',
								fontWeight: 400,
							}}
							name='infoAddressNumber'
							value={values.infoAddressNumber}
							onChange={handleChange}
							onBlur={handleBlur}>
							{Object.keys(AddressMap).map((address) => (
								<option
									key={address}
									value={address}>
									{address}
								</option>
							))}
						</NativeSelect>
					</StyleFormControl>

					<StyleFormControl style={{ flex: 1 }}>
						<InputLabel variant='standard' />
						<NativeSelect
							sx={{
								fontSize: '16px',
								fontWeight: 400,
							}}
							name='infoAddressDetail'
							value={values.infoAddressDetail}
							onChange={handleChange}
							onBlur={handleBlur}>
							{AddressMap[values.infoAddressNumber] &&
								AddressMap[values.infoAddressNumber].map((add) => (
									<option
										key={add}
										value={add}>
										{add}
									</option>
								))}
						</NativeSelect>
					</StyleFormControl>
				</Stack>
				<Box>
					<RoundedSubmitButton
						disabled={!isValid}
						isSubmitting={isSubmitting}
						onClick={() => {
							handleSubmit();
						}}
						text={'주소 수정'}
						type={'submit'}
						sx={{ width: '100%' }}
					/>
				</Box>
			</Stack>
		</form>
	);
};
