import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { CenterCollectionsType, ChildListType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Box, InputLabel, NativeSelect, Stack, Typography, styled } from '@mui/material';
import { FieldArray, FormikProvider, FormikErrors } from 'formik';
import * as React from 'react';
import { StyleFormControl, StyleTextField } from '../../components/CommonStyled';
import { LoginTitleBox } from '../../components/LoginTitleBox';
import VaunceButton from '../../components/VaunceButton';
import { vaunceAppFormiks } from '../../utils/useFormik';
import { LoginPageHeader } from '../Login/LoginPageHeader';
import { VaunceOptionButton } from '../../components/VaunceOptionButton';
import { whitespace } from '@kinderlabs-pos/shared-util';

export interface ISignupFifthStepProps {}
export const SignUpFifthStep: React.FC<ISignupFifthStepProps> = ({}) => {
	const centerCollections: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();

	const newTemplate = {
		birth: '',
		name: '',
		relation: 0,
		gender: 'M',
	};

	const { useSignupInfoTwoStepFormik } = vaunceAppFormiks();
	const formik = useSignupInfoTwoStepFormik();
	const { values, handleChange, handleSubmit, handleBlur, setFieldValue, errors, touched } = formik;

	return (
		<Stack height={'100%'}>
			<LoginPageHeader />
			<LoginTitleBox
				title='자녀 등록'
				subtitle='선택 입력 사항'
				showStep
				stepNum={3}
			/>

			<Stack overflow={'auto'}>
				<FormikProvider value={formik}>
					<FieldArray name='childList'>
						{({ insert, remove, push }) => (
							<>
								{values.childList.map((child, idx) => (
									<Stack
										key={child.id}
										width={'100%'}
										spacing={3}
										sx={
											values.childList.length > 1
												? { pb: 4, borderBottom: `1px dashed ${vaunceColors.lightPink}` }
												: { borderBottom: '' }
										}>
										<Stack>
											<Stack>
												<SingleRowBox
													marginTop={'1rem'}
													alignItems={'flex-end'}>
													<StyleTextField
														InputProps={{
															style: { fontSize: '16px', fontWeight: 500 },
														}}
														fullWidth
														label={<Typography variant='h6'>자녀 이름</Typography>}
														variant='standard'
														placeholder='이름을 입력하세요.'
														value={child.name}
														name={`childList[${idx}]name`}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													<VaunceButton
														sx={{ width: '20%', height: 38 }}
														onClick={() => {
															remove(idx);
														}}>
														삭제
													</VaunceButton>
												</SingleRowBox>
											</Stack>
										</Stack>

										<Stack>
											<Stack
												direction={'row'}
												alignItems={'flex-end'}>
												<StyleTextField
													InputProps={{
														style: { fontSize: '16px', fontWeight: 500 },
													}}
													fullWidth
													label={<Typography variant='h6'>생년월일(8자리)</Typography>}
													variant='standard'
													placeholder='예) 20230101'
													name={`childList[${idx}]birth`}
													onChange={handleChange}
													onBlur={handleBlur}
												/>

												<VaunceOptionButton
													options={child.gender !== 'M'}
													name={`childList.${idx}.gender`}
													value={'M'}
													onClick={handleChange}
													text={'남성'}
												/>
												<VaunceOptionButton
													options={child.gender !== 'F'}
													name={`childList.${idx}.gender`}
													value={'F'}
													onClick={handleChange}
													text={'여성'}
												/>
											</Stack>
											{errors.childList && (
												<Typography
													color={'primary'}
													textAlign={'left'}>
													{(errors.childList &&
														errors.childList[idx] &&
														(errors.childList[idx] as FormikErrors<ChildListType>).birth &&
														(errors.childList[idx] as FormikErrors<ChildListType>).birth) ||
														whitespace}
												</Typography>
											)}
										</Stack>
										<Stack>
											<InputLabel
												variant='standard'
												sx={{ fontWeight: 300 }}>
												{'관계'}
											</InputLabel>
											<NativeSelect
												sx={{ fontSize: '16px', fontWeight: 500 }}
												name={`childList.${idx}.relation`}
												value={child.relation}
												onChange={handleChange}
												onBlur={handleBlur}>
												<option value={0}> 선택 </option>
												<option value={1}> 아들 </option>
												<option value={2}> 딸 </option>
											</NativeSelect>
										</Stack>
									</Stack>
								))}

								<SingleRowBox>
									<VaunceButton
										sx={{ width: '100%', my: '3rem' }}
										onClick={() => {
											push(newTemplate);
										}}>
										+추가 자녀 등록
									</VaunceButton>
								</SingleRowBox>
							</>
						)}
					</FieldArray>

					<LoginTitleBox title='추가 정보' />

					<FieldArray name='centerIdList'>
						{({ insert, remove, push }) => (
							<>
								{values.centerIdList.map((center, idx) => (
									<Stack
										flexDirection={'row'}
										alignItems={'flex-end'}
										key={idx}
										marginBottom={'3rem'}>
										<StyleFormControl style={{ flex: 1 }}>
											<InputLabel
												sx={{ fontSize: '16px', fontWeight: 500 }}
												variant='standard'
												htmlFor='uncontrolled-native'>
												자주이용하는 센터(선택)
											</InputLabel>
											<NativeSelect
												sx={{ fontSize: '16px', fontWeight: 500 }}
												name={`centerIdList.${idx}`}
												value={center}
												onChange={handleChange}>
												<option value={'unSelected'}>{'선택'}</option>
												{centerCollections.map((centerColect, index) => (
													<option
														value={centerColect.centerId}
														key={index}>
														{centerColect.centerDisplay}
													</option>
												))}
											</NativeSelect>
										</StyleFormControl>
										<VaunceButton
											style={{ width: '20%' }}
											onClick={() => {
												remove(idx);
											}}>
											삭제
										</VaunceButton>
									</Stack>
								))}

								<VaunceButton
									sx={{ width: '100%', my: '3rem' }}
									onClick={() => {
										push(0);
									}}>
									+ 자주이용하는 센터
								</VaunceButton>
							</>
						)}
					</FieldArray>

					<SingleRowBox>
						<StyleTextField
							fullWidth
							label={<Typography variant='h6'>추천인 ID (선택)</Typography>}
							name={'recommender'}
							onChange={handleChange}
							variant='standard'
							placeholder='추천인 ID를 입력하세요.'
							style={{ marginBottom: '3rem', fontSize: '1.3em' }}
						/>
					</SingleRowBox>
					<RoundedButton
						sx={{ width: '100%', height: 50, mb: '3rem' }}
						onClick={() => {
							if (errors.childList) {
								let msg: any = '';
								for (const child of errors.childList) {
									if (child) {
										msg = msg + Object.values(child).join('\n') + '\n';
									}
								}
								alert(msg);
							}
							handleSubmit();
						}}>
						추가정보 저장
					</RoundedButton>
				</FormikProvider>
			</Stack>
		</Stack>
	);
};

const SingleRowBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
}));
