import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { Box, Skeleton, Stack, styled, Tab } from '@mui/material';
import React, { Suspense } from 'react';
import { CouponContentBox, ImageBox } from '../../components/CommonStyled';
import { PageHeader } from '../../components/PageHeader';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import { TabPanel } from '../VclubPage';
import { PremiumTab } from './PremiumTab';

export interface IVipclubPageProps {}
export const VipclubPage: React.FC<IVipclubPageProps> = ({}) => {
	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader title='바운스 프리미엄 클럽' />
			{/* 비었을 경우 */}
			<Suspense fallback={<PremiumTabSkeleton />}>
				<PremiumTab />
			</Suspense>
		</Stack>
	);
};

export const PremiumTabSkeleton = () => {
	return (
		<>
			<VauncePinkTabs variant='fullWidth'>
				<TabSkeleton />
				<TabSkeleton />
			</VauncePinkTabs>
			<TabPanel
				value={0}
				index={0}>
				<Stack
					height={'100%'}
					width={'100%'}
					sx={{ overflowY: 'auto' }}>
					<CouponSkeleton />
					<CouponSkeleton />
				</Stack>
			</TabPanel>
		</>
	);
};

const TabSkeleton = () => {
	return (
		<Tab
			sx={{ flex: 1 }}
			label={
				<Skeleton
					animation='wave'
					sx={{ height: 20, width: 70, bgcolor: vaunceAppColors.skeletonGray }}
				/>
			}
		/>
	);
};

export const CouponSkeleton = () => {
	return (
		<CouponContentBox sx={{ pt: '20px', padding: '10px', height: 150 }}>
			<ImageBox>
				<Skeleton
					animation='wave'
					sx={{ height: 100, width: 66, bgcolor: vaunceAppColors.skeletonGray }}
				/>
			</ImageBox>
			<Stack
				sx={{ width: 300 }}
				justifyContent={'flex-end'}>
				<Skeleton
					animation='wave'
					sx={{ height: 80, bgcolor: vaunceAppColors.skeletonGray }}
				/>
			</Stack>
		</CouponContentBox>
	);
};

export const PlusImageBox = styled(Box)(({ theme }) => ({
	width: '24px',
	height: '24px',
	paddingTop: '3px',
	paddingLeft: '5px',
	cursor: 'pointer',
	backgroundColor: `${theme.palette.primary.main}`,
	borderRadius: '50%',
}));
