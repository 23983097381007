import { vaunceAppColors, vaunceColors } from '@kinderlabs-pos/shared-const';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import goldImage from 'assets/vaunce/image/goldClass.png';
import mintImage from 'assets/vaunce/image/mintClass.png';
import onestopImage from 'assets/vaunce/image/onestop.png';
import pinkImage from 'assets/vaunce/image/pinkClass.png';
import warningImage from 'assets/vaunce/image/submitwarning.png';

import goldImage2x from 'assets/vaunce/image/goldClass@2x.png';
import mintImage2x from 'assets/vaunce/image/mintClass@2x.png';
import onestopImage2x from 'assets/vaunce/image/onestop@2x.png';
import pinkImage2x from 'assets/vaunce/image/pinkClass@2x.png';
import warningImage2x from 'assets/vaunce/image/submitwarning@2x.png';

import GrayBox from '../../components/GrayBox';

const basicBox: { title: string; text: string[] }[] = [
	{ title: '적립', text: ['결제 금액의 1% 적립'] },
	{ title: '사용', text: ['100포인트부터 사용 가능(100포인트 단위)'] },
	{ title: '쿠폰', text: ['신규 가입 할인쿠폰 지급', '회원 등급에 따라 쿠폰 추가 지급'] },
	{ title: '적립', text: ['멤버십 전용 이벤트 참여 및 프로모션 제공'] },
];

const gradeBox: {
	imgUrl: string;
	srcsetUrl: string;
	title: string;
	subtitle: string;
	description: string[];
	color: string;
}[] = [
	{
		imgUrl: `${mintImage}`,
		srcsetUrl: mintImage2x,
		title: 'MINT',
		subtitle: '신규 가입 시 기본 등급',
		description: ['기본 혜택 제공 ', '신규 가입 3천원 할인 쿠폰 지급', '멤버십 전용 이벤트 참여'],
		color: `${vaunceColors.mint}`,
	},
	{
		imgUrl: `${pinkImage}`,
		srcsetUrl: pinkImage2x,
		title: 'PINK',
		subtitle: '6개월간 4회 이상 방문 시 승급 가능',
		description: ['기본 혜택 제공', '50% 할인 쿠폰 2매 지급', '멤버십 전용 이벤트 참여'],
		color: `${vaunceColors.lightPink}`,
	},
	{
		imgUrl: `${goldImage}`,
		srcsetUrl: goldImage2x,
		title: 'GOLD',
		subtitle: '6개월간 10회 이상 방문 시 승급 가능',
		description: [
			'기본 혜택 제공',
			'승급 축하 2시간 무료 쿠폰 1매 지급',
			'50% 할인 쿠폰 2매 지급',
			'멤버십 전용 이벤트 참여',
		],
		color: `#F7A500`,
	},
];

const noticeContent: string[] = [
	'포인트는 현장 결제 금액 기준 적립',
	'쿠폰, 포인트, 타사에서 구매한 온라인 이용권 결제 금액 적립 제외',
	'포인트 유효기간 1년 (적립일로부터 1년 후 소멸)',
	'회원 등급은 승급 후 6개월 유지, 승급 후 6개월간 이용 내역으로 등급 재산정',
];
export interface IVaunceClubTabPageProps {}
export const VaunceClubTabPage: React.FC<IVaunceClubTabPageProps> = ({}) => (
	<Stack spacing={3}>
		<GrayBox>
			<Stack
				direction={'row'}
				spacing={1}
				alignItems={'center'}>
				<img
					src={onestopImage}
					srcSet={onestopImage2x}
					style={{ marginRight: '15px' }}
					width={64}
					height={64}
				/>
				<Typography variant='h3'>바운스 클럽에 가입하고 다양한 혜택을 누려보세요!</Typography>
			</Stack>
		</GrayBox>

		<Typography variant='h3'>기본 혜택</Typography>
		<Stack spacing={1}>
			{basicBox.map((item, idx) => (
				<Stack
					key={idx}
					direction={'row'}
					spacing={3}>
					<Typography
						variant='h5'
						color={'primary'}
						fontWeight={500}
						flex={1}
						sx={{ textAlign: 'center' }}>
						{item.title}
					</Typography>
					<Stack flex={4}>
						{item.text.map((item, idx) => (
							<Typography
								key={idx}
								variant='h5'>
								{item}
							</Typography>
						))}
					</Stack>
				</Stack>
			))}
		</Stack>
		<Typography variant='h3'>등급별 혜택</Typography>
		<Stack>
			{gradeBox.map((item, idx) => (
				<Accordion key={idx}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon sx={{ color: `${vaunceAppColors.lightPink}` }} />}>
						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing={2}>
							<img
								src={item.imgUrl}
								srcSet={item.srcsetUrl}
								width={43}
								height={43}
							/>
							<Typography
								color={item.color}
								variant='h4'>
								{item.title}
							</Typography>
						</Stack>
					</AccordionSummary>
					<AccordionDetails>
						<Stack sx={{ pl: 7 }}>
							<Typography
								variant='h4'
								color={item.color}
								sx={{ mb: 1 }}>
								{item.subtitle}
							</Typography>
							{item.description.map((content, idx) => (
								<Typography
									key={idx}
									variant='h5'>
									{content}
								</Typography>
							))}
						</Stack>
					</AccordionDetails>
				</Accordion>
			))}
		</Stack>

		<GrayBox display={'flex'}>
			<img
				src={warningImage}
				width={32}
				height={32}
			/>
			<Stack
				direction={'column'}
				ml={2}>
				<Typography
					variant='h3'
					color='primary'
					mb={1}>
					유의사항
				</Typography>
				{noticeContent.map((content, idx) => (
					<ul
						key={idx}
						style={{ padding: 0, margin: 0 }}>
						<li style={{ fontSize: '12px', fontWeight: 400 }}>{content}</li>
					</ul>
				))}
			</Stack>
		</GrayBox>
	</Stack>
);
