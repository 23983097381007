import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { techLabsCUID } from '@kinderlabs-pos/shared-const';
import {
	TeckLabsEntranceLog,
	TeckLabsFavoritCenterLog,
	TeckLabsPageRouteLog,
	TeckLabsTicketPurchaseLog,
} from '@kinderlabs-pos/shared-data-type';

export const pageLouteLog = async (page: string) => {
	const data: TeckLabsPageRouteLog = {
		cuid: techLabsCUID,
		type: 'click',
		page: page,
		pcId: getPCID() ?? '',
		device: 'MW',
		sessionId: getSessionID() ?? '',
	};
	await VaunceAppApis.postTechLabsLogging(data);
};

export const purchaseLog = async (center: number, type: '다회권' | '정기권') => {
	const data: TeckLabsTicketPurchaseLog = {
		cuid: techLabsCUID,
		type: 'purchase',
		center: center,
		class: type,
		pcId: getPCID() ?? '',
		device: 'MW',
		sessionId: getSessionID() ?? '',
	};
	await VaunceAppApis.postTechLabsLogging(data);
};

export const entranceLog = async (centerId: number) => {
	const data: TeckLabsEntranceLog = {
		cuid: techLabsCUID,
		type: 'entrance',
		center: centerId,
		pcId: getPCID() ?? '',
		device: 'MW',
		sessionId: getSessionID() ?? '',
	};
	await VaunceAppApis.postTechLabsLogging(data);
};

export const favoritCenterLog = async (centerId: number) => {
	const data: TeckLabsFavoritCenterLog = {
		cuid: techLabsCUID,
		type: 'like',
		center: centerId,
		pcId: getPCID() ?? '',
		device: 'MW',
		sessionId: getSessionID() ?? '',
	};
	await VaunceAppApis.postTechLabsLogging(data);
};

export const getPCID = () => {
	if (localStorage.getItem('pcid')) {
		return localStorage.getItem('pcid');
	} else {
		localStorage.setItem('pcid', generatePCID());
		return localStorage.getItem('pcid');
	}
};

export const getSessionID = () => {
	if (localStorage.getItem('sessionId')) {
		return localStorage.getItem('sessionId');
	} else {
		localStorage.setItem('sessionId', generateSessionID());
		return localStorage.getItem('sessionId');
	}
};

const generatePCID = (): string => {
	const timestamp = new Date().getTime();
	const randomDigits = Math.floor(100000 + Math.random() * 900000);
	return `${timestamp}${randomDigits}`;
};

export const generateSessionID = (): string => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';

	for (let i = 0; i < 10; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters.charAt(randomIndex);
	}
	return result;
};
