import { SearchOutlined } from '@ant-design/icons';
import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { CenterCollectionsType, NoticeResponsDataType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { NoticeInfoDialog } from './NoticeInfoDialog';
import { SelectCenterDialog } from './SelectCenterDialog';

export interface INoticePageProps {}
export const NoticeTabPage: React.FC<INoticePageProps> = ({}) => {
	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();

	const selectCenterId = useAtomValue(VaunceState.selectCenterId);

	const [pickedCenter, setPick] = useState<number | string>(selectCenterId ?? 'unSelected');
	const [searchKeyWord, setSearchKeyWord] = useState<string>('');

	const searchOptions = useAtomValue(VaunceState.searchOptions);

	const [noticeList, setNoticeList] = useState<NoticeResponsDataType[]>([]);

	const getNoticeData = async () => {
		const data = await VaunceAppApis.getAllNoticeList({
			...searchOptions,
			searchKeyword: searchKeyWord,
		});
		if (data) {
			setNoticeList(data.data);
		}
	};

	const searchNotice = async () => {
		const noticeRes = await VaunceAppApis.getAllNoticeList({
			...searchOptions,
			searchKeyword: searchKeyWord,
			...(typeof pickedCenter !== 'string' && {
				centerCode: pickedCenter as number,
			}),
		});
		if (noticeRes) {
			setNoticeList(noticeRes.data);
		}
	};

	useEffect(() => {
		getNoticeData();
		searchNotice();
	}, []);

	return (
		<Stack>
			<Stack
				direction={'row'}
				alignItems={'end'}
				spacing={2}>
				<WithDialog
					dialog={(open, closeDialog) => (
						<SelectCenterDialog
							open={open}
							closeDialog={closeDialog}
							handleClick={async (val) => {
								setPick(val);

								const noticeRes = await VaunceAppApis.getAllNoticeList({
									...searchOptions,
									...(typeof val !== 'string' && {
										centerCode: val as number,
									}),
								});

								if (noticeRes) {
									setNoticeList(noticeRes.data);
								}
							}}
						/>
					)}>
					{(openDialog) => (
						<TextField
							select
							sx={{ fontSize: '16px', flex: 1, pt: 2 }}
							fullWidth
							type={'text'}
							variant='standard'
							value={pickedCenter}
							InputProps={{
								readOnly: true,
							}}
							onClick={openDialog}>
							<option value={'unSelected'}>
								<Typography
									variant='h4'
									fontWeight={400}
									sx={{ opacity: 0.6 }}>
									전체
								</Typography>
							</option>
							{centerCollectionData.map((item, idx) => (
								<option
									key={idx}
									value={item.centerId}>
									<Typography
										variant='h4'
										fontWeight={400}>
										{item.centerDisplay}
									</Typography>
								</option>
							))}
						</TextField>
					)}
				</WithDialog>
				<TextField
					sx={{ fontSize: '16px', flex: 1 }}
					fullWidth
					type={'text'}
					variant='standard'
					placeholder={'검색어를 입력하여주세요'}
					value={searchKeyWord}
					onChange={(e) => {
						setSearchKeyWord(e.target.value as string);
					}}
					InputProps={{
						endAdornment: (
							<IconButton
								onClick={() => {
									searchNotice();
								}}>
								<SearchOutlined />
							</IconButton>
						),
					}}
				/>
			</Stack>
			<Stack>
				{noticeList.length > 0 &&
					noticeList.map((item) => <NoticeInfoDialogBtn centerData={item} />)}
			</Stack>
		</Stack>
	);
};

interface NoticeInfoDialogProps {
	centerData: NoticeResponsDataType;
}

const NoticeInfoDialogBtn: React.FC<NoticeInfoDialogProps> = ({ centerData }) => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<NoticeInfoDialog
					open={open}
					onClose={closeDialog}
					noticeId={centerData.id}
				/>
			)}>
			{(openDialog) => (
				<Stack>
					<Stack
						direction={'row'}
						sx={{
							p: 3,
							borderBottom: `1px solid ${vaunceColors.lightGray}`,
						}}
						alignItems={'center'}
						onClick={openDialog}>
						<Stack
							direction={'row'}
							spacing={1}
							mr={'auto'}>
							<Stack spacing={1}>
								<Typography
									variant='h4'
									fontWeight={500}>
									{centerData.title}
								</Typography>
								<Stack
									direction={'row'}
									spacing={1}>
									<Typography
										color={'primary'}
										variant='h6'
										fontWeight={400}>
										{centerData.centerName}
									</Typography>
									<Typography
										variant='h6'
										fontWeight={400}>
										|
									</Typography>
									<Typography
										variant='h6'
										fontWeight={400}>
										{centerData.createDate}
									</Typography>
								</Stack>
							</Stack>
						</Stack>

						<KeyboardArrowRightIcon
							sx={{ color: 'rgba(0, 0, 0, 0.60)', width: '24px', height: '24px' }}
						/>
					</Stack>
				</Stack>
			)}
		</WithDialog>
	);
};
