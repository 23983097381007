import { vaunceAppColors } from '@kinderlabs-pos/shared-const';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { Box, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { EmptyContent } from '../../components/EmptyContent';
import { PageHeader } from '../../components/PageHeader';

export interface IHistoryPageProps {}
const HistoryPage: React.FC<IHistoryPageProps> = ({}) => {
	const centerHistory = VaunceQueryState.useCenterHistoryQuery();

	return (
		<Stack
			direction={'column'}
			height={'100%'}>
			<PageHeader title='센터이용내역' />
			<ContentBox>
				{centerHistory.payHistoryList.length === 0 && (
					<EmptyContent title={'이용 내역이 없습니다.'} />
				)}

				{/* //이용 내역 있는 경우 */}
				{centerHistory.payHistoryList.length > 0 && (
					<Box sx={{ width: '100%', height: '100%' }}>
						<CenterInfoBox>
							<CenterInfoInnerBox>
								<Typography variant='h2'>{centerHistory.total}</Typography>
								<Typography
									variant='h6'
									fontWeight={400}>
									총 이용
								</Typography>
								<Typography
									variant='h6'
									fontWeight={400}>
									횟수
								</Typography>
							</CenterInfoInnerBox>

							<CenterInfoInnerBox>
								<Typography variant='h1'>{centerHistory.monthlyTotal}</Typography>
								<Typography variant='h3'>최근 1개월간</Typography>
								<Typography
									variant='h6'
									fontWeight={400}>
									{' '}
									이용 횟수
								</Typography>
							</CenterInfoInnerBox>

							<CenterInfoInnerBox>
								<Typography variant='h2'>{centerHistory.recentlyCenterName}</Typography>
								<Typography
									variant='h6'
									fontWeight={400}>
									최근 이용
								</Typography>
								<Typography
									variant='h6'
									fontWeight={400}>
									센터
								</Typography>
							</CenterInfoInnerBox>
						</CenterInfoBox>
						<Box
							sx={{ height: '70%' }}
							overflow={'auto'}>
							{centerHistory.payHistoryList.map((center, idx) => (
								<CenterInnerBox key={idx}>
									<Box
										display={'flex'}
										flexDirection={'column'}
										marginRight={'auto'}>
										<Typography variant='h5'>{center.centerDisplay}</Typography>
										<Box display={'flex'}>
											<Typography
												variant='h6'
												fontWeight={400}
												marginRight={'0.5rem'}>
												{center.date}
											</Typography>
											{center.type === 1 && (
												<Typography
													variant='h6'
													fontWeight={400}>
													결제 완료
												</Typography>
											)}
											{center.type === 2 && (
												<Typography
													variant='h6'
													fontWeight={400}
													color='primary'>
													결제 취소
												</Typography>
											)}
										</Box>
									</Box>

									<Typography variant='h4'>{center.amount.toLocaleString()}원</Typography>
								</CenterInnerBox>
							))}
						</Box>
					</Box>
				)}
			</ContentBox>
		</Stack>
	);
};

export default HistoryPage;

const ContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
}));

const CenterInfoBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	borderTop: '1px solid rgba(0, 0, 0, 0.10)',
	textAlign: 'center',
	margin: '1rem 0 1rem',
	alignItems: 'center',
}));

const CenterInfoInnerBox = styled(Box)(({ theme }) => ({
	width: '33.3%',
	margin: '1rem 0 1rem',
}));

const CenterInnerBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	padding: '9px 15px 11px',
	borderTop: '1px solid rgba(0, 0, 0, 0.40)',
}));
