import {
	Box,
	Divider,
	Drawer,
	List,
	ListItemButton,
	Stack,
	styled,
	Typography,
} from '@mui/material';
import modalClose from 'assets/vaunce/image/modalClose.png';
import moLogo from 'assets/vaunce/image/mologo.png';
import moLogo2x from 'assets/vaunce/image/mologo@2x.png';

import awardsLogo1 from 'assets/vaunce/image/awards_logo1.png';
import awardsLogo2 from 'assets/vaunce/image/awards_logo2.png';
import awardsLogo3 from 'assets/vaunce/image/awards_logo3.png';
import awardsLogo4 from 'assets/vaunce/image/awards_logo4.png';
import { useVaunceAppNavigationWithAuthGuard } from '../../utils/useVaunceAppNavigationWithAuthGuard';
import { bottomMenuItems, upperMenuItems } from './DrawerMenuItem';
import { SidebarDrawerHeader } from './SidebarDrawerHeader';
export interface ISidebarDrawerProps {
	opened: boolean;
	toggleDrawer: () => void;
}
export const SidebarDrawer: React.FC<ISidebarDrawerProps> = ({ opened, toggleDrawer }) => {
	const { navigateWithLoginGuard, isLoginned } = useVaunceAppNavigationWithAuthGuard();

	const contentList = [
		'법인명 : (주)바운스',
		'대표자 : 류호준',
		'사업자등록번호: 631-86-00114',
		'본사 : 서울 강남구 봉은사로 524, 인터컨티넨탈 코엑스 B1F 스파크플러스 B130호',
		'대표전화 : 1599-9583',
	];
	return (
		<Drawer
			anchor={'right'}
			open={opened}
			onClose={toggleDrawer}
			PaperProps={{
				sx: {
					height: '100vh',
					width: '90%',
					maxWidth: '90%',
					borderTopLeftRadius: 12,
					borderBottomLeftRadius: 12,
					py: '25px',
					px: '20px',
				},
			}}>
			<Stack
				onClick={toggleDrawer}
				sx={{ height: '100%', overflowY: 'auto' }}>
				<Stack
					alignItems={'flex-end'}
					justifyContent={'center'}
					sx={{ mt: 5 }}
					mb={3}>
					<ModalCloseIcon />
				</Stack>

				<Stack
					direction={'row'}
					width={'100%'}>
					{isLoginned && <SidebarDrawerHeader />}
				</Stack>

				<Stack
					flex={1}
					sx={{ overflowY: 'auto' }}>
					<List>
						{upperMenuItems.map((menu, idx) => (
							<ListItemButton
								sx={{ paddingLeft: 0 }}
								key={idx}
								onClick={() => navigateWithLoginGuard(menu)}>
								<Stack
									direction={'row'}
									spacing={'5px'}
									alignItems={'center'}>
									<img
										src={menu.iconUrl}
										srcSet={menu.srcsetUrl}
										width={24}
										height={24}
									/>

									<Typography variant='h4'>{menu.label}</Typography>
								</Stack>
							</ListItemButton>
						))}
						<Divider
							component={'li'}
							sx={{ my: 2, borderColor: '#707070' }}
						/>
						{bottomMenuItems.map((menu, idx) => (
							<ListItemButton
								sx={{ paddingLeft: 0 }}
								key={idx}
								onClick={() => navigateWithLoginGuard(menu)}>
								<Stack
									direction={'row'}
									spacing={'10px'}
									alignItems={'center'}>
									<img
										src={menu.iconUrl}
										srcSet={menu.srcsetUrl}
										width={24}
										height={24}
									/>
									<Typography variant='h4'>{menu.label}</Typography>
								</Stack>
							</ListItemButton>
						))}
					</List>

					<Stack
						spacing={2}
						my={3}>
						<AwardsRow awardsInfo={awardsInfo} />
						<AwardsRow awardsInfo={awardsInfoSecond} />
					</Stack>

					<Box my={2}>
						<img
							src={moLogo}
							srcSet={moLogo2x}
							width={96}
							height={50}
						/>
					</Box>

					<Stack alignItems={'flex-start'}>
						{contentList.map((content) => (
							<Typography
								variant='h5'
								sx={{ opacity: 0.6 }}
								fontWeight={500}>
								{content}
							</Typography>
						))}
					</Stack>
				</Stack>
			</Stack>
		</Drawer>
	);
};

const awardsInfo = [
	{ img: awardsLogo1, title: 'IF DESIGN AWARD 2016 WINNER', width: 30, height: 30, mr: 13 },
	{
		img: awardsLogo3,
		title: 'A DESIGN AWARD SILVER',
		width: 22,
		height: 33,
		mr: 20,
	},
];
const awardsInfoSecond = [
	{ img: awardsLogo2, title: 'REDDOT AWARD 2016 BRAND WINNER', width: 36, height: 36, mr: 9 },
	{ img: awardsLogo4, title: '2019 GERMAN DESIGN AWARD', width: 40, height: 26, mr: 10 },
];

interface IAwardsRow {
	awardsInfo: {
		img: string;
		title: string;
		width: number;
		height: number;
		mr: number;
	}[];
}

const AwardsRow: React.FC<IAwardsRow> = ({ awardsInfo }) => {
	return (
		<Stack
			width={'100%'}
			spacing={2}>
			<Stack
				direction={'row'}
				spacing={1}>
				{awardsInfo.map((item) => AwardsInner(item))}
			</Stack>
		</Stack>
	);
};

function AwardsInner(item: {
	img: string;
	title: string;
	width: number;
	height: number;
	mr: number;
}): JSX.Element {
	return (
		<Stack
			flex={1}
			direction={'row'}
			spacing={1}
			alignItems={'center'}>
			<Stack>
				<Box
					width={40}
					display={'flex'}
					justifyContent={'center'}>
					<img
						src={item.img}
						width={item.width}
						height={item.height}
						style={{
							marginRight: item.mr,
						}}
					/>
				</Box>
			</Stack>
			<Typography
				sx={{
					// fontFamily: 'Campton',
					fontSize: '8px',
					fontWeight: 400,
				}}
				color='#707070'>
				{item.title}
			</Typography>
		</Stack>
	);
}

const ModalCloseIcon = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${modalClose})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: 24,
	height: 24,
}));

const ListItemLabelIcon = styled(Box)<{ icon: string }>(({ theme, icon }) => ({
	backgroundImage: `url(${icon})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: 24,
	height: 24,
}));
