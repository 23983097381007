import { axiosAppClient, axiosAppPostClient } from '@kinderlabs-pos/apis/axios';
import {
	postPayResultData,
	PremiumCollection,
	PremiumListType,
	PremiumOneListType,
	premiumOneRefundInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const getPremiumAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<PremiumListType>(`${rootUrl}/users/premium`)
	).data;

export const postPremiumCollectionAsync = (rootUrl: string) => async (premiumNumber: string) =>
	await (
		await axiosAppPostClient.post<PremiumCollection>(`${rootUrl}/premium_collection?premiumType=${premiumNumber}
    `)
	).data;

export const addPremiumSeanAsync = (rootUrl: string) => async (userFormData: FormData) =>
	await (
		await axiosAppClient.post<any>(`${rootUrl}/users/season`, userFormData)
	).data;

export const postNicePayAsync = (domain: string) => async (payFormData: FormData) =>
	await (
		await axiosAppPostClient.post<postPayResultData>(`${domain}/nicepay/request`, payFormData)
	).data;

export const postNicePayOneAsync = (domain: string) => async (payFormData: string) =>
	await (
		await axiosAppPostClient.post<postPayResultData>(
			`${domain}/nicepay/request_one?One=${payFormData}`
		)
	).data;

export const getPremiumOneAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<PremiumOneListType>(`${rootUrl}/users/premium_one`)
	).data;

export const getPremiumOneRefundInfoAsync = (rootUrl: string) => async (orderNum: string) =>
	await (
		await axiosAppClient.get<premiumOneRefundInfoType>(
			`${rootUrl}/users/premium_one_refund_info?orderNum=${orderNum}`
		)
	).data;

export const postPremiumOneNicePayCancelAsync =
	(domain: string) => async (orderNum: string, cancelAmt: number) =>
		await (
			await axiosAppPostClient.post<any>(
				`${domain}/users/premium_one_refund?orderNum=${orderNum}&cancelAmt=${cancelAmt}`
			)
		).data;
