import { PremiumCmtType, PremiumMutType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { RoundedButton } from '@kinderlabs-pos/ui-components';
import { Stack, Tab, Typography } from '@mui/material';
import qrImage from 'assets/vaunce/image/iconqr.png';
import plusImage from 'assets/vaunce/image/magnifyplus.png';
import plusImage2x from 'assets/vaunce/image/magnifyplus@2x.png';

import registerImage from 'assets/vaunce/image/register.png';
import registerImage2x from 'assets/vaunce/image/register@2x.png';

import unRegisterQrImage from 'assets/vaunce/image/unregisteredQR.png';
import { useSetAtom } from 'jotai';
import React, { ReactNode } from 'react';
import { PlusImageBox } from '.';
import { CouponContentBox, CouponDescBox, ImageBox } from '../../components/CommonStyled';
import { EmptyContent } from '../../components/EmptyContent';
import QrImageBadge from '../../components/QrImageBadge';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import { useVaunceAppRouter } from '../routes';
import { CmtTicketQrDialog } from './dialog/CmtTicketQrDialog';
import { MultiTicketQrDialog } from './dialog/MultiTicketQrDialog';
import { PremiumTabPanel } from './PremiumTabPanel';

export interface IPremiumTabProps {}
export const PremiumTab: React.FC<IPremiumTabProps> = ({}) => {
	const { navigateOneStop, navigateMultiUseTickets, navigateCommutationTickets } =
		useVaunceAppRouter();
	const premium = VaunceQueryState.usePremiumQuery();

	const [value, setValue] = React.useState<number>(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const ticketName = (ticketName: string) => {
		return ticketName.replace('티켓', '').replace(/_/g, ' ');
	};

	return (
		<>
			{premium.multi.length === 0 && premium.season.length === 0 ? (
				<>
					<EmptyContent title='바운스 프리미엄 클럽 구매내역이 없습니다.' />
					<RoundedButton onClick={navigateOneStop}>구매하기</RoundedButton>
				</>
			) : (
				<Stack
					height={'100%'}
					width={'100%'}
					sx={{ overflowY: 'auto' }}>
					<VauncePinkTabs
						data-cy='premiumTabsInVipClub'
						value={value}
						onChange={handleChange}
						variant='fullWidth'>
						<Tab label='다회권' />
						<Tab label='정기 회원권' />
					</VauncePinkTabs>

					{/* 다회권 */}
					<PremiumTabPanel
						value={value}
						index={0}
						isEmpty={premium.multi.length === 0}
						EmptyContent={<EmptyTicket onClick={navigateMultiUseTickets} />}
						Content={
							<>
								{premium.multi.map((ticket, idx) => (
									<MutTicketList
										ticket={ticket}
										getTicketName={ticketName}
									/>
								))}
								<RoundedButton
									sx={{ width: '100%', height: 58, marginTop: 'auto' }}
									onClick={navigateMultiUseTickets}>
									추가 구매 하기
								</RoundedButton>
							</>
						}
					/>

					<PremiumTabPanel
						value={value}
						index={1}
						isEmpty={premium.season.length === 0}
						EmptyContent={<EmptyTicket onClick={navigateCommutationTickets} />}
						Content={
							<>
								{premium.season.map((ticket, idx) => (
									<CmtTicketList
										ticket={ticket}
										getTicketName={ticketName}
									/>
								))}
								<RoundedButton
									sx={{ width: '100%', height: 58, marginTop: 'auto' }}
									onClick={navigateCommutationTickets}>
									추가 구매 하기
								</RoundedButton>
							</>
						}
					/>
				</Stack>
			)}
		</>
	);
};

interface EmptyTicketProps {
	onClick: () => void;
}
const EmptyTicket: React.FC<EmptyTicketProps> = ({ onClick }) => {
	return (
		<Stack sx={{ mt: 3 }}>
			<EmptyContent title={'구매된 티켓이 없습니다.'} />
			<RoundedButton
				sx={{ width: '100%', height: 58, marginTop: 'auto' }}
				onClick={onClick}>
				구매 하기
			</RoundedButton>
		</Stack>
	);
};

interface MutTicketListProps {
	ticket: PremiumMutType;
	getTicketName: (ticketName: string) => string;
}

const MutTicketList: React.FC<MutTicketListProps> = ({ ticket, getTicketName }) => {
	const isExpired = (ticket: PremiumMutType) => {
		const today = new Date();
		const expirationDate = new Date(ticket.expiredAt);
		expirationDate.setDate(expirationDate.getDate() + 1); // 00:00시로 나와있을 경우를 대비하여 1일 추가
		console.log('today', today);
		console.log('expirationDate', expirationDate);
		return expirationDate < today;
	};

	return (
		<TicketWrapper options={ticket.status === 'AVAILABLE' && !isExpired(ticket)}>
			<Stack mr={'2rem'}>
				<WithDialog
					dialog={(open, closeDialog) => (
						<MultiTicketQrDialog
							open={open}
							onClose={closeDialog}
							ticket={ticket}
						/>
					)}>
					{(openDialog) => (
						<QrImageBadge
							qrImage={qrImage}
							imageSize={'sm'}
							sx={{ cursor: 'pointer' }}
							badgeContent={
								ticket.status === 'AVAILABLE' ? (
									<PlusImageBox>
										<img
											src={plusImage}
											srcSet={plusImage2x}
											width={14}
											height={14}
										/>
									</PlusImageBox>
								) : (
									<></>
								)
							}
							onClick={() => {
								ticket.status === 'AVAILABLE' && openDialog();
							}}
						/>
					)}
				</WithDialog>

				{ticket.status === 'EXPIRED' && (
					<Typography
						textAlign={'center'}
						color={'primary'}
						variant='h5'>
						기간만료
					</Typography>
				)}
			</Stack>
			<CouponDescBox sx={{ mr: 'auto', width: '100%' }}>
				<Stack
					direction={'row'}
					justifyContent={'flex-end'}
					alignItems={'center'}>
					{ticket.status === 'EXPIRED' ? (
						<Typography
							variant='h5'
							fontWeight={'700'}
							color='primary'>
							기간만료
						</Typography>
					) : (
						<>
							<Typography variant='h5'>남은횟수 </Typography>
							<Typography
								variant='h1'
								color='primary'>
								{ticket.remainderTicketCount}
							</Typography>
						</>
					)}
				</Stack>

				<Typography
					variant='h5'
					fontWeight={500}
					color='primary'>
					{getTicketName(ticket.centerName)}
				</Typography>
				<Typography
					variant='h5'
					fontWeight={500}>
					유효 기간: {ticket.expiredAt}
				</Typography>
				<Stack
					display={'flex'}
					flexDirection={'row'}>
					<Typography
						variant='h5'
						fontWeight={500}
						marginRight={'2rem'}>
						총 횟수: {ticket.totalTicketCount}회
					</Typography>
					<Typography
						variant='h5'
						fontWeight={500}>
						사용횟수: {ticket.usedTicketCount}회
					</Typography>
				</Stack>
			</CouponDescBox>
		</TicketWrapper>
	);
};

interface CmtTicketListProps {
	ticket: PremiumCmtType;
	getTicketName: (ticketName: string) => string;
}

const CmtTicketList: React.FC<CmtTicketListProps> = ({ ticket, getTicketName }) => {
	const getCmtQrCodeBottomLabel = (ticket: PremiumMutType) => {
		switch (ticket.status) {
			case 'AVAILABLE':
				return '';
			case 'UNAVAILABLE':
				return (
					<>
						<CmtTicketStatus text={'금일 사용'} />
						<CmtTicketStatus text={'완료'} />
					</>
				);
			case 'EXPIRED':
				return '기간 만료';
			case 'NOT_REGISTERED':
				return (
					<>
						<CmtTicketStatus text={'사용자'} />
						<CmtTicketStatus text={'등록하기'} />
					</>
				);
			default:
				return '';
		}
	};

	const CmtTicketStatus: React.FC<{ text: string }> = ({ text }) => {
		return (
			<Typography
				variant='h5'
				fontWeight={500}>
				{text}
			</Typography>
		);
	};

	// 1 : 빈 값
	// 2 : '금일 사용 완료',
	// 3 : '기간 만료',
	// 4 : '사용자 미등록'
	const ticketStatusLabel = (ticket: PremiumMutType): string => {
		switch (ticket.status) {
			case 'AVAILABLE':
				return ticket.name ?? '';
			case 'UNAVAILABLE':
				return '금일 사용 완료';
			case 'EXPIRED':
				return '기간 만료';
			case 'NOT_REGISTERED':
				return '사용자 미등록';
			default:
				return '';
		}
	};

	const { navigateVauncePremiumVipClubRegister } = useVaunceAppRouter();
	const setSelectPgTicketId = useSetAtom(VaunceState.selectPgTicketId);

	const isExpired = (ticket: PremiumCmtType) => {
		const today = new Date();
		const expirationDate = new Date(ticket.expiredAt);
		expirationDate.setDate(expirationDate.getDate() + 1); // 00:00시로 나와있을 경우를 대비하여 1일 추가
		return expirationDate < today;
	};

	return (
		<TicketWrapper
			options={
				(ticket.status === 'AVAILABLE' && !isExpired(ticket)) || ticket.status === 'NOT_REGISTERED'
			}>
			<ImageBox>
				<>
					{ticket.status === 'AVAILABLE' ? (
						<WithDialog
							dialog={(open, closeDialog) => (
								<CmtTicketQrDialog
									open={open}
									onClose={closeDialog}
									ticket={ticket}
								/>
							)}>
							{(openDialog) => (
								<QrImageBadge
									qrImage={ticket.status === 'NOT_REGISTERED' ? unRegisterQrImage : qrImage}
									imageSize={'sm'}
									sx={{ cursor: 'pointer' }}
									badgeContent={
										ticket.status === 'AVAILABLE' || ticket.status === 'NOT_REGISTERED' ? (
											<PlusImageBox>
												<img
													height={14}
													width={14}
													data-cy='openCmtUserBtn'
													src={plusImage}
													srcSet={plusImage2x}
												/>
											</PlusImageBox>
										) : (
											<></>
										)
									}
									onClick={() => {
										if (ticket.status === 'AVAILABLE' || ticket.status === 'NOT_REGISTERED') {
											openDialog();
										}
									}}
								/>
							)}
						</WithDialog>
					) : (
						<QrImageBadge
							qrImage={ticket.status === 'NOT_REGISTERED' ? unRegisterQrImage : qrImage}
							imageSize={'sm'}
							sx={{ cursor: 'pointer' }}
							badgeContent={
								ticket.status === 'NOT_REGISTERED' ? (
									<PlusImageBox>
										<img
											data-cy='registerCmtUserBtn'
											src={registerImage}
											srcSet={registerImage2x}
											width={18}
											height={18}
										/>
									</PlusImageBox>
								) : (
									<></>
								)
							}
							onClick={() => {
								if (ticket.status === 'AVAILABLE' || ticket.status === 'NOT_REGISTERED') {
									setSelectPgTicketId(ticket.pgTicketId);
									navigateVauncePremiumVipClubRegister();
								}
							}}
						/>
					)}
				</>

				<Typography
					variant='h2'
					color='primary'
					textAlign={'center'}>
					{getCmtQrCodeBottomLabel(ticket)}
				</Typography>
			</ImageBox>

			<CouponDescBox
				sx={{
					mr: 'auto',
					justifyContent: 'center',
					width: '100%',
				}}>
				<Typography
					color='primary'
					fontSize={'24px'}
					fontWeight={700}
					textAlign={'right'}>
					{ticketStatusLabel(ticket)}
				</Typography>
				<Typography
					variant='h5'
					fontWeight={500}
					color='primary'>
					{getTicketName(ticket.centerName)}
				</Typography>

				<Stack
					direction={'row'}
					spacing={2}>
					{ticket.status !== 'NOT_REGISTERED' ? (
						<>
							<Typography variant='h5'>시작:{ticket.startAt}</Typography>
							<Typography variant='h5'>종료:{ticket.expiredAt}</Typography>
						</>
					) : (
						<Typography
							variant='h5'
							fontWeight={500}>
							사용자 등록 후 시작일이 표기됩니다.
						</Typography>
					)}
				</Stack>
			</CouponDescBox>
		</TicketWrapper>
	);
};

interface ITicketWrapper {
	options: boolean;
	children?: ReactNode;
}
export const TicketWrapper: React.FC<ITicketWrapper> = ({ options, children, ...others }) => {
	return (
		<CouponContentBox
			sx={
				options
					? { display: 'flex', padding: '10px', py: '20px' }
					: {
							display: 'flex',
							py: '20px',
							padding: '10px',
							bgcolor: '#E3E3E3',
					  }
			}
			{...others}>
			{children}
		</CouponContentBox>
	);
};
