import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Box, FormControl, FormControlLabel, Stack, styled, Typography } from '@mui/material';
import ticketImage from 'assets/vaunce/image/ticket_large.png';
import ticketImage2x from 'assets/vaunce/image/ticket_large@2x.png';

import React, { useEffect, useRef, useState } from 'react';
import { NicePayPage } from '../../components/NicePay';
import { PageHeader } from '../../components/PageHeader';
import { RoundBox } from '../../components/RoundBox';
import { VaunceCheckBox } from '../../components/VaunceCheckBox';
import { loggingEvent } from '../../utils/firebaseAnalytics';
import { AgreeAllInfomationBtn, PassSelectGroup } from '../MultiTicketPage';
import { cmtNoticeFirstContent, cmtNoticeSecondContent } from './CmtDialogDummyData';
import { purchaseLog } from '../../utils/TechLabsLogUtils';

export interface ICmtTicketPageProps {}
export const CmtTicketPage: React.FC<ICmtTicketPageProps> = ({}) => {
	const myInfo = VaunceQueryState.useMyInfoQuery();
	const commutationTicketInfoList = VaunceQueryState.useTicketInfoQuery('PREMIUM_SEASON');

	const [centerId, setCenterId] = useState<number>(0);
	const [priceDisplayName, setPriceDisplayName] = useState<string>('unSelected');
	const [count, setCount] = useState<number>(3);
	const [price, setPrice] = useState<string>('');
	const [webTicket, setWebTicket] = useState<string>('');

	const ref = useRef<any>();

	const [hashString, setHashString] = useState<string>('');
	const [ediDate, setEdiDate] = useState<string>('');
	const [payModel, setPayModel] = useState<any>({});

	const [checked, setChecked] = React.useState([false, false]);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, event.target.checked, event.target.checked]);
	};

	const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([event.target.checked, checked[1], checked[2]]);
	};

	const handleChangeSecond = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked([checked[0], event.target.checked, checked[2]]);
	};

	const children = (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<ContentBox>
				<CheckLabelBox
					sx={{ fontSize: '1.5em' }}
					label={<Typography variant='h5'>[필수] 정기 회원권 구매에 동의</Typography>}
					control={
						<VaunceCheckBox
							checked={checked[0]}
							onChange={handleChangeFirst}
							size={'small'}
						/>
					}
				/>
			</ContentBox>

			<ContentBox>
				<CheckLabelBox
					sx={{ fontSize: '1.5em' }}
					label={<Typography variant='h5'>[필수] 결제, 환불 및 사용 유의사항에 동의</Typography>}
					control={
						<VaunceCheckBox
							checked={checked[1]}
							onChange={handleChangeSecond}
							size={'small'}
						/>
					}
				/>
			</ContentBox>
		</Box>
	);

	function getCenterName(displayName: string) {
		return displayName.split('_')[3];
	}

	function getCenter(centerId: number) {
		const findCenter = commutationTicketInfoList.listCenters.find(
			(elem) => elem.centerId === centerId
		);
		const newCenter = `정기_${findCenter?.centerDisplay}_${count}개월 정기권_${getCenterName(
			priceDisplayName
		)}`;
		return newCenter;
	}

	const handlePay = async () => {
		if (!myInfo) throw Error();

		const payDataForm = new FormData();
		payDataForm.append('price', price.replace(',', ''));
		payDataForm.append('goodsName', getCenter(centerId));
		payDataForm.append('userId', myInfo.userId);
		payDataForm.append('webTicketMasterListIdx', webTicket);

		const data = await VaunceAppApis.postNicePayAsync(payDataForm);
		if (data) {
			setHashString(data.hashString);
			setEdiDate(data.ediDate);
			setPayModel(data.payRequestModel);
			purchaseLog(centerId, '정기권');
		}
	};

	useEffect(() => {
		if (hashString && ref.current) ref.current.submit();
	}, [ref, hashString]);

	return (
		<Stack
			height={'100%'}
			direction={'column'}>
			<PageHeader title='정기 회원권' />
			<Stack
				height={'100%'}
				overflow={'auto'}
				direction={'column'}
				textAlign={'center'}
				alignItems={'center'}>
				<Box marginBottom={'1.5rem'}>
					<img
						src={ticketImage}
						srcSet={ticketImage2x}
						width={70}
						height={70}
					/>
				</Box>

				<Typography
					variant='h3'
					color='primary'
					sx={{ mb: 1.5 }}>
					매일매일이 VAUNCE-DAY!
				</Typography>

				<Stack sx={{ mb: 2 }}>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={1}>
						<Typography
							variant='h2'
							color='primary'>
							매일
						</Typography>
						<Typography
							variant='h1'
							color={'primary'}
							style={{ fontSize: '2.5rem' }}>
							2
						</Typography>
						<Typography
							variant='h2'
							color='primary'>
							시간 이용권
						</Typography>
					</Stack>

					<Typography
						variant='h6'
						fontWeight={400}>
						영통센터는 2시간 30분 이용
					</Typography>
				</Stack>
				<RoundBox
					marginTop={'1em'}
					textAlign={'left'}>
					<Typography variant='h5'>
						- 구매 시, 지정 센터에서만 사용 가능합니다. (유효기간 3개월)
					</Typography>
					<Typography variant='h5'>
						- 연수/영종도/안양/용인/수원/반포센터는 {'<'}정기권 시즌2{'>'} 프로모션 상품으로
						적용되어 있습니다.
					</Typography>
					<Typography variant='h5'>
						- {'<'}정기권 시즌2{'>'} 프로모션은 이벤트 페이지를 참고해주세요.
					</Typography>
				</RoundBox>
				{/* <RoundBox sx={{ mt: '1rem' }}>
					<Typography
						variant='h5'
						color='primary'>
						- 구매 시, 지정된 센터에서만 사용가능 유효기간 3개월
					</Typography>
				</RoundBox> */}

				<Stack
					spacing={3}
					width={'100%'}>
					<PassSelectGroup
						centerId={centerId}
						priceDisplayName={priceDisplayName}
						count={count}
						setCenterId={setCenterId}
						setPriceDisplayName={setPriceDisplayName}
						setPrice={setPrice}
						setWebTicket={setWebTicket}
						data={commutationTicketInfoList}
						myInfo={myInfo}
					/>
					<FormControl
						fullWidth
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }}>
						<Stack direction={'row'}>
							<FormControlLabel
								label={<Typography variant='h5'>모두 동의합니다.</Typography>}
								control={
									<VaunceCheckBox
										checked={checked[0] && checked[1]}
										onChange={handleChangeAll}
									/>
								}
								sx={{ mr: 'auto', fontSize: '1.4em' }}
							/>
							<AgreeAllInfomationBtn
								noticeFirstContent={cmtNoticeFirstContent}
								noticeSecondContent={cmtNoticeSecondContent}
							/>
						</Stack>
						{children}
					</FormControl>
					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<Typography variant='h4'>결제 금액</Typography>
						{price && (
							<Typography
								fontWeight={500}
								fontSize={'26px'}>
								{price}원
							</Typography>
						)}
					</Stack>
					{/* TODO 추후 formik 적용필요 */}
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={2}
						p={1}>
						<RoundedSubmitButton
							fullWidth
							sx={{ height: 80 }}
							onClick={() => {
								handlePay();
								setIsSubmitting(true);
								setTimeout(() => {
									setIsSubmitting(false);
								}, 3000);
								loggingEvent('정기권 구매', 'submit', 'cmtTicketBuy');
							}}
							disabled={
								!(
									checked[0] &&
									checked[1] &&
									price &&
									centerId &&
									priceDisplayName !== 'unSelected'
								)
							}
							isSubmitting={isSubmitting}
							text={'구매하기'}
						/>
					</Stack>
					<>
						<NicePayPage
							payRef={ref}
							payModel={payModel}
							ediDate={ediDate}
							hashString={hashString}
						/>
					</>
				</Stack>
			</Stack>
		</Stack>
	);
};

const ContentBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
}));

const CheckLabelBox = styled(FormControlLabel)(({ theme }) => ({
	marginRight: 'auto',
}));
