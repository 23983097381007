import { axiosAppPostClient } from '@kinderlabs-pos/apis/axios';
import { AlarmListType } from '@kinderlabs-pos/shared-data-type';

export const getAlarmListAsync = (rootUrl : string) => async() => (
  await axiosAppPostClient.get<AlarmListType>(`${rootUrl}/arlim/getArlimList`)
).data

export const getOneAlarmReadAsync = (rootUrl : string) => async(id : number) => (
  await axiosAppPostClient.get(`${rootUrl}/arlim/one_read`, {
    params : {
      serverId : id
    }
  })
)

export const getAllAlarmReadAsync = (rootUrl : string) => async() => (
  await axiosAppPostClient.get(`${rootUrl}/arlim/all_read`)
).data

export const getAllAlarmDeleteAsync = (rootUrl : string) => async() => (
  await axiosAppPostClient.get(`${rootUrl}/arlim/all_delete`)
).data