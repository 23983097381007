import { whitespace } from '@kinderlabs-pos/shared-util';
import { RoundedSubmitButton } from '@kinderlabs-pos/ui-components';
import { Stack, TextField, Typography } from '@mui/material';
import { LoginHeaderTypograpy } from '../../components/LoginTitleBox';
import { vaunceAppFormiks } from '../../utils/useFormik';

import { loggingEvent } from 'apps/vaunce-app/src/utils/firebaseAnalytics';
import { LoginPageHeader } from './LoginPageHeader';
export interface IFindIdPage {}
export const FindIdPage: React.FC<IFindIdPage> = ({}) => {
	const { useFindIdFormik } = vaunceAppFormiks();
	const formik = useFindIdFormik();
	const {
		values,
		handleChange,
		isSubmitting,
		handleSubmit,
		errors,
		handleBlur,
		isValid,
		touched,
		setFieldValue,
	} = formik;

	return (
		<Stack direction={'column'}>
			<LoginPageHeader />
			<LoginHeaderTypograpy>아이디 찾기</LoginHeaderTypograpy>
			<TextField
				data-cy='findIdNameField'
				name='name'
				onChange={handleChange}
				onBlur={handleBlur}
				sx={{ mb: '3rem', fontSize: '16px' }}
				label={<Typography variant='h6'>이름</Typography>}
				variant='standard'
				placeholder={'이름을 입력하세요.'}
				helperText={
					<Typography color={'primary'}>{(touched.name && errors.name) || whitespace}</Typography>
				}
			/>
			<TextField
				data-cy='findIdBirthField'
				name='birth'
				onChange={handleChange}
				onBlur={handleBlur}
				sx={{ mb: '3rem', fontSize: '16px' }}
				label={<Typography variant='h6'>생년월일</Typography>}
				variant='standard'
				placeholder={'생년월일 8자리를 입력하세요.'}
				helperText={
					<Typography color={'primary'}>{(touched.birth && errors.birth) || whitespace}</Typography>
				}
			/>
			<TextField
				data-cy='findIdPhoneNumField'
				name='phoneNum'
				onChange={handleChange}
				onBlur={handleBlur}
				sx={{ mb: '3rem', fontSize: '16px' }}
				label={<Typography variant='h6'>휴대전화번호</Typography>}
				variant='standard'
				placeholder={'(-)없이 휴대전화 번호를 입력하세요.'}
				helperText={
					<Typography color={'primary'}>
						{(touched.phoneNum && errors.phoneNum) || whitespace}
					</Typography>
				}
			/>
			<Stack direction={'row'}>
				<RoundedSubmitButton
					data-cy='findIdBtn'
					sx={{ height: 58 }}
					disabled={!isValid}
					isSubmitting={isSubmitting}
					onClick={() => {
						handleSubmit();
						loggingEvent('아이디 찾기', 'login', 'findId');
					}}
					text={'아이디 찾기'}
				/>
			</Stack>
		</Stack>
	);
};
