import { VaunceAppApis } from '@kinderlabs-pos/apis/vaunce';
import { useFrameworkFormik } from '@kinderlabs-pos/frameworks/forms';
import {
	AvailableUserType,
	CenterCollectionsType,
	ChildListType,
	FacilityReservationInfo,
	NormalQuestionType,
	QuestionFirstType,
	SignupInfoFirstType,
} from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState, VaunceState } from '@kinderlabs-pos/state';
import { useFormik } from 'formik';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import qs from 'qs';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useVaunceAppRouter } from '../pages/routes';
import {
	birthdayYup,
	couponYup,
	nameYup,
	requiredStringYup,
	telephoneYup,
	ValidErrorsMsg,
} from '../yups/yups';
import { AddressMap } from '../dummyData';

interface PasswordType {
	pwd: string;
	newPwd: string;
	confirmPwd: string;
}
interface FindPasswordType extends FindInfoType {
	id: string;
	reception: 'phone' | 'email';
}
interface FindIdType extends FindInfoType {
	name: string;
}
interface FindInfoType {
	birth: string;
	phoneNum: string;
}
export const vaunceAppFormiks = () => {
	const navigate = useNavigate();
	const [infos, setInfos] = useAtom(VaunceState.facilityInfoAtom);
	const appliedInfo = useAtomValue(VaunceState.partyQuestionRequestInfo);
	const setUsageId = useSetAtom(VaunceState.usageId);

	const { navigateHomepage, navigateNewRegisterStepTwo, navigateSignUpStepFive } =
		useVaunceAppRouter();

	const useFindIdFormik = () => {
		const setFindUserInfo = useSetAtom(VaunceState.findUserInfo);
		const { navigateNotFoundId, navigateFindIdStepTwo } = useVaunceAppRouter();

		const validationSchema = Yup.object().shape({
			name: requiredStringYup('이름 입력은 필수입니다.'),
			birth: Yup.string()
				.required('생년월일은 필수 입력사항 입니다.')
				.matches(/^\d{8}$/, '올바른 생년월일 형식이 아닙니다. 예: 20230101'),
			phoneNum: Yup.string()
				.required('휴대폰번호는 필수 입력사항 입니다.')
				.matches(/^[0-1][0-9]+$/, '올바른 휴대폰 번호 형식이 아닙니다. 예: 01012345678'),
		});

		return useFormik<FindIdType>({
			initialValues: {
				name: '',
				birth: '',
				phoneNum: '',
			},
			validateOnMount: true,
			enableReinitialize: true,
			validationSchema: validationSchema,
			onSubmit: async (values) => {
				const data = await VaunceAppApis.addFindIdsync({
					findidName: values.name,
					findidPhone: values.phoneNum,
					findidBirth: values.birth,
				});

				if (data) {
					if (data.success === true) {
						if (data.findId && data.userName)
							setFindUserInfo({ findId: data.findId, userName: data.userName });
						await navigateFindIdStepTwo();
					} else {
						//실패 페이지로 가도록
						await navigateNotFoundId();
					}
				}
			},
		});
	};
	const usePasswordFormik = () => {
		const validationSchema = Yup.object().shape({
			pwd: requiredStringYup('비밀번호 입력은 필수입니다.'),
			newPwd: Yup.string()
				.min(8, '비밀번호 8자리이상 입력하세요.')
				.max(255)
				.required('새로운 비밀번호를 입력하세요.'),
			confirmPwd: Yup.string()
				.min(8, '비밀번호 8자리이상 입력하세요.')
				.max(255)
				.oneOf([Yup.ref('newPwd'), null], '새로운 비밀번호가 일치하지 않습니다.')
				.required('새로운 비밀번호를 입력하세요.'),
		});

		function initPassword(values: PasswordType) {
			values.pwd = '';
			values.newPwd = '';
			values.confirmPwd = '';
			return values;
		}

		return useFormik<PasswordType>({
			initialValues: {
				pwd: '',
				newPwd: '',
				confirmPwd: '',
			},
			validateOnMount: true,
			enableReinitialize: true,
			validationSchema: validationSchema,
			onSubmit: async (values) => {
				const passwordFormData = new FormData();
				passwordFormData.append('memberPwd', values.pwd);
				passwordFormData.append('memberPwdNew', values.newPwd);

				const data = await VaunceAppApis.updateInfoPasswordAsync(passwordFormData);
				if (data.vo.success) {
					alert('비밀번호 변경에 성공했습니다.');
					initPassword(values);
				} else {
					alert(`${data.vo.message}`);
					initPassword(values);
				}
			},
		});
	};

	const useFindPasswordFormik = () => {
		const setFindUserInfo = useSetAtom(VaunceState.findUserInfo);
		const { navigateFindPwdStepTwo } = useVaunceAppRouter();

		const validationSchema = Yup.object().shape({
			id: Yup.string()
				.email('아이디 입력 시 이메일형식으로 입력하세요.')
				.required('아이디를 입력해주세요.'),
			birth: Yup.string()
				.required('생년월일은 필수 입력사항입니다.')
				.matches(/^\d{8}$/, '올바른 생년월일 형식이 아닙니다. 예: 20230101'),
			phoneNum: Yup.string().matches(
				/^[0-1][0-9]+$/,
				'올바른 휴대폰 번호 형식이 아닙니다. 예: 01012345678'
			),
			reception: requiredStringYup('비밀번호 찾기 수단 선택은 필수 입니다.'),
		});
		return useFormik<FindPasswordType>({
			initialValues: {
				id: '',
				birth: '',
				phoneNum: '',
				reception: 'email',
			},
			validateOnMount: true,
			enableReinitialize: true,
			validationSchema: validationSchema,
			onSubmit: async (values) => {
				const userFormData = new FormData();

				userFormData.append('pwdfindId', values.id);
				userFormData.append('pwdfindPhone', values.phoneNum);
				userFormData.append('pwdfindBirth', values.birth);
				userFormData.append('reception', values.reception);

				const data = await VaunceAppApis.postFindPwAsync(userFormData);
				if (data && data.vo) {
					const response = data.vo;
					setFindUserInfo(response);
					navigateFindPwdStepTwo();
				} else {
					alert('일치하는 고객정보를 찾지못하였습니다.');
				}
			},
		});
	};

	const useAddressFormik = () => {
		const updateAddress = VaunceQueryState.useAddressQuery();
		const userInfo = VaunceQueryState.useUserInfoQuery();
		const result = useFormik<{
			infoAddressNumber: string;
			infoAddressDetail: string;
		}>({
			initialValues: {
				infoAddressNumber: userInfo.infoAddressNumber,
				infoAddressDetail: userInfo.infoAddressDetail,
			},
			validateOnMount: true,
			enableReinitialize: true,
			onSubmit: async (values) => {
				const addressFormData = new FormData();
				addressFormData.append(
					'infoAddress',
					`${values.infoAddressNumber} ${values.infoAddressDetail}`
				);
				addressFormData.append('infoAddressDetail', values.infoAddressDetail);
				addressFormData.append('infoAddressNumber', values.infoAddressNumber);

				await updateAddress.mutateAsync(addressFormData);
			},
		});
		useEffect(() => {
			if (
				result.values.infoAddressNumber &&
				result.values.infoAddressNumber !== userInfo.infoAddressNumber &&
				AddressMap[result.values.infoAddressNumber] &&
				AddressMap[result.values.infoAddressNumber].length > 0
			)
				result.setFieldValue('infoAddressDetail', AddressMap[result.values.infoAddressNumber][0]);
		}, [result.values.infoAddressNumber]);

		return result;
	};

	const useSignupInfoFirstStepFormik = () => {
		const searchParams = useLocation().search;
		const query = qs.parse(searchParams, { ignoreQueryPrefix: true });

		const snsMode = localStorage.getItem('provider') ? true : false;

		const adAgreeStatus = localStorage.getItem('adAgree');

		function getGenderData(sex: string): string {
			return Number(sex) % 2 === 0 ? 'F' : 'M';
		}

		const validationSchema = Yup.object().shape({
			emailFirst: Yup.string()
				.required('이메일을 입력해주세요')
				.matches(/^\S*$/, '이메일에 공백이 포함되어 있습니다.'),
			emailSecondText: Yup.string().when('emailSecond', {
				is: (value: string) => !value || value.trim() === '',
				then: Yup.string().required('이메일을 입력해주세요'),
			}),
			id: Yup.string()
				.email('아이디 입력 시 이메일형식으로 입력하세요.')
				.required('아이디를 입력해주세요.'),
			password: Yup.string()
				.min(8, '비밀번호 8자리이상 입력하세요.')
				.required('비밀번호를 입력해주세요.'),
			confirmPassword: Yup.string()
				.min(8, '비밀번호 8자리이상 입력하세요.')
				.max(255)
				.oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
				.required('비밀번호를 확인해주세요.'),
			addressFirst: Yup.string().required('주소를 선택하세요.'),
			addressSecond: Yup.string().required('상세 주소를 선택하세요.'),
			isPassIdDuplicated: Yup.boolean().oneOf([true], '중복확인이 필요합니다.'),
		});

		const validateSnsModeSchema = Yup.object().shape({
			name: Yup.string().required('이름을 입력해주세요'),
			emailFirst: Yup.string()
				.required('이메일을 입력해주세요')
				.matches(/^\S*$/, '이메일에 공백이 포함되어 있습니다.'),
			emailSecondText: Yup.string().when('emailSecond', {
				is: (value: string) => !value || value.trim() === '',
				then: Yup.string().required('이메일을 입력해주세요'),
			}),
			addressFirst: Yup.string().required('주소를 선택하세요.'),
			addressSecond: Yup.string().required('상세 주소를 선택하세요.'),
		});

		return useFormik<SignupInfoFirstType>({
			validateOnMount: true,
			enableReinitialize: true,
			initialValues: {
				birth: (query['birth'] as string) ?? '',
				gender: getGenderData(query['sex'] as string) ?? 'F',
				name: ((query['name'] as string) ?? '') === 'signup' ? '' : (query['name'] as string) ?? '',
				phone: (query['phone'] as string) ?? '',
				emailFirst: '',
				emailSecond: 'naver.com',
				emailSecondText: '',
				id: '',
				password: '',
				confirmPassword: '',
				addressFirst: '',
				addressSecond: '',
				isPassIdDuplicated: false,
			},
			validationSchema: snsMode ? validateSnsModeSchema : validationSchema,
			onSubmit: async (values) => {
				//sns 일 경우
				if (snsMode) {
					const snsJoinFormData = new FormData();
					snsJoinFormData.append('signname', values.name);

					snsJoinFormData.append('signbirth', values.birth);
					snsJoinFormData.append('signgender', values.gender);
					snsJoinFormData.append('signphone', values.phone.toString());

					snsJoinFormData.append('signaddress1', values.addressFirst);
					snsJoinFormData.append('signaddress2', values.addressFirst + ' ' + values.addressSecond);
					snsJoinFormData.append('signaddress3', values.addressSecond);

					snsJoinFormData.append('signemail01', values.emailFirst);
					snsJoinFormData.append(
						'signemail02',
						values.emailSecond !== 'direct' ? values.emailSecond : values.emailSecondText
					);
					snsJoinFormData.append('adAgree', adAgreeStatus === 'Y' ? 'AGREE' : 'DISAGREE');
					const res = await VaunceAppApis.postSnsJoinAsnyc(snsJoinFormData);
					if (res) {
						localStorage.setItem('user_id', res.userId);
						navigateSignUpStepFive();
					}
				} else {
					// local 로그인일 경우
					const res = await VaunceAppApis.postSignUpAsync({
						signname: values.name,
						signbirth: values.birth,
						signgender: values.gender,
						signphone: values.phone.toString(),

						signemail01: values.emailFirst,
						signemail02:
							values.emailSecond !== 'direct' ? values.emailSecond : values.emailSecondText,
						signaddress1: values.addressFirst,
						signaddress2: values.addressFirst + ' ' + values.addressSecond,
						signaddress3: values.addressSecond,
						signid: values.id,
						signpwd: values.password,
						signpwdconfirm: values.confirmPassword,
						adAgree: adAgreeStatus === 'Y' ? 'AGREE' : 'DISAGREE',
					});

					if (res) {
						if (res.accessToken) {
							localStorage.removeItem('snsToken');

							localStorage.setItem('accessToken', res.accessToken);
							localStorage.setItem('refreshToken', res.refreshToken);
						}
						alert('회원가입이 완료되었습니다.');
						localStorage.setItem('user_id', res.uid);
						navigateSignUpStepFive();
					}
				}
			},
		});
	};
	const useSignupInfoTwoStepFormik = () => {
		const { navigateSignUpStepSix } = useVaunceAppRouter();
		const validationSchema = Yup.object({
			childList: Yup.array().of(
				Yup.object().shape({
					birth: Yup.string()
						.max(8, ValidErrorsMsg.BIRTHMAX)
						.matches(
							/^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
							'생년월일(8자리) 형식이 맞지 않습니다'
						)
						.when('name', (name, schema) =>
							name ? schema.required(ValidErrorsMsg.BIRTHREQUIRED) : schema
						),
					relation: Yup.number().when('name', (name, schema) =>
						name
							? schema.moreThan(0, '관계를 선택해주세요').required('관게를 선택해야 합니다.')
							: schema
					),
				})
			),
		});

		return useFormik<{ childList: ChildListType[]; centerIdList: number[]; recommender: string }>({
			validateOnMount: true,
			validationSchema: validationSchema,
			enableReinitialize: true,
			initialValues: {
				childList: [{ id: 0, name: '', birth: '', gender: 'M', relation: 0 }],
				centerIdList: [0],
				recommender: '',
			},

			onSubmit: async (values) => {
				const signupFormData = new FormData();

				signupFormData.append('centers', [...new Set(values.centerIdList)].join(','));
				signupFormData.append(
					'childnamelist',
					values.childList.map((child) => child.name).join(',')
				);
				signupFormData.append(
					'childbirthlist',
					values.childList.map((child) => child.birth).join(',')
				);
				signupFormData.append(
					'childgenderlist',
					values.childList.map((child) => child.gender).join(',')
				);
				signupFormData.append(
					'childrelationlist',
					values.childList.map((child) => child.relation).join(',')
				);

				if (values.recommender) signupFormData.append('recommender', values.recommender);

				const userId = localStorage.getItem('user_id');
				if (userId) {
					const data = await VaunceAppApis.postSignUpMoreInfoAsync(userId, signupFormData);
					if (data) {
						navigateSignUpStepSix(data.vo.username);
					}
				}
			},
		});
	};

	const useChildrenFormik = () => {
		const userInfo = VaunceQueryState.useUserInfoQuery();
		const updateChildren = VaunceQueryState.useChildrenQuery();

		const initialValue = useMemo(
			() => ({
				childList:
					userInfo.childList.length !== 0
						? userInfo.childList
						: [{ name: '', birth: '', gender: 'M', relation: 0 }],
			}),
			[userInfo]
		);
		const validationSchema = Yup.object({
			childList: Yup.array().of(
				Yup.object().shape({
					name: nameYup,
					birth: birthdayYup,
					gender: Yup.string().required('성별은 필수 입니다.'),
					relation: Yup.number()
						.moreThan(0, '관계를 선택해주세요')
						.required('관게를 선택해야 합니다.'),
				})
			),
		});
		return useFormik<{
			childList: {
				name: string;
				birth: string;
				gender: string;
				relation: number;
			}[];
		}>({
			validateOnMount: true,
			initialValues: initialValue,
			validationSchema: validationSchema,
			enableReinitialize: true,
			onSubmit: async (values) => {
				const childrenFormData = new FormData();
				childrenFormData.append(
					'childrenNameList',
					values.childList.map((child) => child.name).join(',')
				);
				childrenFormData.append(
					'childrenBirthList',
					values.childList.map((child) => child.birth).join(',')
				);
				childrenFormData.append(
					'childrenGenderList',
					values.childList.map((child) => child.gender).join(',')
				);
				childrenFormData.append(
					'childrenRelationList',
					values.childList.map((child) => child.relation).join(',')
				);
				// 왜보내는지 모르겠으나 첫번째 자녀를 보냄
				childrenFormData.append('name', values.childList[0].name);
				childrenFormData.append('birth', values.childList[0].birth);
				childrenFormData.append('gid', values.childList[0].gender);
				childrenFormData.append('rid', values.childList[0].relation.toString()); //성별데이터
				childrenFormData.append('type', '1'); //무슨데이터인지 모르겠음

				await updateChildren.mutateAsync(childrenFormData);
			},
		});
	};

	const useExtraInfoFormik = () => {
		const userInfo = VaunceQueryState.useUserInfoQuery();
		const updateExtraInfo = VaunceQueryState.useExtraInfoQuery();

		const validationSchema = Yup.object().shape({
			centerList: Yup.array()
				.of(Yup.number())
				.test('centerList unique', '자주이용하는 센터는 중복될 수 없습니다.', function (values) {
					return new Set(values).size === (values as number[]).length;
				}),
		});

		return useFormik<{
			centerList: number[];
			centerCollections: CenterCollectionsType[];
			recommender: string;
		}>({
			initialValues: {
				centerList: userInfo.centerList.length !== 0 ? userInfo.centerList : [0],
				centerCollections: [...userInfo.centerCollections],
				recommender: userInfo.recommander,
			},
			validationSchema: validationSchema,
			onSubmit: async (values) => {
				const extraFormData = new FormData();
				extraFormData.append('centerList', values.centerList.join(','));
				extraFormData.append('recommender', values.recommender);

				await updateExtraInfo.mutateAsync(extraFormData);
			},
		});
	};

	const useFacilityRegisterFormik = () => {
		const myUsage = VaunceQueryState.useUserUsageQuery();

		const validationSchema = Yup.object().shape({
			userArray: Yup.array().of(
				Yup.object().shape({
					name: nameYup,
					birth: birthdayYup,
					gender: Yup.string().required('성별은 필수 입니다.'),
				})
			),
		});

		return useFormik<Omit<FacilityReservationInfo, 'termsArray'>>({
			enableReinitialize: true,
			validateOnMount: true,
			initialValues: {
				centerId: infos.centerId,
				availableType: infos.availableType,
				userArray: infos.userArray,
			},
			validationSchema: validationSchema,
			onSubmit: async (values) => {
				const formData = new FormData();

				formData.append('usernamelist', values.userArray.map((user) => user.name).join(','));
				formData.append('userbirthlist', values.userArray.map((user) => user.birth).join(','));
				formData.append('usergenderlist', values.userArray.map((user) => user.gender).join(','));
				formData.append(
					'userrelationlist',
					values.userArray.map((user) => user.relation).join(',')
				);

				formData.append('center', `${values.centerId}`); //센터 ( 매장 branch ) 고유값
				// FIXME:  업장 유형 ( 1 매표소, 2 레이싱 [ 현재 사용 안함 ]), 업장유형 별로 upjang idx 가 다름, 현재는 업장은 매표소 유형으로 고정
				// FIXME: 넥스트스토리 api 응답에서는 매표소, 레이싱 등 모두 "A" 로 일괄 적용되어있음 확인필요
				formData.append('upjngtype', 'NORMAL');
				// formData.append("companyIdx", 25);

				formData.append('applicant', myUsage.applicant);
				formData.append('applicantPhone', myUsage.applicantPhone);
				formData.append('admission', values.availableType);
				formData.append('type', 'NORMAL');

				const res = await VaunceAppApis.updateFacilityRqeust(formData);
				if (res) {
					if (res.vo.usageid > 0) {
						setUsageId(res.vo.usageid);
						setInfos({
							...infos,
							centerId: values.centerId,
							availableType: values.availableType,
							userArray: values.userArray,
						});
					}
					navigateNewRegisterStepTwo();
				} else {
					alert('네트워크 오류입니다.');
				}
			},
		});
	};

	type CategoryType = NormalQuestionType;

	const useNormalQuestionFormik = () => {
		const { navigateQuestionDone } = useVaunceAppRouter();
		const validationSchema = Yup.object().shape({
			title: Yup.string().min(1).max(255).required('제목을 입력하여주세요'),
			memo: Yup.string().min(1).max(255).required('내용을 입력하여주세요.'),
		});
		return useFormik<{
			category: CategoryType;
			title: string;
			memo: string;
			questionType: QuestionFirstType;
			noticeNum: number;
		}>({
			enableReinitialize: true,
			validateOnMount: true,
			initialValues: {
				category: 'normal',
				title: '',
				memo: '',
				questionType: 'normal',
				noticeNum: 1,
			},
			validationSchema: validationSchema,
			onSubmit: async (values) => {
				const res = await VaunceAppApis.sendNormQuestion({ ...values });
				if (res) {
					alert('문의접수가 완료되었습니다.');
					navigateQuestionDone();
				} else {
					alert('문의접수가 실패했습니다.');
				}
			},
		});
	};

	const usePartyQuestionFormik = () => {
		const today = new Date();
		const validationSchema = Yup.object().shape({
			memberinfoName: nameYup,
			memberinfoPhone: telephoneYup,
			numberOfUsers: Yup.number().moreThan(0).required(ValidErrorsMsg.NUMBEROFUSERREQUIRED),
			date: Yup.date().min(new Date(today.setDate(today.getDate() - 1)), ValidErrorsMsg.STARTDATE),
			hour: Yup.string().required(ValidErrorsMsg.REQUIRED),
			minutes: Yup.string().required(ValidErrorsMsg.REQUIRED),
			hours: Yup.string().required(ValidErrorsMsg.REQUIRED),
		});
		return useFormik({
			validationSchema: validationSchema,
			validateOnMount: true,
			enableReinitialize: true,

			initialValues: {
				centerSelect: appliedInfo.centerSelect,
				goods: appliedInfo.goods,
				isSameWithApplicant: appliedInfo.isSameWithApplicant,
				memberinfoName: appliedInfo.memberinfoName,
				memberinfoPhone: appliedInfo.memberinfoPhone,
				numberOfUsers: appliedInfo.numberOfUsers,
				date: appliedInfo.date,
				hour: appliedInfo.hour,
				minutes: appliedInfo.minutes,
				hours: appliedInfo.hours,
				memo: appliedInfo.memo,
			},

			onSubmit: async (values) => {
				delete values.isSameWithApplicant;

				const res = await VaunceAppApis.sendPartyQuestion({
					...values,
				});
				if (res) {
					alert('문의접수가 완료되었습니다.');
					navigate('/question/complete/party');
				}
			},
		});
	};

	interface VipClubRegisterInfo {
		vipClubName: string;
		vipClubBirth: string;
		pgTicketIdx: string;
		file: any;
	}
	const useVipClubRegisterFormik = () => {
		const { navigateVauncePremiumClub } = useVaunceAppRouter();

		const selectPgTicketId = useAtomValue(VaunceState.selectPgTicketId);

		const updatePremiumRegister = VaunceQueryState.usePremiumRegister(navigateVauncePremiumClub);

		const validationSchema = Yup.object().shape({
			vipClubName: nameYup,
			vipClubBirth: birthdayYup,
		});
		return useFormik<VipClubRegisterInfo>({
			validateOnMount: true,
			validationSchema: validationSchema,
			enableReinitialize: true,
			initialValues: {
				vipClubName: '',
				vipClubBirth: '',
				pgTicketIdx: selectPgTicketId.toString(),
				file: null,
			},
			onSubmit: async (values) => {
				const userData = new FormData();
				userData.append('file', values.file);
				userData.append('pgTicketIdx', selectPgTicketId.toString());
				userData.append('birth', values.vipClubBirth);
				userData.append('name', values.vipClubName);
				updatePremiumRegister.mutate(userData);
			},
		});
	};

	const couponInitValue = {
		couponText: '',
	};
	const validationSchema = Yup.object().shape({
		couponText: couponYup,
	});

	const useCouponRegisterFormik = ({
		initialValues = couponInitValue,
		onSubmit,
	}: {
		initialValues?: { couponText: string };
		onSubmit: (values: { couponText: string }) => void;
	}) => {
		return useFrameworkFormik({
			initialValues: initialValues,
			validationSchema: validationSchema,
			onSubmit: onSubmit,
		});
	};

	return {
		useAddressFormik,
		useChildrenFormik,
		useExtraInfoFormik,
		useFindIdFormik,
		usePasswordFormik,
		useFindPasswordFormik,
		useFacilityRegisterFormik,
		useNormalQuestionFormik,
		usePartyQuestionFormik,
		useSignupInfoFirstStepFormik,
		useSignupInfoTwoStepFormik,
		useVipClubRegisterFormik,
		useCouponRegisterFormik,
	};
};
