import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { CenterCollectionsType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import { CloseIconBox, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Box, DialogContent, IconButton, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

export interface ISelectCenterDialogProps {
	open: boolean;
	closeDialog: () => void;
	handleClick: (val: number | string) => void;
}
export const SelectCenterDialog: React.FC<ISelectCenterDialogProps> = ({
	open,
	closeDialog,
	handleClick,
}) => {
	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();

	const dummyData = [
		{
			centerId: 'unSelected',
			centerDisplay: '전체',
			centerDisplayShorts: '전체',
			linkUrl: '',
		},
	];
	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			fullWidth
			keepMounted
			maxWidth={'lg'}
			dialogTitle={
				<Box
					display={'flex'}
					justifyContent={'flex-start'}>
					<IconButton onClick={closeDialog}>
						<CloseIconBox sx={{ width: 18, height: 18 }} />
					</IconButton>
				</Box>
			}
			TransitionComponent={Transition}>
			<DialogContent>
				{[...dummyData, ...centerCollectionData].map((item, idx) => (
					<Box
						key={idx}
						sx={{ borderBottom: `1px solid ${vaunceColors.lightGray}`, padding: 2 }}
						onClick={() => {
							handleClick(item.centerId);
							closeDialog();
						}}>
						<Typography variant='h3'>{item.centerDisplay}</Typography>
					</Box>
				))}
			</DialogContent>
		</WithDialog.XlDialog>
	);
};

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	return (
		<Slide
			direction='up'
			ref={ref}
			mountOnEnter
			unmountOnExit
			{...props}
		/>
	);
});
