import { Stack, Tab, Typography } from '@mui/material';
import React from 'react';
import { PageHeader } from '../../components/PageHeader';
import VauncePinkTabs from '../../components/VauncePinkTabs';
import { TabPanel } from '../VclubPage';
import { AlarmTabPage } from './AlarmTabPage';
import { NoticeTabPage } from './NoticeTabPage';
import { VaunceState } from '@kinderlabs-pos/state';
import { useAtom } from 'jotai';

export interface INoticePageProps {}
const NoticePage: React.FC<INoticePageProps> = ({}) => {
	const [value, setValue] = useAtom(VaunceState.selectNoticeTabIdx);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Stack
			spacing={3}
			flex={1}
			overflow={'auto'}>
			<PageHeader title={'공지 / 알림'} />

			<VauncePinkTabs
				value={value}
				onChange={handleChange}
				variant='fullWidth'>
				<Tab label={<Typography variant='h3'>알림</Typography>} />
				<Tab label={<Typography variant='h3'>공지</Typography>} />
			</VauncePinkTabs>

			<Stack
				flex={1}
				overflow={'auto'}>
				<TabPanel
					value={value}
					index={0}
					overflow={'auto'}>
					<AlarmTabPage />
				</TabPanel>
				<TabPanel
					value={value}
					index={1}
					overflow={'auto'}>
					<NoticeTabPage />
				</TabPanel>
			</Stack>
		</Stack>
	);
};

export default NoticePage;
