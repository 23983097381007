import { CenterCollectionsType } from '@kinderlabs-pos/shared-data-type';
import { VaunceQueryState } from '@kinderlabs-pos/state';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	NativeSelect,
	Stack,
	Typography,
} from '@mui/material';
export interface INewRegisterCenterSelectProps {
	formik: any;
}
export const NewRegisterCenterSelect: React.FC<INewRegisterCenterSelectProps> = ({ formik }) => {
	const centerCollectionData: CenterCollectionsType[] = VaunceQueryState.useCenterListQuery();
	const { values, handleChange, errors, handleBlur, touched } = formik;
	return (
		<Stack spacing={2}>
			<Typography variant='h2'>센터 선택</Typography>
			<FormControl
				fullWidth
				sx={{ fontSize: '16px' }}>
				<InputLabel
					variant='standard'
					htmlFor='uncontrolled-native'
					sx={{ pb: 2, fontSize: '12px', fontWeight: 400 }}>
					이용 센터
				</InputLabel>

				<NativeSelect
					sx={{
						fontSize: '16px',
						fontWeight: 500,
						opacity: 0.6,
					}}
					data-cy='registerCenterSelect'
					variant='standard'
					name='centerId'
					value={values.centerId}
					onChange={handleChange}
					onBlur={handleBlur}>
					{centerCollectionData.map((center) => (
						<option
							key={center.centerId}
							value={center.centerId}>
							{center.centerDisplay}
						</option>
					))}
				</NativeSelect>
			</FormControl>
			{touched.centerId && (
				<FormHelperText>
					<Typography
						component={'span'}
						color={'primary'}>
						{errors.centerId}
					</Typography>
				</FormHelperText>
			)}
		</Stack>
	);
};
